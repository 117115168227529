import { assert } from '../lib/assert';
import { ReVuNode } from '../types';
import { shortid } from '../lib/shortid';
import { renderToNode } from './renderToNode';
import { addListener } from '../lib/eventListener';
import { Performance, measurement, AppWithPerformance } from '../lib/performance';

////////////////////////////////////////////////////////////////////////////////

interface ReVuDOMConfig {
  isPerformanceMonitor: boolean;
  isDebug: boolean;
  idolLimit: number;
  checkDuplicateKey: boolean;
  purgeCss: boolean;
}

////////////////////////////////////////////////////////////////////////////////

interface ReVuDOMRenderer {
  renderRequest: (ctxid: string, cause: string) => void;
  waitForRendered: () => Promise<boolean>;
  renderId: string;
  config: ReVuDOMConfig;
}

////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace ReVuDOM {

  //////////////////////////////////////////////////////////////////////////////

  export const config: ReVuDOMConfig = {
    isPerformanceMonitor: false,
    isDebug: false,
    idolLimit: 200,
    checkDuplicateKey: false,
    purgeCss: false,
  };
  //
  export const debug = (f: boolean) => {
    config.checkDuplicateKey = f;
    return config.isDebug = f;
  };

  export const checkDuplicateKey = (f: boolean) => {
    return config.checkDuplicateKey = f;
  };

  /* istanbul ignore next */
  export const performanceMonitor = (f: boolean) => {
    return config.isPerformanceMonitor = f;
  };

  export const purgeCss = (f: boolean) => {
    config.purgeCss = f;
    return config.purgeCss = f;
  };

  //////////////////////////////////////////////////////////////////////////////

  export const render = (revuNode: ReVuNode, selector: string | Element | null): ReVuDOMRenderer | undefined => {

    const renderId = shortid(3);
    const pf: Performance = {};

    let element: Element | null;
    if(typeof selector === 'string'){
      /* istanbul ignore next */
      element = document.querySelector(selector);
    }else{
      element = selector;
    }

    /* istanbul ignore next */
    if(!element){
      console.error('[ReVu] Invalid Selector', selector);
      return;
    }

    ////////////////////////////////////////////////////////////////////////////

    let inRender = false;
    let isUpdateInRender = false;
    const renderRequest = (ctxid: string, cause: string) => {
      if(inRender){
        isUpdateInRender = true;
      }else{
        requestRenderCore(cause);
      }
    };

    ////////////////////////////////////////////////////////////////////////////

    const waitForRendered = (): Promise<boolean> => {
      return new Promise((resolve) => {
        assert(element);
        const listener = addListener(element, `ReVuFinishRender@${renderId}`, () => {
          listener.remove();
          resolve(true);
        });
      });
    };

    ////////////////////////////////////////////////////////////////////////////

    const renderer: ReVuDOMRenderer = { config, renderId, renderRequest, waitForRendered };

    const renderCoreCore = (cause: string) => {
      assert(element);
      inRender = true;
      if(config.isPerformanceMonitor){
        /* istanbul ignore next */
        renderToNode(renderId, (
          <AppWithPerformance pf={pf}>
            {revuNode}
          </AppWithPerformance>
        ), element, renderer, false);
      }else{
        renderToNode(renderId, revuNode, element, renderer, false);
      }
      //
      if(isUpdateInRender){
        isUpdateInRender = false;
        renderCoreCore(cause);
      }
      //
      inRender = false;
    };

    ////////////////////////////////////////////////////////////////////////////

    const renderCore = (cause: string) => {
      measurement(pf, 'draw', () => {
        renderCoreCore(cause);
      });
      assert(element);
      const event = new CustomEvent(`ReVuFinishRender@${renderId}`);
      element.dispatchEvent(event);
    };

    ////////////////////////////////////////////////////////////////////////////

    let requestAnimation: number | undefined = undefined;
    const requestRenderCore = (cause: string) => {
      if(requestAnimation !== undefined){
        window.cancelAnimationFrame(requestAnimation);
      }
      requestAnimation = window.requestAnimationFrame(() => {
        renderCore(cause);
      });
    };

    ////////////////////////////////////////////////////////////////////////////

    // first render
    renderRequest('*', 'initial');

    return renderer;

  };

}

////////////////////////////////////////////////////////////////////////////////

export { ReVuDOM, ReVuDOMRenderer };
