import { assert } from '../lib/assert';
import { getContext } from './context';
import { StateParam, resolveStateParam } from './StateParam';

////////////////////////////////////////////////////////////////////////////////

interface StateContext<T> {
  initialized?: boolean;
  value?: [T, (value: StateParam<T>) => T];
}

////////////////////////////////////////////////////////////////////////////////

const useState = <T>(init?: T | (() => T)): [T, (value: StateParam<T>) => T] => {
  const context = getContext<StateContext<T>>({});

  if(!context.data.initialized){

    const set = (value: StateParam<T>) => {
      assert(context.data.value);
      //
      const newValue = resolveStateParam<T>(value, context.data.value[0]);
      //
      if(context.data.value[0] !== newValue){
        context.renderer.renderRequest(context.ctxid, 'useState');
        context.data.value[0] = newValue;
      }
      //
      return newValue;
      //
    };

    if(typeof init === 'function'){
      context.data.value = [(init as () => T)(), set];
    }else{
      context.data.value = [init as T, set];
    }
    context.data.initialized = true;

  }

  assert(context.data.value);
  return context.data.value;

};

////////////////////////////////////////////////////////////////////////////////

export { useState };