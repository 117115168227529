import { lazy, useContext, useGlobalCss, useCss, useRawCss } from 'revu2';
import { Routes, Route, Navigate } from 'revu2/router';
import { Align } from 'revu2/ui';
import { useCustomTheme, themes } from '@/theme';
import AppContext from '@/context/AppContext';
import { ROUTE_PATH } from './routePath';
import { SignInComponent, SignInCallback } from './SignIn';
import AuthorizedArea from './components/AuthorizedArea';
import SystemAdminArea from './components/SystemAdminArea';
import { AlertDialog } from '@/pages/components/AlertDialog';
import SignUpComponent from './SignIn/signup';
import { useUpdateEvent } from '@/hooks/useUpdateEvent';
import { useAutoUpdateToken } from '@/context/token';
import OptionSelect from './components/OptionSelect';

////////////////////////////////////////////////////////////////////////////////

const AppInit = lazy(() => import(/* webpackChunkName: "AppInit" */'./AppInit'));
const SystemAdmin = lazy(() => import(/* webpackChunkName: "SystemAdmin" */'@/pages/systemAdmin/SystemAdmin'));
const Maintenance = lazy(() => import(/* webpackChunkName: "Maintenance" */'@/pages/Maintenance'));
const AppHome = lazy(() => import(/* webpackChunkName: "AppHome" */'@/pages/AppHome/AppHome'));
const Preview = lazy(() => import(/* webpackChunkName: "Preview" */'@/pages/Preview'));
const TermsAgreement = lazy(() => import(/* webpackChunkName: "TermsAgreement" */'@/pages/TermsAgreement'))

////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => {

  const [appContext] = useContext(AppContext);

  useAutoUpdateToken();
  useUpdateEvent();

  useCustomTheme(themes);
  const { theme } = useCustomTheme();

  useGlobalCss({
    body : {
      fontSize: '14px',
      overscrollBehaviorX: 'none',
      overscrollBehaviorY: 'none',
    },
    '.bimstok-option-select': {
      marginTop: '16px',
      border: `1px solid ${theme.popup.borderColor}`,
      borderRadius: theme.borderRadius,
      backgroundColor: theme.popup.backgroundColor,
      color: theme.popup.text,
      padding: '4px',
      '.item' : {
        padding: '4px 16px',
        fontSize: '0.9rem',
        borderRadius: '6px',
        cursor: 'pointer',
        '&:hover' : {
          backgroundColor: theme.popup.hoverBackgroundColor,
          color: theme.popup.hoverText,
        }
      },
    },
    '.pulldown, .pulldown-header':{
      position: 'relative',
      marginTop: '0.2rem',
      paddingRight: '2.0rem',
      '&::after':{
        content: '""',
        zIndex: '1',
        position: 'absolute',
        cursor: 'pointer',
        width: '0.5rem',
        height: '0.5rem',
        right: '1.0rem',
        top: '0.3rem',
        bottom: '0',
        //
        transform: 'rotate(-45deg)',
        borderBottom: '2px solid',
        borderLeft: '2px solid',
        borderColor: theme.text,
      }
    },
  });

  useRawCss(`
  .bimstok-custom-loader {
    width:50px;
    height:50px;
    border-radius:50%;
    background:conic-gradient(#0000 10%, ${theme.color.primary});
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}

  .fadeIn{
    animation-name:fadeInAnime;
    animation-duration:1s;
    animation-fill-mode:forwards;
    opacity:0;
    background-color: #00000022;
  }
  @keyframes fadeInAnime{
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  `);

  useCss({
    '.base':{
      width: '100%',
      height: '100%',
    },
    '.loader-outer':{
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: '999999',
    },
    '.loader-inner':{
      pointerEvents: 'auto',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  });

  if(!appContext.initialized){
    return <AppInit/>;
  }

  if(appContext.maintenance){
    return <><AppInit/><Maintenance/></>;
  }

  return (
    <div className="revu-ui base">
      <AlertDialog.Dialog/>
      <AppInit/>

      <div className="loader-outer">
        {
          appContext.loading?
            <div className="loader-inner fadeIn">
              <Align>
                <div className="bimstok-custom-loader"></div>
              </Align>
            </div>
            :
            <></>
        }
      </div>

      <Routes>

        <Route path={ROUTE_PATH.SIGNIN_PATH}>
          <SignInComponent/>
        </Route>
        <Route path={ROUTE_PATH.SIGNIN_CALLBACK_PATH}>
          <SignInCallback/>
        </Route>

        <Route path={ROUTE_PATH.SIGNUP_PATH}>
          <SignUpComponent/>
        </Route>

        <Route path={ROUTE_PATH.SYSTEMADMIN_PATH}>
          <AuthorizedArea failed={ROUTE_PATH.SIGNIN()}>
            <SystemAdminArea failed={ROUTE_PATH.SIGNIN()}>
              <SystemAdmin/>
            </SystemAdminArea>
          </AuthorizedArea>
        </Route>

        <Route path={ROUTE_PATH.PREVIEW_PATH}>
          <AuthorizedArea failed={ROUTE_PATH.SIGNIN()}>
            <Preview/>
          </AuthorizedArea>
        </Route>

        <Route path={ROUTE_PATH.WORKSPACE_PATH}>
          <AuthorizedArea failed={ROUTE_PATH.SIGNIN()}>
            <AppHome/>
          </AuthorizedArea>
        </Route>

        <Route path={ROUTE_PATH.TERMSAGREEMENT_PATH}>
          <AuthorizedArea failed={ROUTE_PATH.SIGNIN()}>
            <TermsAgreement/>
          </AuthorizedArea>
        </Route>

        <Route path="/*">
          <Navigate to={ROUTE_PATH.WORKSPACE()} replace={true}/>
        </Route>

      </Routes>

      <div style="display: none">
        {/* dummy load for safari */}
        <OptionSelect options={[]}/>
      </div>

    </div>
  );
};

export default AppRouter;