import { FunctionComponent, isReVuNode } from '../types';

////////////////////////////////////////////////////////////////////////////////
/*
function childrenDeepCopyCore(src: unknown, p?: unknown[]): unknown {

  // recursive
  const parents = p ?? [];
  if (parents.indexOf(src) >= 0) {
    return src;
  }

  // null undefined
  if (src === null || src === undefined) {
    return src;
  }

  // date
  if(src instanceof Date){
    return new Date(src);
  }

  // promise map set
  if(src instanceof Promise || src instanceof Map || src instanceof Set){
    return src;
  }

  // object
  if(typeof src === 'object') {

    // array
    if (Array.isArray(src)) {
      parents.push(src);
      const dst: unknown[] = [];
      for (const s of src) {
        const result = childrenDeepCopyCore(s, parents);
        dst.push(result);
      }
      parents.pop();
      return dst;
    }

    // class
    if (src.constructor.name !== 'Object') {
      return src;
    }

    // object
    parents.push(src);
    //
    const dst: object = {};
    const srcKeys = Object.keys(src);
    for (const srcKey of srcKeys) {
      if(srcKey !== 'children'){
        dst[srcKey] = src[srcKey] as unknown;
      }else{
        const result = childrenDeepCopyCore(src[srcKey], parents);
        dst[srcKey] = result;
      }
    }
    //
    parents.pop();
    return dst;
    //
  }

  return src;

}
*/
function childrenDeepCopy<T>(src: T): T {
  //return childrenDeepCopyCore(src) as T;
  return src;
}

////////////////////////////////////////////////////////////////////////////////

function parseParams (params: {[key:string]: unknown}){

  const {children, ...inheritanceParams} = params;

  let cache = false;
  if(inheritanceParams.cache){
    cache = inheritanceParams.cache?true:false;
    delete inheritanceParams.cache;
  }

  const copyChildren = childrenDeepCopy(children as JSX.Children);

  if(!Array.isArray(copyChildren)){
    if(isReVuNode(copyChildren)){
      return {
        children: copyChildren,
        params: inheritanceParams,
        cache,
      };
    }
    return {
      children: {type: '=', text: copyChildren, params: {}},
      params: inheritanceParams,
      cache,
    };
  }

  const results: JSX.Element[] = [];
  for(let i = 0; i < copyChildren.length; i++){
    //
    const child = copyChildren[i] as JSX.Children;
    if(!Array.isArray(child)){
      if(isReVuNode(child)){
        results.push({...child, key: child.key ?? i, cache: child.cache});
      }else{
        results.push({type: '=', text: child, params: {}, key: i, cache});
      }
    }else{
      if(child.length > 0){
        results.push(jsx('#', {children: child}, i));
      }
    }
  }

  return {
    children: results,
    params: inheritanceParams,
    cache
  };
}

////////////////////////////////////////////////////////////////////////////////

function jsx(
  type: string | FunctionComponent,
  params: {[key:string]: unknown} = {},
  key?: string | number,
): JSX.Element {
  //
  const cParams = params;
  if(typeof type === 'string'){
    const parsedParams = parseParams(cParams);
    return {type, ...parsedParams, key};
  }
  //
  let cache = false;
  if(cParams.cache){
    cache = cParams.cache?true:false;
    delete cParams.cache;
  }
  //
  return {type, params: cParams, key, cache};
}

////////////////////////////////////////////////////////////////////////////////

function jsxs(
  type: string | FunctionComponent,
  params: {[key:string]: unknown} = {},
  key?: string | number,
): JSX.Element {
  return jsx(type, params, key);
}

////////////////////////////////////////////////////////////////////////////////

function Fragment(
  params: {[key:string]: unknown},
): JSX.Element {
  const cParams = params;
  const parsedParams =  parseParams(cParams);
  return {type: '#', ...parsedParams, key: params.key as string | number | undefined};
}

////////////////////////////////////////////////////////////////////////////////

export { jsx, jsxs, Fragment };
