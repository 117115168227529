import { addListener, changeListenerFunc, EventFunction, ListenInfo } from '../lib/eventListener';
import { CacheObject } from './renderToNode';

type EventInfos = {[key:string]: ListenInfo}

////////////////////////////////////////////////////////////////////////////////

const applyEvents = (target: CacheObject, events: { [name:string] : EventFunction }): void => {

  const element = target.node as Element;

  // old listener
  const old = target.events ?? {};

  // add listener
  const listeners: EventInfos = {};
  for(const name in events){
    const ev = events[name];
    if(old[name]){
      changeListenerFunc(old[name], ev);
      listeners[name] = old[name];
    }else{
      listeners[name] = addListener(element, name, ev, {});
    }
  }

  // remove old listener
  for(const name in old){
    if(!listeners[name]){
      old[name].remove();
    }
  }

  // new listener
  target.events = listeners;

};

const removeEvents = (events: EventInfos) => {
  for(const name in events){
    const ev = events[name];
    ev.remove();
  }
};

////////////////////////////////////////////////////////////////////////////////

export { applyEvents, EventInfos, removeEvents };