import { ReVuNode } from '../types';
import { Ref } from './useRef';
import { getContext } from './context';
import { isSameArray } from '../lib/isSameArray';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const forwardRef = <T,X = any>(f: (props: X, ref: Ref<T>) => ReVuNode): ((props: X & {ref?: Ref<T>}) => ReVuNode) => {
  return (props: X & {ref?: Ref<T>}) => {
    return f(props, props.ref as Ref<T>);
  };
};

interface ImperativeHandleContext {
  checked?: unknown[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useImperativeHandle = <T = any>(ref: Ref<T> | undefined, funcs: () => T, check?: unknown[]) => {
  const context = getContext<ImperativeHandleContext>({});
  if(isSameArray(context.data.checked, check)){
    return;
  }
  context.data.checked = check;

  if(ref?.setState){
    ref.setState(funcs);
  }else{
    console.info('%cNo forwardRef', 'background-color: orange;');
  }
};

export { forwardRef, useImperativeHandle };