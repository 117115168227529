import { Params } from '../types';
import { assert } from '../lib/assert';
import { ReVuDOMRenderer } from '../render/ReVuDOM';

////////////////////////////////////////////////////////////////////////////////

interface ContextData<T> {
  ctxid: string;
  fcid: string;
  index: number;
  renderer: ReVuDOMRenderer;
  //
  data: T;
  effects: (() => void)[];
  layoutEffects: (() => void)[];
  purgeFuncs: (() => void)[];
  //
  funcName: string;
  //
}

interface Context {
  ctxid: string;
  fcid: string;
  index: number;
  renderer: ReVuDOMRenderer;
  contextData: ContextData<unknown>[];
  //
  params: Params;
  funcName: string;
  //
}

////////////////////////////////////////////////////////////////////////////////

let contexts: {[ctxid: string]: Context} = {};
let currentContext: Context | undefined;

const resetContext = () => {
  currentContext = undefined;
  contexts = {};
};

////////////////////////////////////////////////////////////////////////////////

const selectContext = (ctxid: string, fcid: string, funcName: string, renderer: ReVuDOMRenderer) => {
  //
  contexts[ctxid] = contexts[ctxid] ?? {
    ctxid,
    fcid,
    funcName,
    update: true,
    index: 0,
    renderer,
    params: {},
    contextData: []
  };
  //
  currentContext = contexts[ctxid];
  currentContext.index = 0;
  //
  return currentContext;
};

////////////////////////////////////////////////////////////////////////////////

const getContext = <T>(init?: T): ContextData<T> => {
  assert(currentContext);
  //
  currentContext.contextData[currentContext.index] = currentContext.contextData[currentContext.index] ?? {
    ctxid: currentContext.ctxid,
    fcid: currentContext.fcid,
    funcName: currentContext.funcName,
    index: currentContext.index,
    renderer: currentContext.renderer,
    data: init ?? {},
    effects: [],
    layoutEffects: [],
    purgeFuncs: [],
  };
  //
  return currentContext.contextData[currentContext.index++] as ContextData<T>;
  //
};

////////////////////////////////////////////////////////////////////////////////

const purge = (renderId: string, ctxid: string) => {
  /* istanbul ignore next */
  if(ctxid.substring(0, renderId.length + 1) !== `${renderId}.`){
    return;
  }
  //
  const removes: string[] = [];
  for(const target in contexts){
    if(target.indexOf(ctxid) === 0){
      removes.push(target);
    }
  }

  for(const remove of removes){
    const context = contexts[remove];
    //
    for(const ctx of context.contextData){
      for(const purgeFunc of ctx.purgeFuncs){
        purgeFunc();
      }
    }
    delete contexts[remove];
  }

};

////////////////////////////////////////////////////////////////////////////////

const effect = (renderId: string) => {
  // do layout effect
  for(const ctxid in contexts){
    const ctx = contexts[ctxid];
    /* istanbul ignore next */
    if(ctx.ctxid.substring(0, renderId.length + 1) !== `${renderId}.`){
      return;
    }
    //
    for(const cc of ctx.contextData){
      for(const effect of cc.effects){
        effect();
      }
      cc.effects = [];
    }
  }
  //
};

const layoutEffect = (renderId: string) => {
  // do layout effect
  for(const ctxid in contexts){
    const ctx = contexts[ctxid];
    /* istanbul ignore next */
    if(ctx.ctxid.substring(0, renderId.length + 1) !== `${renderId}.`){
      return;
    }
    //
    for(const cc of ctx.contextData){
      for(const layoutEffect of cc.layoutEffects){
        layoutEffect();
      }
      cc.layoutEffects = [];
    }
  }
  //
};

////////////////////////////////////////////////////////////////////////////////

export { selectContext, getContext, purge, resetContext, effect, layoutEffect };
