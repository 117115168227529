interface ENVIRONMENTS {

  sentryDsn: string;

  termsVersion: string;
  termsUrl: string;
  
  lng: string;
  environment: string;
  debug: boolean;
  BASE_URL: string;
  //
  graphQlEndPoint: string;
  graphQlWsEndPoint: string;
  //
  uploadEndPoint: string;
  downloadEndPoint: string;
  removeEndPoint: string;

  //
  signinEndPoint: string;
  signUpEndPoint: string;
  signCallbackEndPoint: string;
  getTokenEndPoint: string;
  updateTokenEndPoint: string;
  userInfoEndPoint: string;
  revokeEndPoint: string;

  //signupPath: string;

  directOpenExt: string[];

}

interface ENVIRONMENTS_VALUES {
  values: Partial<ENVIRONMENTS>;
}

const ENV: ENVIRONMENTS_VALUES = { values: {} };

function setEnvironments(env: Partial<ENVIRONMENTS>): void {
  ENV.values = {
    ...ENV.values,
    ...env
  };
}

function getEnvironments(): ENVIRONMENTS {
  return ENV.values as ENVIRONMENTS;
}

export { ENVIRONMENTS, getEnvironments, setEnvironments };