import { LocalStorage } from '@/lib/Storage';
import { AppUser } from '@/graphql/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAppUser = (o: any): o is AppUser => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return o !== undefined && o !== null && typeof o === 'object'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    && o.user_id && o.sub;
};

const USER = 'user';

const setUser = (user: AppUser | undefined) => {
  LocalStorage.set<AppUser>(USER, user);
};

const getUser = (): AppUser | undefined => {
  return LocalStorage.get<AppUser>(USER);
};

export { setUser, getUser, isAppUser };