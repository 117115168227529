import { FC } from '../types';
import { StateParam, resolveStateParam } from './StateParam';
import { useForceUpdate } from './useForceUpdate';

////////////////////////////////////////////////////////////////////////////////

interface Context<T extends object> {
  Provider: FC<{children: JSX.Children}>;
  setContext: (o: StateParam<T>) => void;
  getContext: () => T;
  //
  context: [T, (o: StateParam<T>) => void];
  //
}

////////////////////////////////////////////////////////////////////////////////

const createContext = <T extends object,>(init?: T): Context<T> => {

  let fu = (_cause?: string) => {/* istanbul ignore next */console.error('Context.Provider is not provided');};

  const Provider: FC<{children: JSX.Children}> = ({children}: {children: JSX.Children})  => {
    fu = useForceUpdate('createContext');
    return <>{children}</>;
  };

  const setContext = (o: StateParam<T>) => {
    //
    const newValue = resolveStateParam<T>(o, context[0]);
    if(context[0] !== newValue){
      // clear
      for(const k of Object.keys(context[0])){
        delete context[0][k];
      }
      // store
      Object.assign(context[0], newValue);
      fu(`setContext ${JSON.stringify(newValue)}`);

      //
      if(!newValue){
        console.trace();
      }
    }
  };

  const context: [T, (o: StateParam<T>) => void] = [init as T, setContext];

  const getContext = () => {
    return context[0];
  };

  return {
    Provider,
    setContext,
    getContext,
    //
    context,
  };

};

////////////////////////////////////////////////////////////////////////////////

const useContext = <T extends object,>(context: Context<T>): [T, (o: StateParam<T>) => void] =>
{
  return context.context;
};

////////////////////////////////////////////////////////////////////////////////

export { createContext, useContext, Context };
