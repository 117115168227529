import { assert } from '../lib/assert';
import { ReVuNode } from '../types';
import { toArray } from '../lib/toArray';
import { spread } from './spread';
import { insertAfter } from '../lib/insertAfter';
import { removeNode } from '../lib/removeNode';
import { purge, effect, layoutEffect } from '../hooks/context';
import { applyProps, isSvg } from './applyProps';
import { EventInfos, removeEvents } from './applyEvents';
import { ReVuDOMRenderer } from './ReVuDOM';

////////////////////////////////////////////////////////////////////////////////

interface CacheObject {
  revuNode?: ReVuNode;
  idolCount: number;
  node?: Node;
  events?: EventInfos;
  parentIsSvg: boolean;
}

let cacheObjects: {[uid: string]: CacheObject} = {};
let newCacheObjects: {[uid: string]: CacheObject} = {};

const resetCache = () => {
  cacheObjects = {};
  newCacheObjects = {};
};

const purgeCachedNode = (cache: CacheObject) => {
  if(cache.node){
    removeNode(cache.node);
  }
};

////////////////////////////////////////////////////////////////////////////////

let renderCount = 0;

const renderToNodeCore = (
  revuNode: ReVuNode,
  prevNode: Node | null,
  parentElement: Element,
  parentUid: string,
  renderer: ReVuDOMRenderer,
  parentIsSvg: boolean,
): Node | null => {
  assert(typeof revuNode.type === 'string');
  assert(revuNode.fcid);
  assert(revuNode.key !== undefined);
  //
  const uid = `${parentUid}.${(revuNode.type === '$')?revuNode.fcid : revuNode.type }@${revuNode.key}`;
  revuNode.uid = uid;
  return renderToNodeCoreCore(revuNode, prevNode, parentElement, renderer, parentIsSvg);
  //
};

const renderToNodeCoreCore = (
  revuNode: ReVuNode,
  prevNode: Node | null,
  parentElement: Element,
  renderer: ReVuDOMRenderer,
  parentIsSvg: boolean,
): Node | null => {

  assert(typeof revuNode.type === 'string');
  assert(revuNode.uid);

  //////////////////////////////////////////////////////////////////////////////

  if(revuNode.type === '$' || revuNode.type === '#'){
    //
    if(revuNode.type === '$'){
      newCacheObjects[revuNode.uid] = {events: {}, revuNode, idolCount: 0, parentIsSvg: parentIsSvg};
      delete cacheObjects[revuNode.uid];
    }

    // FC NODE or FRAGMENT NODE
    assert(revuNode.children);
    const children = toArray(revuNode.children);
    let workPrevNode: Node | null = prevNode;
    for(const child of children){
      workPrevNode = renderToNodeCore(child, workPrevNode, parentElement, revuNode.uid, renderer, parentIsSvg);
    }
    return workPrevNode;
  }

  //////////////////////////////////////////////////////////////////////////////

  if(revuNode.type === '='){
    // TEXT NODE
    let textNode: Node;
    const cache = cacheObjects[revuNode.uid];
    delete cacheObjects[revuNode.uid];
    if(!cache || cache.revuNode?.type !== '='){
      textNode = document.createTextNode('');
    }else{
      assert(cache.node);
      textNode = cache.node;
    }
    newCacheObjects[revuNode.uid] = {node: textNode, events: {}, revuNode, idolCount: 0, parentIsSvg: parentIsSvg};

    let text = '';
    if(revuNode.text === undefined || revuNode.text === null){
      text = '';
    }else if(typeof revuNode.text === 'object'){
      text = JSON.stringify(revuNode.text);
    }else{
      text = String(revuNode.text);
    }
    if(textNode.textContent !== text){
      textNode.textContent = text;
    }

    insertAfter(parentElement, textNode, prevNode);
    return textNode;
  }

  //////////////////////////////////////////////////////////////////////////////

  // ELEMENT NODE
  let parentIsSvgElement = parentIsSvg;
  let element: Element;
  let events: EventInfos | undefined;
  const cache = cacheObjects[revuNode.uid];
  delete cacheObjects[revuNode.uid];
  if(!cache || ((cache.revuNode?.type as string) !== revuNode.type)){
    if(!parentIsSvg && !isSvg(revuNode.type)){
      element = document.createElement(revuNode.type);
    }else{
      element = document.createElementNS('http://www.w3.org/2000/svg', revuNode.type);
      parentIsSvgElement = true;
    }
    events = {};
  }else{
    element = cache.node as Element;
    events = cache.events;
  }
  const newCache: CacheObject = {node: element, events, revuNode, idolCount: 0, parentIsSvg: parentIsSvgElement};
  newCacheObjects[revuNode.uid] = newCache;

  applyProps(newCache);

  if(revuNode.children){
    const children = toArray(revuNode.children);
    let workPrevNode: Node | null = null;
    for(const child of children){
      workPrevNode = renderToNodeCore(child, workPrevNode, element, revuNode.uid, renderer, parentIsSvgElement);
    }
  }

  insertAfter(parentElement, element, prevNode);
  return element;

};

////////////////////////////////////////////////////////////////////////////////

const effectRequestAnimationFrame: {[renderId:string]: number} = {};

const renderToNode = (
  renderId: string,
  revuNode: ReVuNode,
  targetElement: Element,
  renderer: ReVuDOMRenderer,
  parentIsSvg: boolean,
): ReVuNode => {

  newCacheObjects = {};

  const start = performance.now();

  // render
  const revuNodeTree = spread(renderId, revuNode, renderer);

  const c0 = performance.now();

  //////////////////////////////////////////////////////////////////////////////

  ++renderCount;
  renderToNodeCore(revuNodeTree, null, targetElement, renderId, renderer, parentIsSvg);

  //////////////////////////////////////////////////////////////////////////////

  const c1 = performance.now();

  // layoutEffect
  layoutEffect(renderId);

  const c2 = performance.now();

  // list cache object
  const cachedCtxId: string[] = [];
  for(const uid in cacheObjects){
    const cacheObject = cacheObjects[uid];
    if(cacheObject.revuNode?.cache){
      //
      assert(cacheObject.revuNode.ctxid);
      cachedCtxId.push(cacheObject.revuNode.ctxid);
      //
    }
  }

  const c3 = performance.now();

  // purge
  for(const uid in cacheObjects){
    const cacheObject = cacheObjects[uid];
    purgeCachedNode(cacheObject);
    //
    if(cacheObject.revuNode?.type === '$'){
      let doPurge = true;
      if(cacheObject.revuNode.cache){
        doPurge = false;
      }else{
        for(const ctxid of cachedCtxId){
          assert(cacheObject.revuNode.ctxid);
          if(cacheObject.revuNode.ctxid.indexOf(ctxid) >= 0){
            doPurge = false;
            break;
          }
        }
      }
      if(doPurge){
        assert(cacheObject.revuNode.ctxid);
        purge(renderId, cacheObject.revuNode.ctxid);
        cacheObject.revuNode = undefined;
      }
    }
    //
    if(++cacheObject.idolCount <= renderer.config.idolLimit){
      // lazy purge (renderer.config.idolLimit)
      cacheObject.idolCount = 0;
      newCacheObjects[uid] = cacheObject;
    }else{
      // purge
      if(cacheObject.events){
        removeEvents(cacheObject.events);
      }
    }
  }
  cacheObjects = newCacheObjects;

  const c4 = performance.now();

  // effect
  if(effectRequestAnimationFrame[renderId]){
    cancelAnimationFrame(effectRequestAnimationFrame[renderId]);
  }
  effectRequestAnimationFrame[renderId] = window.requestAnimationFrame(() => {
    effect(renderId);
  });

  const c5 = performance.now();

  // debug
  if(renderer.config.checkDuplicateKey){
    //
    // check id conflict
    {
      const uidCheck: {[uid:string]: ReVuNode} = {};
      const uidTest = (revuNode: ReVuNode) => {
      //
        const uid = revuNode.uid;
        assert(uid);
        if(uidCheck[uid]){
          const cc = cacheObjects[uid];
          console.log('%cReVu Duplicate Key Detected', 'background-color:red;', cc?.node);
          return;
        }
        assert(!uidCheck[uid], uid);
        uidCheck[uid] = revuNode;
        //
        if(revuNode.children){
          for(const child of toArray(revuNode.children)){
            uidTest(child);
          }
        }
      };
      //
      uidTest(revuNodeTree);
    }
    {
      const ctxidCheck: {[ctxid:string]: ReVuNode} = {};
      const ctxidTest = (revuNode: ReVuNode) => {
        if(revuNode.type !== '$'){
          return;
        }
        //
        const ctxid = revuNode.ctxid;
        assert(ctxid);
        if(ctxidCheck[ctxid]){
          if(revuNode.uid){
            const cc = cacheObjects[revuNode.uid];
            console.log('%cReVu Duplicate ctxid Detected', 'background-color:red;', cc?.node, ctxid);
          }else{
            console.log('%cReVu Duplicate ctxid Detected', 'background-color:red;', ctxid);
          }
          return;
        }
        assert(!ctxidCheck[ctxid], ctxid);
        ctxidCheck[ctxid] = revuNode;
        //
        if(revuNode.children){
          for(const child of toArray(revuNode.children)){
            ctxidTest(child);
          }
        }
      };
      //
      ctxidTest(revuNodeTree);
    }
  }

  const c6 = performance.now();
  /*
  console.log('------------------------------------------');
  console.log('renderCount =', renderCount);
  console.log('renderer.config.checkDuplicateKey: ', renderer.config.checkDuplicateKey);

  console.log('c0 =', c0 - start);
  console.log('c1 =', c1 - c0);
  console.log('c2 =', c2 - c1);
  console.log('c3 =', c3 - c2);
  console.log('c4 =', c4 - c3);
  console.log('c5 =', c5 - c4);
  console.log('c6 =', c6 - c5);

  console.log('------------------------------------------');
  */
  return revuNodeTree;

};

export { renderToNode, resetCache, CacheObject };
