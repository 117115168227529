import { assert } from '../lib/assert';
import { getContext } from './context';
import { StateParam, resolveStateParam } from './StateParam';

////////////////////////////////////////////////////////////////////////////////

type Ref<T> = {current: T, setState: (o: StateParam<T>) => void}

interface RefContext<T> {
  initialized?: boolean;
  value?: {current: T, setState: (o: StateParam<T>) => void};
}

////////////////////////////////////////////////////////////////////////////////

const useRef = <T>(init?: T): Ref<T> => {
  const context = getContext<RefContext<T>>({});

  if(!context.data.initialized){
    //
    const setState = (value: StateParam<T>) => {
      assert(context.data.value);
      context.data.value.current = resolveStateParam<T>(value, context.data.value.current);
    };
    //
    context.data.value = {current: init as T, setState};
    context.data.initialized = true;
  }
  //
  assert(context.data.value);
  return context.data.value;
  //
};

////////////////////////////////////////////////////////////////////////////////

export { useRef, Ref };