import { assert } from './assert';

const insertAfter = (parentElement: Element, newNode: Node, prevNode: Node | null): Node => {
  //
  /* istanbul ignore next */
  if (prevNode === newNode) {
    return newNode;
  }
  //
  if(!prevNode){
    if (parentElement.firstChild === newNode) {
      return newNode;
    }
    parentElement.insertBefore(newNode, null);
  }else{
    if (prevNode.nextSibling === newNode) {
      return newNode;
    }
    assert(prevNode.parentNode);
    prevNode.parentNode.insertBefore(newNode, prevNode.nextSibling);
  }
  //
  return newNode;
  //
};

export { insertAfter };
