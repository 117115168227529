import { urljoin } from './urljoin';
import { match, compile } from './pathToRegExp';
import { useLocation } from './useLocation';
import { Path, PathObject, resolvePath } from './resolvePath';
import { useExistsRouterStack } from './useRouterStack';
import { assert } from '..';

////////////////////////////////////////////////////////////////////////////////

interface MatchResult {
  match: string,
  path: string;
  url: string;
  isExact: boolean;
  params: object;
  state?: object;
}

////////////////////////////////////////////////////////////////////////////////

const asteriskConverter = (pathString: string) => {
  return pathString.replace(/\/\*(\/|$)/g, '(.*)');
};

////////////////////////////////////////////////////////////////////////////////

const routeMatch = (ps: PathObject, matchString: string, path: string): MatchResult | undefined => {
  //
  const matchFunc = match(asteriskConverter(matchString));
  const matchResult = matchFunc(path);
  if(matchResult){
    //
    // filter params (ignore key is number)
    const params: {[key:string]: unknown} = {};
    for(const key in matchResult.params){
      if(isNaN(parseInt(key))){
        params[key] = matchResult.params[key];
      }
    }
    //
    return {
      match: matchString,
      path: compile(asteriskConverter(ps.pathname))(matchResult.params),
      url: location.href,
      isExact: true,
      params,
      state: ps.state
    };
  }
  //
  return;
};

////////////////////////////////////////////////////////////////////////////////

const useMatch = (path: Path): MatchResult | undefined=> {
  const stackContext = useExistsRouterStack();

  const location = useLocation();
  assert(stackContext.parent);
  const ps =  resolvePath(path, stackContext.parent?.base);

  // exact
  const exactMatchResult = routeMatch(ps, ps.pathname, location.pathname);
  if(exactMatchResult){
    if(path !== '*'){
      return exactMatchResult;
    }else{
      exactMatchResult.isExact = false;
      return exactMatchResult;
    }
  }

  // fuzzy
  const matchPath = urljoin(ps.pathname, '(.*)');
  const matchResult = routeMatch(ps, matchPath, location.pathname);
  if(matchResult){
    matchResult.isExact = false;
    return matchResult;
  }

  return undefined;
};

////////////////////////////////////////////////////////////////////////////////

export { useMatch, MatchResult };
