import {
  useEffect,
  useState,
  useMemo,
  shortid,
  useEffectOnce,
} from '../..';
import { useLayer, Layer, createLayer } from './useLayer';

////////////////////////////////////////////////////////////////////////////////

const DialogLayer = createLayer(300000);

////////////////////////////////////////////////////////////////////////////////

type Dialog<T> = {
  Dialog: () => JSX.Element;
  show: (context?: T, onClose?: (context?: Partial<T>) => void | Promise<void>) => void;
  close: (context?: Partial<T> | undefined) => void | Promise<void>;
};

////////////////////////////////////////////////////////////////////////////////

type DialogContext<T> = {
  data: T;
  onClose?: (context?: T) => Promise<void> | void;
}

type DialogParams<T> = {
  context: DialogContext<T>;
  close: (context?: Partial<T> | undefined) => void | Promise<void>;
};

////////////////////////////////////////////////////////////////////////////////

function createDialog<T = unknown>(DialogElement: (params: DialogParams<T>) => JSX.Element, layer?: Layer): Dialog<T> {

  //////////////////////////////////////////////////////////////////////////////

  const defaultShowFunc = (_context?: T, _onClose?: (context?: Partial<T>) => void | Promise<void>) => {
    console.log('%cDialog not provided', 'background-color: red;', DialogElement.name);
  };

  const defaultCloseFunc = (_context?: Partial<T> | undefined) => {/*NOOP*/};

  let showFunc = defaultShowFunc;
  let closeFunc = defaultCloseFunc;

  //////////////////////////////////////////////////////////////////////////////

  const Dialog = () => {

    ////////////////////////////////////////////////////////////////////////////

    const render = useLayer(layer ?? DialogLayer);
    const dialogId = useMemo<string>(() => shortid(6), []);
    const [show, setShow] = useState<boolean>();

    const dialogContext = useMemo<DialogContext<T>>(() => {return {data: undefined as unknown as T};}, []);

    ////////////////////////////////////////////////////////////////////////////

    useEffectOnce(() => {
      //
      if(showFunc !== defaultShowFunc){
        console.log('%cAlready Dialog provided', 'background-color: red;', DialogElement.name);
        return;
      }
      //
      showFunc = (context?: T, onClose?: (context?: Partial<T>) => void | Promise<void>)  => {
        if(dialogContext){
          dialogContext.data = context as T;
          dialogContext.onClose = onClose;
        }
        setShow(true);
      };
      //
      closeFunc = (context?: Partial<T>) => {
        if(dialogContext){
          dialogContext.data = {...dialogContext.data, ...context};
        }
        setShow(false);
      };
      //
      return () => {
        showFunc = defaultShowFunc;
        closeFunc = defaultCloseFunc;
      };
      //
    });

    ////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
      //
      if(show){
        render(dialogId, <DialogElement
          context={dialogContext}
          close={(context?: Partial<T> | undefined) => {
            closeFunc(context);
          }}
        />);
      }else{
        render(dialogId, undefined);
        void dialogContext.onClose?.(dialogContext.data);
      }
    }, [show, dialogContext, dialogId, render]);

    ////////////////////////////////////////////////////////////////////////////

    return <></>;

  };

  //////////////////////////////////////////////////////////////////////////////

  return {
    Dialog,
    show: (context?: T, onClose?: (context?: Partial<T>) => void | Promise<void>) : void => {
      return showFunc(context, onClose);
    },
    close: (context?: Partial<T> | undefined) => {
      closeFunc(context);
    },
  };

}

export { Dialog, createDialog, DialogLayer, DialogParams };