import { getContext } from '../hooks/context';
import { assert } from '../lib/assert';
import { useStack, useExistsStack } from '../hooks/useStack';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { MatchResult } from './useMatch';
import { HistoryInstance, getHistoryInstance, resetHistoryInstance } from './useHistory';
import { LocationInstance, getLocationInstance, resetLocationInstance } from './useLocation';

////////////////////////////////////////////////////////////////////////////////

type RouterStack = {
  base: string;
  //
  renderCount: number;
  switchMode: boolean;
  //
  parent?: RouterStack;
  parentBase: string;

  location: LocationInstance;
  history: HistoryInstance;
  match?: MatchResult;
  //
}

const RouterStackKind = Symbol('RouterStack');

////////////////////////////////////////////////////////////////////////////////

const resetRouterStack = () => {
  resetHistoryInstance();
  resetLocationInstance();
};

////////////////////////////////////////////////////////////////////////////////

function useExistsRouterStack(): RouterStack {
  const context = getContext();
  const [stack] = useExistsStack<Partial<RouterStack>>(RouterStackKind, context.ctxid);
  assert(stack);
  return stack as RouterStack;
}

function useRouterStack(): RouterStack {
  //
  const forceUpdate = useForceUpdate('useRouterStack');
  //
  const [stack, getParentStack] = useStack<Partial<RouterStack>>(RouterStackKind, {});
  const [parentStack] = getParentStack();

  stack.history = getHistoryInstance();
  stack.history.forceUpdate = stack.history.forceUpdate ?? forceUpdate;
  stack.location = getLocationInstance();
  //
  stack.base = stack.base ?? '';
  //
  stack.renderCount = stack.renderCount ?? 0;
  stack.switchMode = stack.switchMode ?? false;
  //
  stack.parent = parentStack as RouterStack;
  stack.parentBase = stack.parent?.base ?? '';
  //
  return stack as RouterStack;
  //
}

export { RouterStack, useRouterStack, useExistsRouterStack, resetRouterStack };
