import { Path, resolvePath } from './resolvePath';
import { useCallback } from '../hooks/useCallback';
import { useHistory } from './useHistory';
import buildPath from './buildPath';

////////////////////////////////////////////////////////////////////////////////

type NavigateFunction = (path: Path | number, option?: { replace: boolean, state?: object }) => void;

function useNavigate(): NavigateFunction {
  const history = useHistory();

  return useCallback((path: Path | number, option?: { replace: boolean, state?: object }) => {

    if(typeof path === 'number'){
      history.go(path);
      return;
    }

    const targetPath = resolvePath(path, '');
    const test = buildPath(location.pathname, location.search, location.hash);
    const newPath = buildPath(targetPath.pathname, targetPath.search, targetPath.hash);

    if(test === newPath){
      return;
    }

    if(option?.replace){
      history.replace(newPath, option.state);
    }else{
      history.push(newPath, option?.state);
    }
    return;

  }, []);

}

////////////////////////////////////////////////////////////////////////////////

export { useNavigate };
