import { createContext } from 'revu2';
import { Workspace, Workspaces } from '@/hooks/useWorkspace';
import { Projects, Project } from '@/hooks/useProjects';
import { TermsItems } from '@/hooks/useTerms';

////////////////////////////////////////////////////////////////////////////////

interface AppContextData {

  loading?: boolean;

  initialized?: boolean;
  maintenance?: boolean;

  // workspaces
  workspaces?: Workspaces;
  workspace?: Workspace;
  isWorkspaceAdmin?: boolean;

  // projects
  projects?: Projects
  project?: Project
  isProjectAdmin?: boolean;
  projectTerms?: TermsItems;

  // admin
  isAdminMode?: boolean;

  // force Update
  forceUpdate: number;

  // token Updated
  tokenUpdated: number;
}

const AppContext = createContext<AppContextData>({forceUpdate: 0, tokenUpdated: 0});

////////////////////////////////////////////////////////////////////////////////

const tokenUpdated = () => {
  AppContext.setContext(v => {return {...v, tokenUpdated: v.tokenUpdated + 1};});
};

////////////////////////////////////////////////////////////////////////////////

const forceUpdate = () => {
  AppContext.setContext(v => {return {...v, forceUpdate: v.forceUpdate + 1};});
};

////////////////////////////////////////////////////////////////////////////////

export { AppContextData, forceUpdate, tokenUpdated };
export default AppContext;
