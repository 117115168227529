/* istanbul ignore file */
import { useCss } from '../hooks/useCss';

interface Performance {
  [method:string] : {
    last: number;
    total: number;
    count: number;
  }
}

const measurement = (store: Performance, methodName: string, f: () => unknown) => {
  const a = performance.now();

  const result = f();

  const b = performance.now() - a;
  store[methodName] = store[methodName] ?? {last:0, total:0, count:0};
  //
  store[methodName].count++;
  store[methodName].last = b;
  store[methodName].total += b;
  //
  return result;
};

// performance monitor
const AppWithPerformance = ({pf, children}: {pf: Performance, children: JSX.Children}) => {
  //
  useCss({
    '.revu-performance':{
      position: 'absolute',
      pointerEvents: 'none',
      left: 0,
      bottom: 0,
      backgroundColor: '#00000088',
      color: '#00FF00',
      fontSize: '12px',
      padding: '4px 8px',
      '.label':{
        display: 'inline-block',
        width: '40px',
      },
      '.value':{
        display: 'inline-block',
      },
    },
  }, []);
  //
  const DRAW = 'draw';
  //
  const count = pf?.[DRAW]?.count ?? 0;
  const latest = pf?.[DRAW]?.last ?? 0;
  const total = pf?.[DRAW]?.total ?? 0;
  let avg = 0;
  if(count > 0){
    avg = total / count;
  }
  //
  return <>
    {children}
    <div className="revu-performance">
      <div>
        <div className="label">count</div>
        <div className="value">: {count}</div>
      </div>
      <div>
        <div className="label">latest</div>
        <div className="value">: {latest.toFixed(3)} msec.</div>
      </div>
      <div>
        <div className="label">avg</div>
        <div className="value">: {avg.toFixed(3)} msec.</div>
      </div>
    </div>
  </>;
};

export { Performance, measurement, AppWithPerformance };
