//
const commentReg = /\/\*[\s\S]*?\*\//g;
const lineAttrReg = /([^:]+):([^;]*);/;
const altReg = /(\/\*[\s\S]*?\*\/)|([^\s;{}][^;{}]*(?=\{))|(\})|([^;{}]+;(?!\s*\*\/))/gim;

// Capture groups
const CAP_SELECTOR = 2;
const END_CAP = 3;
const CAP_ATTR = 4;

const isEmpty = (x: unknown): boolean => {
  return x === undefined || x == null || (Array.isArray(x) && x.length === 0);
};

interface CssJson {
  children: { [key: string]: CssJson }
  attributes: { [key: string]: string | string[] }
}

function toJSON(cssString: string): CssJson | undefined {
  //
  const cssJson: CssJson = {
    children: {},
    attributes: {},
  };

  // remove comment
  cssString = cssString.replace(commentReg, '');
  // remove \r \n
  cssString = cssString.replace(/(\r|\n)/gm, '');
  // replace [space, tab,...]} => }
  cssString = cssString.replace(/ *}/g, '}');
  // replace } => ;}
  cssString = cssString.replace(/([^;])}/g, '$1;}');
  // ::v-deep> => ::v-deep >
  cssString = cssString.replace(/(::v-deep|>>>|\/deep\/)(>|~|\+|\|\|)/g, '::v-deep $2');
  //
  for (; ;) {
    //
    const match = altReg.exec(cssString);
    if (!match) {
      break;
    }
    //
    if (!isEmpty(match[CAP_SELECTOR])) {
      //
      const name = match[CAP_SELECTOR].trim();
      const newNode = toJSON(cssString);
      //
      if (newNode) {
        const sel = name.trim();
        if (sel in cssJson.children) {
          for (const att in newNode.attributes) {
            cssJson.children[sel].attributes[att] = newNode.attributes[att];
          }
        } else {
          cssJson.children[sel] = newNode;
        }
      }
      //}
      //
    } else if (!isEmpty(match[END_CAP])) {
      // Node has finished
      return cssJson;
    } else if (!isEmpty(match[CAP_ATTR])) {
      //
      const line = match[CAP_ATTR].trim();
      const attr = lineAttrReg.exec(line);
      if (attr) {
        // Attribute
        const name = attr[1].trim();
        const value = attr[2].trim();
        if (name in cssJson.attributes) {
          const currVal = cssJson.attributes[name];
          if (!(currVal instanceof Array)) {
            cssJson.attributes[name] = [currVal];
          }
          (cssJson.attributes[name] as string[]).push(value);
        } else {
          cssJson.attributes[name] = value;
        }
      }
    }
  }

  //
  if (Object.keys(cssJson.attributes).length === 0 && Object.keys(cssJson.children).length === 0) {
    return;
  }

  return cssJson;
}

function toCSSString(cssJson: CssJson, level = 0): string {
  //
  let cssString = '';
  //
  for (const i in cssJson.attributes) {
    const att = cssJson.attributes[i];
    if (att instanceof Array) {
      for (const at of att) {
        cssString += `${'  '.repeat(level)}${i}:${at};\n`;
      }
    } else {
      cssString += `${'  '.repeat(level)}${i}:${att};\n`;
    }
  }
  //
  for (const i in cssJson.children) {
    cssString += `${'  '.repeat(level)}${i} {\n${toCSSString(cssJson.children[i], level + 1)}}\n`;
  }
  //
  return cssString;
  //
}

function addFcid(cssItem: CssJson, cid: string): CssJson {
  //
  for (const key in cssItem.children) {
    //
    const newSelectors: string[] = [];
    if (key.substring(0, 1) !== '@') {
      const selectorImtes = key.trim().split(',');
      for (const selecors of selectorImtes) {
        //
        const selector = selecors.trim().split(' ');
        const deep = selector.indexOf('::v-deep');
        //
        let targetSelector = selector.length - 1;
        if (deep === 0) {
          if(selector.length === 1){
            selector.push(`[${cid}]`);
          }else{
            selector[1] = `[${cid}] ${selector[1]}`;
          }
        } else {
          if (deep > 0) {
            targetSelector = deep - 1;
          }
          if (selector[targetSelector].indexOf('::') >= 0) {
            const ss = selector[targetSelector].split('::');
            selector[targetSelector] = `${ss[0]}[${cid}]::${ss[1]}`;
          } else if (selector[targetSelector].indexOf(':') >= 0) {
            const ss = selector[targetSelector].split(':');
            selector[targetSelector] = `${ss[0]}[${cid}]:${ss[1]}`;
          } else {
            selector[targetSelector] = `${selector[targetSelector]}[${cid}]`;
          }
        }
        //
        if (deep >= 0) {
          selector.splice(deep, 1);
        }
        const newSelector = selector.join(' ');
        newSelectors.push(newSelector);
        //
      }
    } else {
      newSelectors.push(`${key} `);
    }
    //
    addFcid(cssItem.children[key], cid);
    //
    cssItem.children[newSelectors.join(',')] = cssItem.children[key];
    delete cssItem.children[key];
  }
  //
  return cssItem;
  //
}

function applyCss(css: string): HTMLStyleElement {
  //
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
  //
  return style;
  //
}

export { applyCss, addFcid, toJSON, toCSSString, CssJson };
