import { FC, useCss, useCallback, useForceUpdate } from '../..';

////////////////////////////////////////////////////////////////////////////////

interface LayerContextData {
  key: string | number,
  vNode: JSX.Children | undefined,
}

////////////////////////////////////////////////////////////////////////////////

type Layer = {
  LayerContext: LayerContextData[];
  Provider: FC<{zIndex?:number}>;
};

////////////////////////////////////////////////////////////////////////////////

const createLayer = (initialZIndex: number): Layer => {

  const LayerContext: LayerContextData[] = [];

  const LayerProvider = ({zIndex}: {zIndex?:number}) => {

    useCss({
      '.layer': {
        zIndex: String(zIndex ?? initialZIndex ?? 1000),
        //
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        //
        pointerEvents : 'none',
        '::v-deep *' :{
          pointerEvents : 'auto',
        }
      }
    });

    return (
      <div className="revu-ui layer">
        {
          LayerContext.map(v => v.vNode)
        }
      </div>
    );
  };

  return {LayerContext, Provider: LayerProvider};

};

const useLayer = (layer: {LayerContext: LayerContextData[]}) => {

  const fu = useForceUpdate('ui.useLayer');

  const render = useCallback((key : string | number, vNode: JSX.Children | undefined, forceUpdate = true): boolean => {

    const contexts = layer.LayerContext;

    const find = (tgtKey: string | number) => {
      for(let i = 0; i < contexts.length; i++){
        const v = contexts[i];
        if(v.key === tgtKey){
          return i;
        }
      }
      return -1;
    };
    //
    if(!vNode){
      const i = find(key);
      if(i >= 0){
        // hide
        contexts.splice(i, 1);
        if(forceUpdate){
          fu();
        }
        return true;
      }
    } else {
      const i = find(key);
      if(i < 0){
        // show new
        contexts.push({key, vNode});
        if(forceUpdate){
          fu();
        }
        return true;
      }else{
        // update
        const v = contexts[i];
        v.vNode = vNode;
        if(forceUpdate){
          fu();
        }
        return true;
      }
    }
    //
    return false;
    //
  }, [fu, layer.LayerContext]);

  return render;

};


export { Layer, createLayer, useLayer };
