import { useCss, useEffect, useState, addListener, removeListener, useRef } from 'revu2';
import { Modal, HSplitContainer, HSplit, Svg } from 'revu2/ui';
import { useCustomTheme } from '@/theme';
import closeSvg from '@/assets/close.svg';

const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 70;

type ClickEventType = ((e:Event) => void | Promise<void>) | (() => void | Promise<void>);

const ModalDialog = ({
  children,
  //
  title,
  clickClose,
  footer,
  //
  left,
  primary,
  secondary,
  tertiary,
  clickLeft,
  clickPrimary,
  clickSecondary,
  clickTertiary,
  leftClass,
  primaryClass,
  secondaryClass,
  tertiaryClass,
  leftDisabled,
  primaryDisabled,
  secondaryDisabled,
  tertiaryDisabled,
}:{
  children: JSX.Children,
  //
  title?: JSX.Children | string,
  clickClose?: ClickEventType,
  footer?: JSX.Children,
  //
  left?: JSX.Children | string,
  primary?: JSX.Children | string,
  secondary?: JSX.Children | string,
  tertiary?: JSX.Children | string
  clickLeft?: ClickEventType,
  clickPrimary?: ClickEventType,
  clickSecondary?: ClickEventType,
  clickTertiary?: ClickEventType,
  leftClass?: string | {[key:string]: boolean} | string[],
  primaryClass?: string | {[key:string]: boolean} | string[],
  secondaryClass?: string | {[key:string]: boolean} | string[],
  tertiaryClass?: string | {[key:string]: boolean} | string[],
  leftDisabled?: boolean,
  primaryDisabled?: boolean,
  secondaryDisabled?: boolean,
  tertiaryDisabled?: boolean
}) => {
  //
  const {theme} = useCustomTheme();
  const ref = useRef<HTMLDivElement>();
  //
  useCss({
    '.modal-dialog-component' : {
      borderRadius: theme.borderRadius,
      backgroundColor: theme.dialog.backgroundColor,
      color: theme.dialog.text,

      '.header':{
        padding: '1rem',
        height: `${HEADER_HEIGHT}px`,
        borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
        backgroundColor: theme.dialog.headerBackgroundColor,
        color: theme.dialog.headerText,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        //
        '.close':{
          paddingTop: '2px',
          cursor: 'pointer',
        }
        //
      },

      '.main':{
        padding: '1rem',
      },

      '.footer':{
        height: `${FOOTER_HEIGHT}px`,
        borderTop: `1px solid ${theme.dialog.borderColor}`,
        textAlign: 'right',
        padding: '0 1rem 0.1rem 0',
        'button': {
          marginLeft: '1rem',
        }
      },

      '&:focus':{
        outline:'0',
      }
    }
  }, [theme]);

  const [maxHeight, setMaxHeight] = useState<number>(window.innerHeight - 32);

  useEffect(() => {
    const l = addListener(window, 'resize', () => {
      setMaxHeight(window.innerHeight - 32);
    });
    //
    if(ref.current){
      ref.current.focus();
    }
    //
    return () => {
      removeListener(l);
    };
    //
  }, []);

  const CONTENTS_HEIGHT = (
    ((title || clickClose)? HEADER_HEIGHT : 0)
    +
    ((footer || clickTertiary || clickSecondary || clickPrimary)?FOOTER_HEIGHT:0)
  );

  return (
    <Modal>
      <div
        className="modal-dialog-component"
        style={{maxHeight: `${maxHeight}px`}}
        tabIndex={-1}
        ref={ref}
        onKeyDown={
          async (e: KeyboardEvent) => {
            if(e.key === 'Escape'){
              await clickClose?.(e);
            }
          }
        }
      >
        {
          (title || clickClose)?
            <div className="header">
              <HSplitContainer>
                <HSplit>
                  {title}
                </HSplit>
                <HSplit width={16}>
                  {
                    clickClose?
                      <div className="close" onClick={clickClose}>
                        <Svg src={closeSvg} width={14} color={theme.dialog.headerText}/>
                      </div>
                      :<></>
                  }
                </HSplit>
              </HSplitContainer>
            </div>
            :<></>
        }

        <div className="main" style={{
          maxHeight: `${maxHeight >= CONTENTS_HEIGHT ? maxHeight - CONTENTS_HEIGHT: 0}px`,
          overflow: 'auto'
        }}>
          {children}
        </div>

        {
          (footer || clickLeft || clickTertiary || clickSecondary || clickPrimary)?
            <div className="footer">
              {
                footer?
                  footer
                  :
                  <HSplitContainer>
                    <HSplit>
                      <div className="form-item text-left">
                        {
                          clickLeft?
                            <button type="button"
                              className={leftClass ?? 'left'} onClick={clickLeft} disabled={leftDisabled}>{left}</button>
                            :<></>
                        }
                      </div>
                    </HSplit>
                    <HSplit>
                      <div className="form-item">
                        {
                          clickTertiary?
                            <button type="button"
                              className={tertiaryClass ?? 'tertiary'} onClick={clickTertiary} disabled={tertiaryDisabled}>{tertiary}</button>
                            :<></>
                        }
                        {
                          clickSecondary?
                            <button type="button"
                              className={secondaryClass ?? 'secondary'} onClick={clickSecondary} disabled={secondaryDisabled}>{secondary}</button>
                            :<></>
                        }
                        {
                          clickPrimary?
                            <button type="button"
                              className={primaryClass ?? 'primary'} onClick={clickPrimary} disabled={primaryDisabled}>{primary}</button>
                            :<></>
                        }
                      </div>
                    </HSplit>
                  </HSplitContainer>
              }
            </div>
            :
            <></>
        }

      </div>
    </Modal>
  );
};

export default ModalDialog;