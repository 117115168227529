const Colors = {
  Primary : '#0278BD',
  PrimaryHover : '#00589B',
  //
  Black21 : '#212121',
  Black33 : '#333333',
  Black55 : '#555555',
  Gray700 : '#616161',
  Black75 : '#757575',
  BlackAA : '#aaaaaa',
  Gray400 : '#BDBDBD',
  BlackCB : '#C0C0C0',
  BlackEA : '#EAEAEA',
  BlackEE : '#EEEEEE',
  BlackF5 : '#F5F5F5',
  BlackFA : '#F5F5F5',
  Gray100 : '#F5F5F5',
  Gray50 : '#F5F5F5',
  //
  White : '#ffffff',
  //
  Alert: '#d50000',
  AlertDark: '#b50000',
  AlertLight: '#ffcdd2',
};

const theme = {

  table: {
    headerBackgroundColor: Colors.Gray50,
    backgroundColor: Colors.White,
    hoverBackgroundColor: Colors.Gray100,
    borderColor: Colors.BlackEE,
    headerText: Colors.Black75,
    text: Colors.Black21,
    dragBackgroundColor: Colors.BlackEA,
  },

  modal: {
    backgroundColor: '#00000044',
  },

  label: {
    labelColor: Colors.Black33,
    alertColor: Colors.Alert,
    disabledColor: Colors.BlackAA,
  },

  input: {
    placeholderColor: Colors.Gray400,

    borderColor: Colors.BlackCB,
    color: Colors.Black21,
    bkColor: Colors.Gray50,

    focusBorderColor: Colors.Primary,
    focusColor: Colors.Black21,
    focusBkColor: Colors.Gray50,

    alertBorderColor: Colors.Alert,
    alertColor: Colors.Alert,
    alertBkColor: Colors.AlertLight,

    disabledBorderColor: Colors.BlackEA,
    disabledColor: Colors.BlackAA,
    disabledBkColor: Colors.Gray50,
  },

  fileSelect: {
    dragOver: Colors.BlackEE,
  },

  checkRadio: {
    primaryColor: Colors.Primary,
    borderColor: Colors.BlackCB,
    bkColor: Colors.Gray50,

    disabledColor: Colors.BlackAA,
    disabledBkColor: Colors.Gray50,
  },

  inputButton: {
    primaryColor: Colors.White,
    primaryBkColor: Colors.Primary,
    primaryBorderColor: Colors.Gray50,
    primaryHover: Colors.PrimaryHover,

    primaryDisabledColor: Colors.White,
    primaryDisabledBkColor: Colors.Gray400,
    primaryDisabledBorderColor: Colors.Gray400,

    borderColor: Colors.Gray400,
    color: Colors.Black75,
    bkColor: Colors.White,
    hover: Colors.Black21,

    disabledBorderColor: Colors.BlackEA,
    disabledColor: Colors.Gray400,
    disabledBkColor: Colors.Gray50,
  },

  option: {
    optionBorderColor: Colors.Gray400,
    optionColor: Colors.Black21,
    optionBkColor: Colors.White,
    optionHoverBkColor: Colors.BlackEA,
  },

  //////////////////////////////////////////////////////////////////////////////

  tab: {
    backgroundColor: Colors.White,
    selected: Colors.Primary,
    normal: Colors.Black75,
    border: Colors.BlackEE,
  },

  //////////////////////////////////////////////////////////////////////////////

  color: {
    primary: Colors.Primary,
    primaryHover: Colors.PrimaryHover,
    primaryText: Colors.White,

    alert: Colors.Alert,
    alertHover: Colors.AlertDark,
    alertText: Colors.White,

    white: Colors.White,
    black: Colors.Black21,

    ...Colors,
  },

};

type Theme = typeof theme;

export { Theme, Colors };
export default theme;
