import { num36 } from './num36';
const seQ = [
  0, // 0
  0, // 1
  0, // 2
  0, // 3
  0, // 4
  0, // 5
  0, // 6
  0, // 7
  0, // 8
  0, // 9
];

const MAXS = [
  0, // 0
  num36.decode('z'),            // 1 35
  num36.decode('zz'),           // 2 1295
  num36.decode('zzz'),          // 3 46655
  num36.decode('zzzz'),         // 4 1679615
  num36.decode('zzzzz'),        // 5 60466175
  num36.decode('zzzzzz'),       // 6 2176782335
  num36.decode('zzzzzzz'),      // 7 78364164095
  num36.decode('zzzzzzzz'),     // 8 2821109907455
  num36.decode('zzzzzzzzz'),    // 9 101559956668415
];

function resetShortid(): void {
  for(let i = 0; i < seQ.length; i++){
    seQ[i] = 0;
  }
}

function shortid(length = 9, prefix = ''): string {
  const l = Math.min(length, seQ.length - 1);
  const n = seQ[l];
  //
  seQ[l] += 1;
  if(seQ[l] > MAXS[l]){
    seQ[l] = 0;
  }
  //
  const result = `${'0'.repeat(seQ.length - 1)}${num36.encode(n)}`;
  return `${prefix}${result.substring(result.length - l)}`.toLowerCase();
}

export { shortid, resetShortid };
