import { Router, DebugRouter } from 'revu2/router';
import ReVuDOM from 'revu2/dom';
import { ReVuUI } from 'revu2/ui';
import { getEnvironments } from '@/../config/env';
import AppContext from '@/context/AppContext';
import AppRouter from '@/pages/AppRouter';

import * as Sentry from '@sentry/browser';
import version from '../version';
import { BrowserTracing } from '@sentry/tracing';

if(getEnvironments().debug){
  //DebugRouter(true);
  ReVuDOM.performanceMonitor(true);
  ReVuDOM.debug(true);
}

if(getEnvironments().sentryDsn){
  Sentry.init({
    dsn: getEnvironments().sentryDsn,
    environment: process.env.NODE_ENV,
    integrations: [new BrowserTracing()],
    release: `${version.name}@${version.version}`,
    tracesSampleRate: 1.0,
  });
  Sentry.setTag('server-client', 'client');
}

const app = document.getElementById('app');
ReVuDOM.render((
  <AppContext.Provider>
    <Router base={getEnvironments().BASE_URL}>
      <ReVuUI>
        <AppRouter/>
      </ReVuUI>
    </Router>
  </AppContext.Provider>
), app);

window.addEventListener('error', e => {
  // prompt user to confirm refresh
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});
