import { assert } from '../lib/assert';

////////////////////////////////////////////////////////////////////////////////

interface UseLocation {
  href: string;
  //
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  //
  pathname: string;
  search: string;
  searchParams: URLSearchParams;
  hash: string;
  //
  username: string;
  password: string;
  origin: string;
  //
  state?: object;
}

///////////////////////////////////////////

function locationAssign(loc: UseLocation, newLoc: URL): UseLocation{
  //
  loc.href = newLoc.href;
  //
  loc.protocol = newLoc.protocol;
  loc.host = newLoc.host;
  loc.hostname = newLoc.hostname;
  loc.port = newLoc.port;
  //
  loc.pathname = newLoc.pathname;
  loc.search = newLoc.search;
  loc.searchParams = newLoc.searchParams;
  loc.hash = newLoc.hash;
  //
  loc.username = newLoc.username;
  loc.password = newLoc.password;
  loc.origin = newLoc.origin;
  //
  return loc;
}

////////////////////////////////////////////////////////////////////////////////

type LocationInstance = {
  location: UseLocation,
  setLocation: (pathname: string, s?: object)=> boolean,
  initLocation: (href: string)=> void
}

function createLocation(): LocationInstance {
  //
  const currentLocation: UseLocation = {} as UseLocation;
  //
  const initLocation = (fullPath: string): void => {
    locationAssign(currentLocation, new URL(fullPath));
  };
  //
  const setLocation = (pathname: string, state?: object): boolean => {
    try{
      locationAssign(currentLocation, new URL(`${currentLocation.origin}${pathname}`));
      currentLocation.state = state;
      return true;
    }catch(e)/* istanbul ignore next */{
      console.warn('setLocation error', e);
      return false;
    }
  };
  //
  return { location: currentLocation, setLocation, initLocation };
  //
}

////////////////////////////////////////////////////////////////////////////////

let locationInstance: LocationInstance = createLocation();

function getLocationInstance(){
  return locationInstance;
}

function resetLocationInstance(){
  locationInstance = createLocation();
}

function useLocation(): UseLocation {
  assert(locationInstance);
  locationInstance.initLocation(location.href);
  return locationInstance.location;
}

////////////////////////////////////////////////////////////////////////////////

export { LocationInstance, UseLocation, useLocation, resetLocationInstance, getLocationInstance };