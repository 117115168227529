import { urljoin } from './urljoin';

////////////////////////////////////////////////////////////////////////////////

interface PathObject {
  pathname: string;
  search?: string;
  hash?: string;
  state?: object;
}

interface PathResult extends PathObject {
  fullpath: string;
}

type Path = PathObject | string

////////////////////////////////////////////////////////////////////////////////

const normalizePathObject = (path: PathObject, basePath?: string): PathResult => {
  //
  const searchText = path.search? ((path.search.substring(0, 1) === '?')?path.search:`?${path.search}`):'';
  const hashText = path.hash? ((path.hash.substring(0, 1) === '#')?path.hash:`#${path.hash}`):'';
  //
  let pathname = `${path.pathname}${searchText}${hashText}`;
  let hash = '';
  let search = '';
  //
  const hashHash = pathname.indexOf('#');
  if(hashHash > 0){
    hash = pathname.substring(hashHash);
    pathname = pathname.substring(0, hashHash);
  }
  const hasQuery = pathname.indexOf('?');
  if(hasQuery > 0){
    search = pathname.substring(hasQuery + 1);
    pathname = pathname.substring(0, hasQuery);
  }
  //
  const targetPathName = basePath ? urljoin(basePath, pathname) : pathname;
  //
  return {
    pathname: targetPathName,
    search,
    hash,
    state: path.state,
    fullpath: `${targetPathName}${search ? `?${search}`: ''}${hash}`,
  };
  //
};

////////////////////////////////////////////////////////////////////////////////

const resolvePath = (path: Path, basePath: string): PathResult => {

  let targetPathname: string;
  if(typeof path === 'string'){
    targetPathname = path;
  }else{
    targetPathname = path.pathname;
  }
  //
  let basePathname: string | undefined = basePath;
  if(targetPathname.indexOf('http://') === 0 || targetPathname.indexOf('https://') === 0 || targetPathname.substring(0,1) === '/'){
    basePathname = undefined;
  }

  let targetPath: PathResult;
  if(typeof path === 'string'){
    targetPath = normalizePathObject({pathname: path}, basePathname);
  }else{
    targetPath = normalizePathObject(path, basePathname);
  }

  return targetPath;
};

////////////////////////////////////////////////////////////////////////////////

export { Path, PathObject, resolvePath };