import { useMemo } from './useMemo';

////////////////////////////////////////////////////////////////////////////////

const useCallback = <F>(f: F, check?: unknown[]): F => {
  return useMemo<F>(() => f, check);
};

////////////////////////////////////////////////////////////////////////////////

export { useCallback };
