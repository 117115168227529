import { useRouterStack } from './useRouterStack';

////////////////////////////////////////////////////////////////////////////////

const Router = ({base, children} : {base?: string, children: JSX.Children}) => {
  const routeContext = useRouterStack();
  routeContext.base = base ?? '/';
  return <>{children}</>;
};

////////////////////////////////////////////////////////////////////////////////

export { Router };
