import { useCss } from 'revu2';
import { useCustomTheme } from '@/theme';

////////////////////////////////////////////////////////////////////////////////

interface OptionItem {
  key: string,
  title: string | JSX.Children,
  className?: string,
  hide?: boolean,
  onClick: (e: PointerEvent) => void | Promise<void>
}

const OptionSelect = ({options, maxHeight}: {options: OptionItem[], maxHeight?: string}) => {

  const {theme} = useCustomTheme();
  useCss({
    '.bimstok-option-select': {
      //
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar':{
        display:'none',
      },
      //
      marginTop: '8px',
      border: `1px solid ${theme.popup.borderColor}`,
      borderRadius: theme.borderRadius,
      backgroundColor: theme.popup.backgroundColor,
      color: theme.popup.text,
      padding: '4px',
      '.item' : {
        padding: '4px 16px',
        fontSize: '0.9rem',
        borderRadius: '6px',
        '&:hover' : {
          backgroundColor: theme.popup.hoverBackgroundColor,
          color: theme.popup.hoverText,
        }
      },
      '.item.remove' : {
        color: theme.color.alert,
      }
    },
  }, [theme]);

  const scrollStyle = maxHeight? {maxHeight, overflow: 'scroll'} : {};

  return (
    <div className="bimstok-option-select shadow" style={scrollStyle}>
      {
        options.map((o) => {
          if(o.hide === true){
            return <></>;
          }
          return <div
            key={o.key}
            className={'item ' + (o.className ?? '')}
            onClick={
              (e:PointerEvent) => {
                void o.onClick(e);
              }
            }>{o.title}</div>;
        })
      }
    </div>
  );
};

export { OptionItem };
export default OptionSelect;