import { useLocation } from '@/ReVu2/router';
import { getEnvironments } from '../../../config/env';

const SignUpComponent = () => {
  //
  const loc = useLocation();
  const username = loc.searchParams.get('username');
  location.href = `${getEnvironments().signUpEndPoint}${username?`?username=${encodeURIComponent(username)}`: ''}`;
  return <></>;
  //
};

export default SignUpComponent;