import { assert } from '../lib/assert';
import { useMatch } from './useMatch';
import { useRouterStack, RouterStack } from './useRouterStack';

////////////////////////////////////////////////////////////////////////////////

const Route = ({path, exact, children}: {path: string, exact?: boolean, children: JSX.Children}) => {

  const routeContext = useRouterStack();
  const match = useMatch(path);

  const parentContext = routeContext.parent;
  assert(parentContext);
  if(parentContext.switchMode && parentContext.renderCount > 0){
    return <></>;
  }

  if(!match || exact && !match.isExact){
    return <></>;
  }

  const matchParams: {[key:string]: string} = {};
  let parent: RouterStack | undefined = parentContext;
  while(parent){
    Object.assign(matchParams, parent.match?.params);
    parent = parent.parent;
  }
  Object.assign(matchParams, match.params);

  routeContext.base = match.path;
  routeContext.match = match;
  routeContext.match.params = matchParams;

  parentContext.renderCount++;

  return <>{children}</>;
};

export { Route };