import { getContext } from './context';
import { isSameArray } from '../lib/isSameArray';

////////////////////////////////////////////////////////////////////////////////

type EffectCloser = () => void;

interface EffectContext {
  checked?: unknown[];
  purgeFunc?: EffectCloser;
}

////////////////////////////////////////////////////////////////////////////////

const useEffect = (f: () => EffectCloser | void, check?: unknown[]): void => {
  const context = getContext<EffectContext>({});

  if(isSameArray(context.data.checked, check)){
    return;
  }
  //
  context.data.checked = check;

  const effect = () => {
    if(context.data.purgeFunc){
      context.data.purgeFunc();
      context.purgeFuncs = context.purgeFuncs.filter((p) => p !== context.data.purgeFunc);
      context.data.purgeFunc = undefined;
    }
    const purgeEffect = f();
    if(purgeEffect && typeof purgeEffect === 'function'){
      context.data.purgeFunc = purgeEffect;
      context.purgeFuncs.push(purgeEffect);
    }
  };
  context.effects.push(effect);

};

const useEffectOnce = (f: () => EffectCloser | void): void => {
  useEffect(f, []);
};

////////////////////////////////////////////////////////////////////////////////

export { useEffect, useEffectOnce };