import { Ref } from './hooks/useRef';

type FunctionComponent<T = {[key:string]: unknown}> = (props: T) => ReVuNode;
type FC<T = {[key:string]: unknown}> = FunctionComponent<T>;

type ClassProps = string | string[] | {[className: string]: boolean};
type StyleProps = {[key: string]: number | string | undefined};

type TextChild = string | number | boolean | Date | object | null | undefined;
type Child = ReVuNode | TextChild;
type Children = Child | Array<Child>;

interface Params {
  class?: ClassProps,
  className?: ClassProps,
  style?: StyleProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any> | Ref<any>[];
  //
  [key: string]: unknown;
}

interface ReVuNode {
  type: string | FunctionComponent; // '$' -> Function, '#' -> Fragment, '=' -> text
  funcName?: string;
  params: Params; // params
  //
  key?: string | number; // index key
  //
  text?: TextChild;  // for text node
  children?: ReVuNode[] | ReVuNode; // children node(s)
  //
  cache?: boolean;
  //
  fcid?: string;  // function id
  uid?: string; // unit id
  ctxid?: string; // context id
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isReVuNode(o: any): o is ReVuNode {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return o !== undefined && o !== null && (o.type !== undefined) && (o.params !== undefined);
}

export {
  Ref,
  FunctionComponent, FC,
  StyleProps, ClassProps, Params,
  ReVuNode, isReVuNode,
  Child,
  Children,
};
