type ListenOption = { capture?: boolean; once?: boolean; passive?: boolean };
type EventFunction = (e: Event) => unknown;

interface ListenInfo {
  el: EventTarget;
  name: string;
  func: EventFunction;
  option?: ListenOption;
  //
  funcCore: EventFunction;
  remove: () => void;
}

function removeListener(evInfo: ListenInfo | null | undefined): void {
  /* istanbul ignore next */
  if (!evInfo) {
    return;
  }
  evInfo.el.removeEventListener(evInfo.name, evInfo.func, evInfo.option);
}

function addListener(
  el: EventTarget,
  name: string,
  func: EventFunction,
  option?: ListenOption,
): ListenInfo {
  //
  const evInfo: ListenInfo = {
    el,
    name,
    func: /* istanbul ignore next */() => {/* */},
    option,
    remove: /* istanbul ignore next */() => {/* */},
    funcCore: func
  };
  evInfo.remove = () => removeListener(evInfo);
  evInfo.func = (e: Event) => {
    return evInfo.funcCore(e);
  };
  //
  el.addEventListener(evInfo.name, evInfo.func, evInfo.option);
  //
  return evInfo;
}

function changeListenerFunc(evInfo: ListenInfo, f: (ev:Event) => void){
  evInfo.funcCore = f;
}

export {
  ListenInfo,
  ListenOption,
  EventFunction,
  addListener,
  removeListener,
  changeListenerFunc,
};
