import { setEnvironments } from './env';

setEnvironments({

  sentryDsn: 'https://b2b7725410b04937b4ff2072ed7d6890@sentry.earlysso.com/9',
  
  termsVersion: '1.0',
  termsUrl: 'https://www.bimstok.com/terms/',

  lng: 'ja',
  debug: false,
  environment: 'production',
  BASE_URL: '/',
  //
  graphQlEndPoint: '/graphql',
  //
  uploadEndPoint: '/upload',
  downloadEndPoint: '/download',
  removeEndPoint: '/remove',
  //
  signinEndPoint: '/api/signin',
  signCallbackEndPoint: '/api/signin_callback',
  getTokenEndPoint: '/api/get_token',
  updateTokenEndPoint: '/api/update_token',
  revokeEndPoint: '/api/revoke_token',
  userInfoEndPoint: '/api/userinfo',
  //
  signUpEndPoint: '/api/signup',
  //

  directOpenExt: ['.pdf', '.xml', '.html', '.htm', '.jpeg', '.jpg', '.png', '.gif', '.webp', '.tiff', 'tif'],

});
