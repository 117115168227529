import { CSS } from '../../../lib/styleToString';
import { Theme } from '../defaultTheme';

const pureInput = 'input:not([type="checkbox"]):not([type="range"]):not([type="radio"]):not([type="button"]):not([type="submit"]):not([type="file"]), textarea';

const inputElementCss = (theme: Theme): CSS => {
  return {

    ////////////////////////////////////////////////////////////////////////////

    // input
    [pureInput] : {
      display: 'block',
      padding: '0.5rem 0.5rem',
      borderRadius: '0.25rem',
      //
      border: `1px solid ${theme.input.borderColor}`,
      color: theme.input.color,
      backgroundColor: theme.input.bkColor,

      '&::placeholder':{
        color: theme.input.placeholderColor,
      },

      '&:focus':{
        outline: 'none',
        border: `1px solid ${theme.input.focusBorderColor}`,
        color: theme.input.focusColor,
        backgroundColor: theme.input.focusBkColor,
      },

      '&:disabled':{
        border: `1px solid ${theme.input.disabledBorderColor}`,
        color: theme.input.disabledColor,
        backgroundColor: theme.input.disabledBkColor,
      },

      '&.alert':{
        border: `1px solid ${theme.input.alertBorderColor}`,
        color: theme.input.alertColor,
        backgroundColor: theme.input.alertBkColor,
      },
      '&.inline':{
        display: 'inline-block',
      },

      '&:read-only':{
        border: '1px solid #00000000',
        color: theme.input.color,
        backgroundColor: '#00000000',
      },
    },

    'input[select-input-readonly="true"]':{
      border: '1px solid #00000000 !important',
      color: `${theme.input.color} !important`,
      backgroundColor: '#00000000 !important',
    },
    'input[select-input-readonly="false"]':{
      border: `1px solid ${theme.input.borderColor} !important`,
      color: `${theme.input.color} !important`,
      backgroundColor: `${theme.input.bkColor} !important`,
    },

    ////////////////////////////////////////////////////////////////////////////

    // input range
    'input[type="range"]': {
      display: 'inline-block',
      '-webkit-appearance': 'none', // これ無しだとスタイルがほぼ全く反映されないので注意
      appearance: 'none',
      cursor: 'pointer', // カーソルを分かりやすく
      background: '#8acdff', // バーの背景色
      height: '14px', // バーの高さ
      width: '100%', // スライダーの幅
      borderRadius: '10px', // バーの端の丸み
      border: 'solid 3px #dff1ff', // バーまわりの線
      outline: '0', /* アウトラインを消して代わりにfocusのスタイルをあてる */
      '&:focus': {
        boxShadow: '0 0 3px rgb(0, 161, 255)',
      },
      '&::-webkit-slider-thumb, &::-moz-range-thumb': {
        width: '24px', // 幅
        height: '24px', // 高さ
        background: '#53aeff', // 背景色
        borderRadius: '50%', // 円形に
        boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.15)', // 影

      },
      // -webkit-向けのつまみ
      '&::-webkit-slider-thumb': {
        '-webkit-appearance': 'none', // デフォルトのつまみのスタイルを解除
      },
      // -moz-向けのつまみ
      '&::-moz-range-thumb': {
        border: 'none', // デフォルトの線を消す
      },
      // つまみをドラッグしているときのスタイル
      '&:active::-webkit-slider-thumb': {
        boxShadow: '0px 5px 10px -2px rgba(0, 0, 0, 0.3)',
      }
    },

    // input checkbox
    'input[type="checkbox"]': {
      appearance: 'none',
      display: 'inline-block',

      '&+*':{
        display: 'inline-block',
        padding: '0 1.6rem 0 1.6rem',
        verticalAlign: 'top',
      },

      '&::before':{
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '1.25rem',
        width: '1.25rem',
        cursor: 'pointer',
        //
        borderRadius: '0.3rem',
        border: '1px solid',
        borderColor: theme.checkRadio.borderColor,
        backgroundColor: theme.checkRadio.bkColor,
      },

      '&:checked::before':{
        borderColor: theme.checkRadio.primaryColor,
        backgroundColor: theme.checkRadio.primaryColor,
      },
      '&:checked::after':{
        content: '""',
        position: 'absolute',
        top: 'calc((100% - 1.25rem) / 2 + 0.35rem)',
        left: '0.25rem',
        height: '0.3rem',
        width: '0.75rem',
        cursor: 'pointer',
        //
        transform: 'rotate(-45deg)',
        borderBottom: '2px solid',
        borderLeft: '2px solid',
        borderColor: theme.checkRadio.bkColor,
      },

      '&:indeterminate::before':{
        borderColor: theme.checkRadio.primaryColor,
        backgroundColor: theme.checkRadio.primaryColor,
      },
      '&:indeterminate::after':{
        content: '""',
        position: 'absolute',
        top: '0.4rem',
        left: '0.25rem',
        height: '0.3rem',
        width: '0.75rem',
        borderBottom: '2px solid',
        borderColor: theme.checkRadio.bkColor,
      },

      // disabled
      '&:disabled::before':{
        cursor: 'auto',
        borderColor: theme.checkRadio.borderColor,
        backgroundColor: theme.checkRadio.disabledBkColor,
      },
      '&:disabled::after':{
        cursor: 'auto',
        borderColor: theme.checkRadio.disabledBkColor,
      },

      '&:checked:disabled::after':{
        borderColor: theme.checkRadio.disabledColor,
      },
      '&:indeterminate:disabled::after':{
        borderColor: theme.checkRadio.disabledColor,
      },

      '&:focus::before':{
        borderColor: theme.checkRadio.primaryColor,
      },

    },

    ////////////////////////////////////////////////////////////////////////////

    // input radio
    'input[type="radio"]': {

      appearance: 'none',
      display: 'inline-block',

      '&+*':{
        display: 'inline-block',
        padding: '0 0 0 1.6rem',
        verticalAlign: 'top',
      },

      '&::before':{
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '1.25rem',
        width: '1.25rem',
        //
        borderRadius: '50%',
        border: '1px solid',
        borderColor: theme.checkRadio.borderColor,
        backgroundColor: theme.checkRadio.bkColor,
      },
      '&:checked::before':{
        borderColor: theme.checkRadio.primaryColor,
        backgroundColor: theme.checkRadio.bkColor,
      },

      '&:checked::after':{
        content: '""',
        position: 'absolute',
        top: '4px',
        left: '4px',
        height: 'calc(1.25rem - 8px)',
        width: 'calc(1.25rem - 8px)',
        //
        transform: 'none',
        borderRadius: '50%',
        backgroundColor: theme.checkRadio.primaryColor,
      },

      // disabled
      '&:disabled::before':{
        borderColor: theme.checkRadio.borderColor,
        backgroundColor: theme.checkRadio.disabledBkColor,
      },
      '&:disabled::after':{
        backgroundColor: theme.checkRadio.disabledBkColor,
      },
      '&:checked:disabled::after':{
        backgroundColor: theme.checkRadio.disabledColor,
      },

      '&:focus::before':{
        borderColor: theme.checkRadio.primaryColor,
      },

    },

    ////////////////////////////////////////////////////////////////////////////
    // button

    'input[type="button"],input[type="submit"]': {
      display: 'block',
      padding: '0.5rem 1rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      fontWeight: 'bold',
      //
      border: `1px solid ${theme.inputButton.borderColor}`,
      color: theme.inputButton.color,
      backgroundColor: theme.inputButton.bkColor,
      '&:hover':{
        borderColor: theme.inputButton.hover,
        color: theme.inputButton.hover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

    'input[type="submit"]': {
      border: `1px solid ${theme.inputButton.primaryBorderColor}`,
      color: theme.inputButton.primaryColor,
      backgroundColor: theme.inputButton.primaryBkColor,
      '&:hover':{
        backgroundColor: theme.inputButton.primaryHover,
        color: theme.inputButton.primaryColor,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

  };
};

export { inputElementCss, pureInput };