import { getContext } from './context';
import { useCallback } from './useCallback';

////////////////////////////////////////////////////////////////////////////////

const useForceUpdate = (cause?: string): (causeEx?: string) => void => {
  const context = getContext();

  const fu = useCallback((causeEx?: string) => {
    context.renderer.renderRequest(context.ctxid, `useForceUpdate ${cause ?? '-'} ${causeEx ?? '-'}`);
  }, [context]);

  return fu;

};

////////////////////////////////////////////////////////////////////////////////

export { useForceUpdate };