import { useState, useCss, useEffect, jsxs, isJSX } from '../';
import { useTheme } from './theme/useTheme';
import { useLocation, useNavigate } from '../router';

interface TabItem {
  key?: string,
  name: string,
  content: JSX.Children,
  tabStyle?: {[key:string]: string | number},
  contentStyle?: {[key:string]: string | number},
}

type TabItems = TabItem[];

const Tab = ({tabs, hashNavigate, initialSelected}: {
  tabs: TabItem[],
  hashNavigate?: boolean,
  initialSelected?: number
}) => {

  const {theme} = useTheme();

  useCss({
    '.tab-component' : {
      width: '100%',
      minHeight: '100%',
      padding: '1rem',
      backgroundColor: theme.tab.backgroundColor,
      //
      '.name':{
        display: 'inline-block',
        padding: '0.5rem 1rem',
        color: theme.tab.normal,
        borderBottom: `4px solid ${theme.tab.backgroundColor}`,
        cursor: 'pointer',
        '&.selected':{
          color: theme.tab.selected,
          borderBottom: `4px solid ${theme.tab.selected}`
        }
      },
      '.content':{
        width: '100%',
        borderTop: `2px solid ${theme.tab.border}`,
        display: 'none',
        '&.selected':{
          display: 'block',
        }
      }
    }
  }, [theme]);

  const location = useLocation();
  const navigate = useNavigate();
  //
  const initialTab = location.hash ? Number(location.hash.substring(1,2)): initialSelected ?? 0;
  const [selected, setSelected] = useState<number>(initialTab);

  useEffect(() => {
    setSelected(Number(location.hash.substring(1,2)));
  }, [location.hash]);

  const click = (e:Event, i: number) => {
    e.stopPropagation();
    setSelected(i);
    if(hashNavigate){
      navigate(`${location.pathname}${location.search}#${i}`);
    }
  };

  return (
    <div  className="tab-component">
      {
        tabs.map((tab, index) => {
          return <div
            key={tab.key?tab.key: (console.log('%cTab Key Error', 'background-color: red;'), undefined)}
            className={index === selected?'name selected':'name'}
            onClick={(e: Event) => click(e, index)}
          >{tab.name}</div>;
        })
      }
      {
        tabs.map((tab, index) => {
          const children = tab.content;
          return <div
            key={tab.key?`${tab.key}_content`:undefined}
            className={index === selected?'content selected':'content'}
          >{
              (isJSX(children) && (typeof children?.type === 'function'))?
                jsxs(children.type, {show: (index === selected),...children.params}, children.key)
                :
                children
            }</div>;
        })

      }
    </div>
  );
};

export { Tab, TabItem, TabItems };
