import {
  useGlobalCss,
  margeProps,
  ClassProps,
  useRawCss,
  StyleProps,
} from '../..';
import { useTheme } from './theme/useTheme';
import { Align } from './Align';

////////////////////////////////////////////////////////////////////////////////

const DEF_Z_INDEX = '100000';

////////////////////////////////////////////////////////////////////////////////

interface ModalParams {
  horizontal?: 'left' | 'center' | 'right'
  vertical?: 'start' | 'center' | 'end'
  zIndex?: string,
  backgroundColor?: string,
  onClick?: (e?:PointerEvent) => void,
  show?: boolean
  children: JSX.Children
  //
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
}

////////////////////////////////////////////////////////////////////////////////

const Modal = (
  {horizontal, vertical, zIndex, backgroundColor, onClick, show, children, ...inherited}: ModalParams
) => {

  const {theme} = useTheme();
  useGlobalCss({
    '.modal' : {
      position: 'absolute',
      width: '100%',
      height: '100%',
      animation: 'revuModalFadeIn 0.1s ease 0s 1 normal',
    },
  }, [theme]);
  useRawCss(`@keyframes revuModalFadeIn {
  0% {opacity: 0}
  20% {opacity: 0}
  40% {opacity: 0.1}
  60% {opacity: 0.2}
  80% {opacity: 0.8}
  100% {opacity: 1.0}
}`);

  if(show === false){
    return <></>;
  }

  const inheritedProps = margeProps(inherited, {
    style: {filter: 'drop-shadow(5px 5px 5px rgba(0,0,0,0.2))'},
  });

  return <div
    className="modal"
    style={{
      zIndex: zIndex ?? DEF_Z_INDEX,
      backgroundColor: backgroundColor ?? theme.modal.backgroundColor,
    }}
    onClick={(e:PointerEvent) => onClick?.(e)}
  >
    <Align horizontal={horizontal} vertical={vertical}>
      <div {...inheritedProps}>
        {children}
      </div>
    </Align>
  </div>;

};

////////////////////////////////////////////////////////////////////////////////

export { Modal };
