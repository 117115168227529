import { assert } from '../lib/assert';
import { Fragment } from '../jsx/jsx-runtime';
import { ReVuNode, isReVuNode, FunctionComponent } from '../types';
import { shortid } from '../lib/shortid';
import { toArray } from '../lib/toArray';
import { selectContext } from '../hooks/context';
import { ReVuDOMRenderer } from './ReVuDOM';

////////////////////////////////////////////////////////////////////////////////

const fcidMap = new Map<FunctionComponent, string>();

const getFcid = (fc: FunctionComponent) => {
  let fcid = fcidMap.get(fc);
  if(fcid === undefined){
    fcid = shortid(4);
    fcidMap.set(fc, fcid);
  }
  return fcid;
};

const resetFcMap = () => {
  fcidMap.clear();
};

////////////////////////////////////////////////////////////////////////////////

const setFcid = (revuNodes: ReVuNode | ReVuNode[], fcid: string): void => {
  //
  if(Array.isArray(revuNodes)){
    for(const revNode of revuNodes){
      setFcid(revNode, fcid);
    }
    return;
  }
  //
  if(isReVuNode(revuNodes)){
    if(revuNodes.fcid === undefined){
      revuNodes.fcid = fcid;
    }
    if(revuNodes.children){
      setFcid(revuNodes.children, fcid);
    }
    if(revuNodes.params.children){
      setFcid(revuNodes.params.children as ReVuNode, fcid);
    }
  }else{
    //
    // NOP
  }
  //
};

////////////////////////////////////////////////////////////////////////////////

const resolveFc = (revuNode: ReVuNode, parent: ReVuNode, parentCtxid: string, renderer: ReVuDOMRenderer) => {
  assert(typeof revuNode.type === 'function');

  const fcid = getFcid(revuNode.type);
  const ctxid = `${parentCtxid}.${fcid}@${revuNode.key || 0}`;

  // select context
  selectContext(ctxid, fcid, revuNode.type.name, renderer);
  // do function
  const resolvedRevuNode = revuNode.type(revuNode.params);

  // spread
  const result = spreadCore(resolvedRevuNode, parent, fcid, ctxid, 0, renderer);
  return {type: '$', funcName: revuNode.type.name, ctxid, fcid, params: {}, children: [result], key: revuNode.key || 0, cache: revuNode.cache};
  //
};

////////////////////////////////////////////////////////////////////////////////

const spreadCore = (
  revuNodeSrc: ReVuNode,
  parent: ReVuNode,
  parentFcid: string,
  parentCtxid: string,
  index: number,
  renderer: ReVuDOMRenderer
): ReVuNode => {

  const revuNode = {...revuNodeSrc};

  revuNode.key = revuNode.key || index;
  setFcid(revuNode, parentFcid);

  if(typeof revuNode.type === 'function'){

    // fc
    if(revuNode.type !== Fragment){
      return resolveFc(revuNode, parent, parentCtxid, renderer);
    }

    // fragment
    const resolvedRevuNode = revuNode.type(revuNode.params);
    resolvedRevuNode.key = revuNode.key;
    // spread
    const ctxid = `${parentCtxid}.${revuNode.key || 0}`;
    return spreadCore(resolvedRevuNode, parent, parentFcid, ctxid, 0, renderer);
    //
  }

  // children
  if(revuNode.children){
    assert(revuNode.fcid);
    //
    const ctxid = `${parentCtxid}.${revuNode.key || 0}`;
    //
    const results: ReVuNode[] = [];
    const revuNodes = toArray(revuNode.children);
    for(let i = 0; i < revuNodes.length; i++){
      const child = revuNodes[i];
      assert(isReVuNode(child));
      // spread
      results.push(spreadCore(child, parent, parentFcid, ctxid, i, renderer));
    }
    revuNode.children = results;
    //
  }

  return revuNode;
};

////////////////////////////////////////////////////////////////////////////////

const spread = (
  renderId: string,
  revuNode: ReVuNode,
  renderer: ReVuDOMRenderer
): ReVuNode => {
  assert(typeof revuNode.type === 'function');

  const parent: ReVuNode = {type: '#', ctxid: renderId, params: {}};
  assert(parent.ctxid);
  return resolveFc(revuNode, parent, parent.ctxid, renderer);
};

////////////////////////////////////////////////////////////////////////////////

export { spread, resetFcMap };
