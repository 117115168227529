import { setEnvironments } from './env';

setEnvironments({

  sentryDsn: 'https://3fa18a1312ed42c4a97205f435d7f189@sentry.earlysso.com/4',

  graphQlEndPoint: '/graphql',

  lng: 'ja',
  debug: false,
  environment: 'stage',
  //signupPath: 'https://stage.earlysso.com/ui/create_account_form?client_id=hx66SuvXLlb79nWvxVKj13qO2q2SA8tDv8ZZZjoENEtHI4OM1Qf62cdLFkIzcRGvACssyPCh',
});
