import { assert } from 'revu2';
import CryptoJS from 'crypto-js';
import { getEnvironments } from '@/../config/env';
import { getSignInInfo } from '@/context/SignInInfo';

////////////////////////////////////////////////////////////////////////////////

const encrypt = (input: string, pass = false) => {
  if(getEnvironments().debug){
    return input;
  }
  if(pass){
    return input;
  }
  const signInInfo = getSignInInfo();
  assert(signInInfo);
  return CryptoJS.AES.encrypt(input, String(signInInfo.firstTime)).toString();
};

const decrypt = (input: string, pass = false) => {
  if(getEnvironments().debug){
    return input;
  }
  if(pass){
    return input;
  }
  const signInInfo = getSignInInfo();
  assert(signInInfo);
  const decrypted = CryptoJS.AES.decrypt(input, String(signInInfo.firstTime));
  return decrypted.toString(CryptoJS.enc.Utf8);
};

////////////////////////////////////////////////////////////////////////////////

class SessionStorage {
  public static get<T>(key: string, passEncrypt = false): T | undefined{
    try{
      const item = sessionStorage.getItem(key);
      if(item === null || item === undefined){
        return;
      }
      const v = JSON.parse(decrypt(item, passEncrypt)) as {data: T};
      return v.data;
    }catch(e){
      return;
    }
  }
  public static set<T>(key: string, data: T | null | undefined, passEncrypt = false){
    if(data === null || data === undefined){
      sessionStorage.removeItem(key);
      return;
    }
    const saveData = JSON.stringify({data});
    sessionStorage.setItem(key, encrypt(saveData, passEncrypt));
  }
}

////////////////////////////////////////////////////////////////////////////////

class LocalStorage {
  public static get<T>(key: string, passEncrypt = false): T | undefined{
    try{
      const item = localStorage.getItem(key);
      if(item === null || item === undefined){
        return;
      }
      const v = JSON.parse(decrypt(item, passEncrypt)) as {data: T};
      return v.data;
    }catch(e){
      return;
    }
  }
  public static set<T>(key: string, data: T | null | undefined, passEncrypt = false){
    if(data === null || data === undefined){
      localStorage.removeItem(key);
      return;
    }
    const saveData = JSON.stringify({data});
    localStorage.setItem(key, encrypt(saveData, passEncrypt));
  }
}

////////////////////////////////////////////////////////////////////////////////

export { SessionStorage, LocalStorage };
