import { getContext } from './context';
import { isSameArray } from '../lib/isSameArray';

////////////////////////////////////////////////////////////////////////////////

interface MemoContext<T> {
  initialized?: boolean;
  checked?: unknown[];
  value?: T;
}

////////////////////////////////////////////////////////////////////////////////

function useMemo<T>(f: () => T, check?: unknown[]): T {
  const context = getContext<MemoContext<T>>({});

  if(!context.data.initialized){
    context.data.value = f();
    context.data.initialized = true;
    context.data.checked = check;
  }

  if(isSameArray(context.data.checked, check)){
    return context.data.value as T;
  }
  context.data.checked = check;

  context.data.value = f();
  return context.data.value;

}

function useMemoOnce<T>(f: () => T): T {
  return useMemo(f, []);
}

////////////////////////////////////////////////////////////////////////////////

export { useMemo, useMemoOnce };
