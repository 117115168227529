import { defaultTheme } from 'revu2/ui';

const CustomColors = {
  Primary : '#0278BD',
  PrimaryHover : '#00589B',
  //
  White : '#ffffff',
  Gray50 : '#FAFAFA',
  Gray100 : '#F5F5F5',
  Gray200 : '#DCDCDC',
  Gray400 : '#BDBDBD',
  Gray700 : '#616161',
  //
  Black21 : '#212121',
  Black33 : '#333333',
  Black55 : '#555555',
  Black75 : '#757575',
  BlackEE : '#EEEEEE',
};

const defTheme = {
  ...defaultTheme,

  primary: CustomColors.Primary,

  borderRadius: '0.5rem',
  shadow: 'drop-shadow(5px 5px 5px rgba(0,0,0,0.2))',

  backgroundColor: CustomColors.BlackEE,
  foregroundColor: CustomColors.White,
  text: CustomColors.Black21,

  login: {
    baseBk: CustomColors.BlackEE,
    title: CustomColors.Black21,
    copyright: CustomColors.Gray700,
    version: CustomColors.Gray400,
    bk: CustomColors.White,
  },

  header:{
    background: 'linear-gradient(90deg, #00589B 0%, #0277BD 100%)',
    text: CustomColors.White,
  },

  menu: {
    backgroundColor: CustomColors.White,
    selected: CustomColors.Primary,
    normal: CustomColors.Black75,
    footerText: CustomColors.Black75,
    hover: CustomColors.BlackEE,
  },

  tab: {
    backgroundColor: CustomColors.White,
    selected: CustomColors.Primary,
    normal: CustomColors.Black75,
    border: CustomColors.BlackEE,
  },

  tile: {
    backgroundColor: CustomColors.White,
    title: CustomColors.Black55,
    updatedAt: CustomColors.Black75,
    noImage: CustomColors.Gray400,
  },

  main: {
    backgroundColor: CustomColors.BlackEE,
    foregroundColor: CustomColors.White,
  },

  popup: {
    borderColor: CustomColors.BlackEE,
    backgroundColor: CustomColors.White,
    text: CustomColors.Black21,
    hoverBackgroundColor: CustomColors.Primary,
    hoverText: CustomColors.White,
  },

  dialog: {
    borderColor: CustomColors.BlackEE,
    backgroundColor: CustomColors.White,
    text: CustomColors.Black21,
    headerBackgroundColor: CustomColors.Black75,
    headerText: CustomColors.White,
  },

  formEdit: {
    partsColor: CustomColors.Black21,
    backgroundColor: CustomColors.BlackEE,
    foregroundColor: CustomColors.White,
    foregroundColorDark: CustomColors.Gray100,
    borderColor: CustomColors.Gray200,
    selectedColor: CustomColors.Primary,
  },

  fileTree: {
    backgroundColor: CustomColors.White,
    selectedBackgroundColor: '#0278BD11',
    normal: CustomColors.Black55,
    border: CustomColors.BlackEE,
  },

  detail: {
    countColor: CustomColors.White,
    countBk: CustomColors.Primary,
  },


};

type CustomTheme = typeof defTheme;

export { CustomTheme, defTheme as defaultTheme, CustomColors };
