import { assert } from '../lib/assert';
import { StyleProps, Ref } from '../types';
import { EventFunction } from '../lib/eventListener';
import { applyEvents } from './applyEvents';
import { CacheObject } from './renderToNode';
import { splitStyle, joinStyle, sameStyle } from '../lib/splitStyle';

const isSvg = (type: string) => {
  if(type !== 'svg'){
    return false;
  }
  return true;
};

////////////////////////////////////////////////////////////////////////////////

function applyProps(target: CacheObject): void{

  const revuNode = target.revuNode;
  assert(revuNode);
  const element = target.node as Element;
  const events: { [name:string] : EventFunction } = {};

  const applyNames: string[] = [];

  const isSvgType = target.parentIsSvg || ((typeof revuNode.type === 'string') && isSvg(revuNode.type));

  //////////////////////////////////////////////////////////////////////////////
  // fid

  if(!isSvgType){
    assert(revuNode.fcid);
    const fcid = `fc${revuNode.fcid}`;
    applyNames.push(fcid);
    const existFid = element.getAttribute(fcid);
    if(existFid === null){
      element.setAttribute(fcid, '');
    }
  }

  for(const name in revuNode.params){
    const v = revuNode.params[name];

    ////////////////////////////////////////////////////////////////////////////
    // events

    if(name.substring(0, 2) === 'on' && typeof v === 'function'){
      events[name.substring(2).toLowerCase()] = v as EventFunction;
      continue;
    }

    ////////////////////////////////////////////////////////////////////////////
    // ref

    if(name === 'ref'){
      let refs: Ref<Element>[];
      if(!Array.isArray(v)){
        refs = [v as Ref<Element>];
      }else{
        refs = v as Ref<Element>[];
      }
      for(const ref of refs){
        if(ref && ref.setState){
          ref.setState(element);
        }
      }
      continue;
    }

    ////////////////////////////////////////////////////////////////////////////
    // value for HTMLInputElement and HTMLTextAreaElement

    if(name === 'value'){
      applyNames.push('value');
      if(element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement){
        const newV = String(v || '');
        if(element.value !== newV){
          element.value = newV;
        }
      }
      continue;
    }

    ////////////////////////////////////////////////////////////////////////////
    // checked for HTMLInputElement (radio, checkbox)

    if(name === 'checked'){
      applyNames.push('checked');
      if(element instanceof HTMLInputElement){
        element.checked = v? true: false;
      }
    }

    if(name === 'indeterminate'){
      applyNames.push('indeterminate');
      if(element instanceof HTMLInputElement){
        element.indeterminate = v? true: false;
      }
    }

    ////////////////////////////////////////////////////////////////////////////
    // class

    if(name === 'class' || name === 'className'){
      if(v === undefined || v === null || (typeof v !== 'string' && !Array.isArray(v) && typeof v !== 'object')){
        continue;
      }
      applyNames.push('class');
      //
      let newValue = '';
      if(typeof v === 'string'){
        const vv = v.split(' ').filter((c) => c);
        newValue = vv.join(' ');
      }else if(Array.isArray(v)){
        newValue = v.filter((c) => c).join(' ');
      }else{
        const c: string[] = [];
        for(const key in v){
          if(v[key]){
            c.push(key);
          }
        }
        newValue = c.join(' ');
      }
      //
      const existValue = element.getAttribute('class');
      if(existValue !== newValue){
        element.setAttribute('class', newValue);
      }
      continue;
    }

    ////////////////////////////////////////////////////////////////////////////
    // for

    if(name === 'for' || name === 'htmlFor'){
      if(v === undefined || v === null || (typeof v !== 'string')){
        continue;
      }
      applyNames.push('for');
      //
      const newValue = String(v);
      const existValue = element.getAttribute('for');
      if(existValue !== newValue){
        element.setAttribute('for', newValue);
      }
      continue;
    }
    ////////////////////////////////////////////////////////////////////////////
    // style

    if(name === 'style'){
      if(v === undefined || v === null || Array.isArray(v) || (typeof v !== 'string' && typeof v !== 'object')){
        continue;
      }
      applyNames.push('style');
      //
      const existValue = element.getAttribute('style');
      const es = splitStyle(existValue || '');
      let ns: StyleProps = {};
      if(typeof v === 'string'){
        ns = splitStyle(v);
      }else{
        ns = v as StyleProps;
      }
      if(element.tagName === 'TEXTAREA'){
        // for browser auto apply style
        ns['width'] = es['width'] ?? ns['width'];
        ns['height'] = es['height'] ?? ns['height'];
      }
      if(!sameStyle(es, ns)){
        element.setAttribute('style', joinStyle(ns));
      }
      //
      continue;
    }

    ////////////////////////////////////////////////////////////////////////////
    // other attributes

    if(v === false || v === null || v === undefined){
      continue;
    }

    const tgtName = isSvgType ? name : name.toLowerCase();
    applyNames.push(tgtName);
    //
    let newValue: string;
    if(v === true){
      newValue = '';
    }else{
      newValue = String(v);
    }
    //
    const existValue = element.getAttribute(tgtName);
    if(newValue !== undefined && existValue !== newValue){
      element.setAttribute(tgtName, newValue);
    }

  }

  //////////////////////////////////////////////////////////////////////////////
  // remove

  const existNames = element.getAttributeNames();
  const removeAttrs = existNames.filter(n => !applyNames.includes(n));
  for(const rm of removeAttrs){
    if(element.tagName === 'TEXTAREA' && rm === 'style'){
      // for browser auto apply style
      continue;
    }
    element.removeAttribute(rm);
  }

  //////////////////////////////////////////////////////////////////////////////
  // events

  applyEvents(target, events);

}

////////////////////////////////////////////////////////////////////////////////

export { applyProps, isSvg };