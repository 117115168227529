/**
 * deep copy function core
 * @internal
 *
 * @param src コピー対象オブジェクト
 * @param p 再帰チェック用オブジェクト
 * @return 複製オブジェクト
 */
function deepCopyCore(src: unknown, noDeepKeys: string[], p?: unknown[]): unknown {
  //
  const parents = p ?? [];
  if (parents.indexOf(src) >= 0) {
    return src;
  }

  //
  switch (typeof src) {
    case 'function': {
      return src;
    }

    case 'object': {

      // null
      if (src === null) {
        return null;
      }

      // array
      if (Array.isArray(src)) {
        parents.push(src);
        //
        const dst: unknown[] = [];
        for (const s of src) {
          const result = deepCopyCore(s, noDeepKeys, parents);
          dst.push(result);
        }
        //
        parents.pop();
        return dst;
      }

      // date
      if(src instanceof Date){
        return new Date(src);
      }

      // promise
      if(src instanceof Promise){
        return src;
      }

      if(src instanceof Map){
        return src;
      }
      if(src instanceof Set){
        return src;
      }

      // class
      if (src.constructor.name !== 'Object') {
        return src;
      }

      // object
      parents.push(src);
      //
      const dst: object = {};
      const srcKeys = Object.keys(src);
      for (const srcKey of srcKeys) {
        if(noDeepKeys.indexOf(srcKey) >= 0){
          dst[srcKey] = src[srcKey] as unknown;
        }else{
          const result = deepCopyCore(src[srcKey], noDeepKeys, parents);
          dst[srcKey] = result;
        }
      }
      //
      parents.pop();
      return dst;
      //
    }
    default:
      return src;
  }
}

function deepCopy<T>(src: T, noDeepKeys?: string[]): T {
  return deepCopyCore(src as unknown, noDeepKeys ?? []) as T;
}

export { deepCopy };
