import { StyleProps } from '../types';
import { camel2Kebabu } from '../lib/changeCase';

////////////////////////////////////////////////////////////////////////////////

const splitStyle = (s: string): StyleProps => {
  //
  if(!s){
    return {};
  }
  //
  const result: {[key:string]:string} = {};
  //
  const ll = s.split(';');
  for(const l of ll){
    const lr = l.trim().split(':');
    if(lr.length === 2){
      result[lr[0].trim()] = lr[1].trim();
    }
  }
  //
  return result;
  //
};

const joinStyle = (s: StyleProps): string => {
  const result: string[] = [];
  for(const key in s){
    const v = s[key];
    if(v !== undefined && v !== null){
      result.push(`${camel2Kebabu(key)}:${v}`);
    }
  }
  if(result.length > 0){
    return `${result.join(';')};`;
  }
  /* istanbul ignore next */
  return '';
};

const sameStyle = (a: StyleProps, b: StyleProps): boolean => {
  //
  const bb: StyleProps = {};
  for(const bi in b){
    if(b[bi] !== undefined && b[bi] !== null){
      bb[bi] = b[bi];
    }
  }
  //
  const al = Object.keys(a);
  const bl = Object.keys(bb);
  if(al.length !== bl.length){
    return false;
  }
  //
  for(const key of bl){
    if(a[camel2Kebabu(key)] !== bb[key]){
      return false;
    }
  }
  //
  return true;
  //
};

export { splitStyle, joinStyle, sameStyle };