import { assert } from '../lib/assert';
import { useRouterStack } from './useRouterStack';

const Routes = ({children}: {children: JSX.Children}) => {
  //
  const routeContext = useRouterStack();
  const parentContext = routeContext.parent;
  assert(parentContext);
  //
  routeContext.base = parentContext.base;
  routeContext.renderCount = 0;
  routeContext.switchMode = true;
  //
  return <>{children}</>;
  //
};

export { Routes };