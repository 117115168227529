import { CSS } from '../../../lib/styleToString';
import { Theme } from '../defaultTheme';
import { pureInput } from './inputElement';

const labelElementCss = (theme: Theme): CSS => {
  return {
    ////////////////////////////////////////////////////////////////////////////

    'label' : {
      color: theme.label.labelColor,
      '&.alert':{
        color: theme.label.alertColor,
      },
      [`+${pureInput}, div[class="revu-select"]`]:{
        margin: '0.4rem 0',
      },
      '&.inline':{
        display: 'inline-block',
        marginTop: '1rem',
        verticalAlign: 'top',
      },
    },
    'label.disabled':{
      color: theme.label.disabledColor,
    },
    'input:disabled+label':{
      color: theme.label.disabledColor,
    },

    ////////////////////////////////////////////////////////////////////////////
  };
};

export { labelElementCss };