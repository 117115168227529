import { useEffect } from 'revu2';
import { v4 } from 'uuid';
import { useLocation, useNavigate } from 'revu2/router';
import { getEnvironments } from '@/../config/env';
import { SessionStorage } from '@/lib/Storage';
import { setUpdatedToken } from '@/context/SignInInfo';
import { ROUTE_PATH } from '../routePath';
import { TokenResponse, setToken, getToken, revokeToken } from '@/context/token';
import { fetchWithToken } from '@/lib/fetchWithToken';
//
import { setUser, isAppUser } from '@/context/user';
import { AppUser, AppUserKind } from '@/graphql/types';
import * as Sentry from '@sentry/browser';

////////////////////////////////////////////////////////////////////////////////

const setSingInState = (state: string | undefined) => {
  SessionStorage.set<string>('state', state);
};

const getSingInState = () => {
  return SessionStorage.get<string>('state');
};

////////////////////////////////////////////////////////////////////////////////

const signIn = (username?: string | null) => {
  const signInState = v4().split('-').join('');
  setSingInState(signInState);
  location.href = `${getEnvironments().signinEndPoint}/${signInState}${username?`?username=${encodeURIComponent(username)}`:''}`;
};

////////////////////////////////////////////////////////////////////////////////

const signOut = () => {
  window.requestAnimationFrame(() => {
    void (async () => {
      await revokeToken();
      //
      setToken(undefined);
      setUser(undefined);
      location.href = ROUTE_PATH.SIGNIN();
    })();
  });
};

////////////////////////////////////////////////////////////////////////////////

const SignInComponent = () => {
  //
  const location = useLocation();
  const username = location.searchParams.get('username');
  //
  useEffect(() => {
    signIn(username);
  }, [username]);
  //
  return <></>;
  //
};

////////////////////////////////////////////////////////////////////////////////

const fetchUserInfo = async (access_token?: string) => {
  //
  const token = access_token ?? getToken()?.access_token;
  if(!token){
    setToken(undefined);
    setUser(undefined);
    return;
  }
  //
  const userInfoEndPoint = `${getEnvironments().userInfoEndPoint}`;
  const userInfo = await fetchWithToken<AppUser>(userInfoEndPoint, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  }, isAppUser);
  setUser(userInfo);
  return userInfo;
  //
};

////////////////////////////////////////////////////////////////////////////////

const SignInCallback = () => {

  const navigate = useNavigate();

  useEffect(() => {
    //
    void (async () => {
      //
      const signinState = getSingInState();
      if(!signinState){
        return;
      }
      setSingInState(undefined);
      //
      try {
        const getTokenEndPoint = `${getEnvironments().getTokenEndPoint}/${signinState}`;
        const getTokenResult = await fetch(getTokenEndPoint, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
          },
        });
        const token = (await getTokenResult.json()) as TokenResponse;
        if(!token){
          location.href = `${getEnvironments().signinEndPoint}/${signinState}`;
          return;
        }
        setToken(token);
        //
        const userInfo = await fetchUserInfo();
        if(!userInfo){
          location.href = `${getEnvironments().signinEndPoint}/${signinState}`;
          return;
        }

        if(getEnvironments().sentryDsn){
          Sentry.setContext('user', {user_id: userInfo.user_id, email: userInfo.email, sub: userInfo.sub, name: userInfo.name});
        }

        setUpdatedToken();
        setToken(token);

        if (userInfo.userAgreedTermVersion !== getEnvironments().termsVersion) {
          navigate(ROUTE_PATH.TERMSAGREEMENT())
        } else if(userInfo.kind === AppUserKind.BIMSTOK_ADMIN){
          navigate(ROUTE_PATH.SYSTEMADMIN());
        } else{
          navigate(ROUTE_PATH.WORKSPACE());
        }

      }catch(e){
        location.href = `${getEnvironments().signinEndPoint}/${signinState}`;
      }

    })();
    //
  }, [navigate]);

  return <></>;
  //
};

////////////////////////////////////////////////////////////////////////////////

export { signIn, SignInComponent, SignInCallback, signOut, fetchUserInfo };
