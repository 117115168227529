import { CSS } from '../../../lib/styleToString';
import { Theme } from '../defaultTheme';

const buttonElementCss = (theme: Theme): CSS => {
  return {

    'button' : {
      outline: '1',
      padding: '0.5rem 1.0rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      fontWeight: 'bold',
      border: `1px solid ${theme.inputButton.borderColor}`,
      color: theme.inputButton.color,
      backgroundColor: theme.inputButton.bkColor,
      '&:hover':{
        borderColor: theme.inputButton.hover,
        color: theme.inputButton.hover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      },
    },

    'button[type="submit"]': {
      border: `1px solid ${theme.inputButton.primaryBorderColor}`,
      color: theme.inputButton.primaryColor,
      backgroundColor: theme.inputButton.primaryBkColor,
      '&:hover':{
        backgroundColor: theme.inputButton.primaryHover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

  };
};

export { buttonElementCss };
