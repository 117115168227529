import { assert, margeProps, ClassProps, useCss, useState, useLayoutEffect, useRef, StyleProps, useCallback, useMemo } from '../..';

////////////////////////////////////////////////////////////////////////////////

const DEFAULT_RESIZE_BAR_SIZE = 8;
const DEFAULT_RESIZE_BAR_COLOR = '#00000000';
const DEFAULT_RESIZE_BAR_COLOR_HOVER = '#FFFFFF44';

////////////////////////////////////////////////////////////////////////////////

const VSplitContainer = ({children}: {children: JSX.Children}) => {
  useCss({
    '.v-split-container':{
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    }
  });
  return <div className="v-split-container">{children}</div>;
};

////////////////////////////////////////////////////////////////////////////////

type VResizeBarInfo = {
  position: 'top' | 'bottom',
  height?: number,
  color?: string,
  hoverColor?: string
};

type VSplitParams = {
  height?: number,
  minHeight?: number,
  resizeBar?: VResizeBarInfo,
  children: JSX.Children
  //
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
};

////////////////////////////////////////////////////////////////////////////////

const VSplit = ({ height, minHeight, resizeBar, children, ...inherited }: VSplitParams) => {

  //////////////////////////////////////////////////////////////////////////////

  useCss({
    '.v-split' : {
      zIndex: '0',
      position: 'relative',
      width: '100%',
    },
    '.bar' : {
      zIndex: '1',
      position: 'absolute',
      width: '100%',
      '&.top' : {
        top: '0px',
      },
      '&.bottom' : {
        bottom: '0px',
      },
      '&:hover': {
        cursor: 'ns-resize',
      }
    },
  });

  //////////////////////////////////////////////////////////////////////////////

  const [vHeight, setVHeight] = useState<number | undefined>(height);

  useLayoutEffect(() => {
    if(height){
      setVHeight(height);
    }
  }, [height]);

  //////////////////////////////////////////////////////////////////////////////

  const resizeInfo = useRef({active: false, offset: 0, height: 0});
  const div = useRef<HTMLDivElement>();

  const onPointerDown = useCallback((e: PointerEvent) => {
    if(resizeBar && vHeight !== undefined){
      resizeInfo.current.active = true;
      resizeInfo.current.height = vHeight;
      resizeInfo.current.offset = e.screenY;
      (e.target as HTMLElement).setPointerCapture(e.pointerId);
    }
  }, [resizeBar, vHeight]);

  const onPointerMove = useCallback((e: PointerEvent) => {
    if(!resizeInfo.current.active){
      return;
    }
    //
    const offset = e.screenY - resizeInfo.current.offset;
    const newHeight = resizeInfo.current.height + ((resizeBar?.position === 'bottom')? offset : -offset );
    assert(height);
    if(newHeight > (minHeight ?? height)){
      setVHeight(newHeight);
    }
    //
  }, [height, minHeight, resizeBar?.position]);

  const onPointerUp = useCallback((e: PointerEvent) => {
    if(!resizeInfo.current.active){
      return;
    }
    //
    const rect = div.current.getBoundingClientRect();
    setVHeight(rect.height);
    //
    resizeInfo.current.active = false;
    (e.target as HTMLElement).releasePointerCapture(e.pointerId);
    //
  }, []);

  //////////////////////////////////////////////////////////////////////////////

  const barColor = resizeBar?.color ?? DEFAULT_RESIZE_BAR_COLOR;
  const [backgroundColor, setBackgroundColor] = useState<string>(barColor);

  const onMouseEnter = useCallback((_e: PointerEvent) => {
    const barHoverColor = resizeBar?.hoverColor ?? DEFAULT_RESIZE_BAR_COLOR_HOVER;
    setBackgroundColor(barHoverColor);
  }, [resizeBar?.hoverColor]);

  const onMouseLeave = useCallback((_e: PointerEvent) => {
    setBackgroundColor(barColor);
  }, [barColor]);

  //////////////////////////////////////////////////////////////////////////////

  const splitStyle = useMemo(() => {
    return (vHeight !== undefined) ?
      {flexBasis: `${Math.floor(vHeight)}px`}
      :
      {flexGrow: '1', flexShrink: '1'};
  }, [vHeight]);

  const splitChildStyle = useMemo(() => {
    return (vHeight !== undefined)?
      {height: `${Math.floor(vHeight)}px`, width: '100%'}
      :
      {height: '100%', width: '100%'};
  }, [vHeight]);

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {style: splitChildStyle});
  }, [inherited, splitChildStyle]);

  return (
    <div
      className="v-split"
      style={splitStyle}
      ref={div}
    >
      <div {...inheritedProps}>
        {children}
        {
          (resizeBar && height !== undefined)?
            <div
              className={`bar ${resizeBar.position}`}
              style={{
                height: `${resizeBar?.height ?? DEFAULT_RESIZE_BAR_SIZE}px`,
                backgroundColor
              }}
              onPointerDown={onPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={onPointerUp}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            ></div>
            :
            <></>
        }
      </div>
    </div>
  );

};

export { VSplitContainer, VSplit };