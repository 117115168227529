import { margeProps, ClassProps, StyleProps, useCss, useMemo } from '../../';

type AlignKind = {
  horizontal?: 'left' | 'center' | 'right',
  vertical?: 'start' | 'center' | 'end',
  children: JSX.Children,
  //
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
};

const Align = ({horizontal, vertical, children, ...inherited}: AlignKind) => {

  useCss({
    '.align' : {
      width: '100%',
      height: '100%',
      display: 'flex',
      '.align-inner':{
        display: 'block',
      }
    }
  });

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {class: 'align', style: {
      justifyContent: horizontal ?? 'center',
      alignItems: vertical ?? 'center'
    }});
  }, [horizontal, inherited, vertical]);

  return <div {...inheritedProps}>
    <div className="align-inner">
      {children}
    </div>
  </div>;

};

export { Align, AlignKind };