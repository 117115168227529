import { useTheme } from 'revu2/ui';
import { CustomTheme, defaultTheme, CustomColors } from './CustomTheme';

const themes: {[key:string]: CustomTheme} = {
  'default': defaultTheme,
};

const useCustomTheme = (t?: {[key: string]: CustomTheme}) => {
  return useTheme<CustomTheme>(t);
};

export { useCustomTheme, CustomTheme, themes, CustomColors };
