/**
 * deep equal check function core
 * @internal
 *
 * @param a 検査オブジェクト
 * @param b 検査オブジェクト
 * @param p 再帰チェック用オブジェクト
 */
function deepEqualCore(a: unknown, b: unknown, ignoreFunction: boolean, p?: unknown[]): boolean {
  //
  const parents = p ?? [];
  //
  if (parents.indexOf(a) >= 0) {
    return true;
  }

  //
  const typeA = typeof a;
  const typeB = typeof b;
  if (typeA !== typeB) {
    return false;
  }

  switch (typeA) {

    case 'function': {
      if (ignoreFunction) {
        return true;
      }
      return a === b;
    }

    case 'number': {
      if (Number.isNaN(a)) {
        return Number.isNaN(b);
      }
      return a === b;
    }

    case 'object': {

      // array
      if (Array.isArray(a)) {
        //
        const aArray = a as unknown[];
        const bArray = b as unknown[];
        if (aArray.length !== bArray.length) {
          return false;
        }
        //
        parents.push(a);
        for(let i = 0; i < aArray.length; i++){
          if (!deepEqualCore(aArray[i], bArray[i], ignoreFunction, parents)) {
            return false;
          }
        }
        parents.pop();
        return true;
      }

      // date
      if(a instanceof Date){
        const aDate = a ;
        const bDate = b as Date;
        return aDate.getTime() === bDate.getTime();
      }

      // class
      const classA = (a as object)?.constructor?.name;
      const classB = (b as object)?.constructor?.name;
      // class
      if (classA !== 'Object' || classB !== 'Object') {
        return a === b;
      }

      const aKeys = Object.keys(a as object);
      const bKeys = Object.keys(b as object);
      if (aKeys.length !== bKeys.length) {
        return false;
      }
      parents.push(a);
      for (const aKey of aKeys) {
        if (!deepEqualCore((a as {[key:string]:unknown})[aKey], (b as {[key:string]:unknown})[aKey], ignoreFunction, parents)) {
          return false;
        }
      }
      parents.pop();
      return true;
    }

    default:
      return a === b;
  }

}

function deepEqual(a: unknown, b: unknown, ignoreFunction?: boolean): boolean {
  return deepEqualCore(a, b, ignoreFunction ?? false);
}

export { deepEqual };
