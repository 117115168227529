import { Path, resolvePath } from './resolvePath';
import { useHistory } from './useHistory';
import { useExistsRouterStack } from './useRouterStack';

////////////////////////////////////////////////////////////////////////////////

interface RedirectProps {
  to: Path;
  push?: boolean;
  replace?: boolean;
}

////////////////////////////////////////////////////////////////////////////////

const Navigate = (props: RedirectProps) => {
  const stackContext = useExistsRouterStack();

  const targetPath = resolvePath(props.to, stackContext.base);
  const history = useHistory();

  if(targetPath.fullpath.substring(0, 7) === 'http://' || targetPath.fullpath.substring(0, 8) === 'https://'){
    location.href = targetPath.fullpath;
    return <></>;
  }

  if(!props.replace){
    history.push(targetPath);
  }else{
    history.replace(targetPath);
  }

  return <></>;

};

export { Navigate };
