import { SessionStorage } from '@/lib/Storage';

const SIGN_IN_REDIRECT = 'redirect';

const setRedirect = (user: string | undefined) => {
  SessionStorage.set<string>(SIGN_IN_REDIRECT, user, true);
};

const getRedirect = (): string | undefined => {
  const result = SessionStorage.get<string>(SIGN_IN_REDIRECT, true);
  setRedirect(undefined);
  return result;
};

export { setRedirect, getRedirect };