import { assert } from '../lib/assert';
import { addListener } from '../lib/eventListener';
import { DebugRouter } from './debug';
import { Path, resolvePath } from './resolvePath';
import { UseLocation, LocationInstance, getLocationInstance } from './useLocation';
import buildPath from './buildPath';

////////////////////////////////////////////////////////////////////////////////

enum Action {
  Push,
  Replace,
  Pop,
}

interface Transition {
  action: Action;
  location: UseLocation;
  retry: () => void;
}

interface Blocker {
  (tx: Transition): void;
}

////////////////////////////////////////////////////////////////////////////////

interface HistoryInstance {
  push: (path: Path, state?: object) => void;
  replace: (path: Path, state?: object) => void;
  go: (n: number, state?: object) => void;
  goBack: (state?: object) => void;
  goForward: (state?: object) => void;
  block: (blocker: Blocker) => void;
  //
  navi: (action: Action, path: Path, state?: object) => void;
  //
  location: UseLocation;
  prevLocation: UseLocation;
  //
  blocker?: Blocker;
  //
  forceUpdate?: () => void;
  purge?: () => void;
  //
}

////////////////////////////////////////////////////////////////////////////////

function createHistory(revuLocation: LocationInstance): HistoryInstance{
  //
  revuLocation.initLocation(location.href);
  //
  const revHistory: HistoryInstance = {
    push: (path: Path, state?: object) => {
      revHistory.navi(Action.Push, path, state);
    },
    replace: (path:Path, state?: object) => {
      revHistory.navi(Action.Replace, path, state);
    },
    //
    go: (n: number) => history.go(n),
    goBack: () => {
      revHistory.go(-1);
    },
    goForward: () => revHistory.go(+1),
    //
    block: (blocker: Blocker) => {
      revHistory.blocker = blocker;
    },
    //
    navi: (action: Action, path: Path, state?: object) => {
      //
      const doNavi = () => {
        //
        if(typeof path === 'string'){
          /* istanbul ignore next */
          if(path.indexOf('http://') === 0 || path.indexOf('https://') === 0){
            location.href = path;
            return;
          }
        }
        //
        const loc = new URL(location.href);
        const rp = resolvePath(path, loc.pathname);
        //
        const pathString = rp.fullpath;
        const stateParam = state ?? rp.state;
        //
        if(revuLocation.setLocation(pathString, stateParam)){
          //
          if(revHistory.location.href !== revHistory.prevLocation.href || revHistory.location.state !== revHistory.prevLocation.state){
            revHistory.prevLocation = {...revHistory.location};
            //assert(revHistory.forceUpdate);
            revHistory.forceUpdate?.();
          }
          //
          if(action === Action.Push){
            /* istanbul ignore next */
            if(DebugRouter()){
              console.log('%cNavigate Push%c', 'background-color: blue;color: white;', '', pathString);
            }
            history.pushState(stateParam, document.title, pathString);
          }else if(action === Action.Replace){
            /* istanbul ignore next */
            if(DebugRouter()){
              console.log('%cNavigate Replace%c', 'background-color: magenta;color: white;', '', pathString);
            }
            history.replaceState(stateParam, document.title, pathString);
          }
        }else/* istanbul ignore next */{
          console.warn('[ReVu Router] Can\'t navigation to', pathString);
        }
        //
      };
      //
      if(revHistory.blocker){
        revHistory.blocker({
          action,
          location: revHistory.location,
          retry: () => {
            doNavi();
            revHistory.blocker = undefined;
          }
        });
        return;
      }
      //
      doNavi();
      //
    },
    //
    location: revuLocation.location,
    prevLocation: {...revuLocation.location},
    //
  };

  const l = addListener(window, 'popstate', () => {
    const path = buildPath(location.pathname, location.search, location.hash);
    revHistory.navi(Action.Pop, path, undefined);
  });
  //
  const path = buildPath(location.pathname, location.search, location.hash);
  revHistory.navi(Action.Replace, path, undefined);
  //
  revHistory.purge = () => {
    l.remove();
  };
  //
  return revHistory;
  //
}

////////////////////////////////////////////////////////////////////////////////

let historyInstance: HistoryInstance = createHistory(getLocationInstance());

function getHistoryInstance(){
  return historyInstance;
}

function resetHistoryInstance(){
  historyInstance?.purge?.();
  historyInstance = createHistory(getLocationInstance());
}

function useHistory(): HistoryInstance {
  assert(historyInstance);
  return historyInstance;
}

////////////////////////////////////////////////////////////////////////////////

export { createHistory, HistoryInstance, useHistory, getHistoryInstance, resetHistoryInstance };
