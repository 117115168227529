import { getContext } from './context';
import { isSameArray } from '../lib/isSameArray';

////////////////////////////////////////////////////////////////////////////////

type LayoutEffectCloser = () => void;

interface LayoutEffectContext {
  checked?: unknown[];
  purgeFunc?: LayoutEffectCloser;
}

////////////////////////////////////////////////////////////////////////////////

const useLayoutEffect = (f: () => LayoutEffectCloser | void, check?: unknown[]): void => {
  const context = getContext<LayoutEffectContext>({});

  if(isSameArray(context.data.checked, check)){
    return;
  }

  context.data.checked = check;

  const layoutEffect = () => {
    if(context.data.purgeFunc){
      context.data.purgeFunc();
      context.purgeFuncs = context.purgeFuncs.filter((p) => p !== context.data.purgeFunc);
      context.data.purgeFunc = undefined;
    }
    const purge = f();
    if(purge && typeof purge === 'function'){
      context.data.purgeFunc = purge;
      context.purgeFuncs.push(purge);
    }
  };
  context.layoutEffects.push(layoutEffect);

};

////////////////////////////////////////////////////////////////////////////////

export { useLayoutEffect };