import { useCss, useGlobalCss } from '../../';
import { useTheme } from './theme/useTheme';
import { PopUpLayer } from './PopUp';
import { SelectLayer } from './Select';
import { DialogLayer } from './Dialog';

const ReVuUI = ({children}: {children: JSX.Children}) => {
  //
  const Theme = useTheme();

  useGlobalCss(Theme.globalCss, [Theme.theme]);
  useCss({
    '.revu-ui': {
      width: '100%',
      height: '100%',
    }
  }, []);
  //
  return (
    <Theme.Provider>
      <div className="revu-ui">
        {children}
      </div>
      <DialogLayer.Provider/>
      <SelectLayer.Provider/>
      <PopUpLayer.Provider/>
    </Theme.Provider>
  );
};

export { ReVuUI };
