interface SignInInfo {
  firstTime: number;
  updated?: number;
}

const SIGN_IN_INFO = 'sign_in_info';

const getSignInInfo = (): SignInInfo => {
  //
  let signInInfo: SignInInfo;
  const signInInfoString = localStorage.getItem(SIGN_IN_INFO);
  if(!signInInfoString){
    signInInfo = {firstTime: (new Date()).getTime()};
    localStorage.setItem(SIGN_IN_INFO, JSON.stringify(signInInfo));
  }else{
    signInInfo = JSON.parse(signInInfoString) as SignInInfo;
  }
  return signInInfo;
  //
};

const setUpdatedToken = () => {
  const signInInfo = getSignInInfo();
  signInInfo.updated = (new Date()).getTime();
  localStorage.setItem(SIGN_IN_INFO, JSON.stringify(signInInfo));
};

const clearSignInInfo = () => {
  localStorage.removeItem(SIGN_IN_INFO);
};

export { SignInInfo, getSignInInfo, setUpdatedToken, clearSignInInfo };
