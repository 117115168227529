import { Ref, StyleProps, ClassProps } from '../types';
import { margeProps } from '../lib/margeProps';
import { jsxs } from '../jsx/jsx-runtime';
import { Path, resolvePath } from './resolvePath';
import { useHistory } from './useHistory';
import { useMatch } from './useMatch';
import { useMemo } from '../hooks/useMemo';
import { useExistsRouterStack } from './useRouterStack';

////////////////////////////////////////////////////////////////////////////////

interface LinkProps {
  to: Path;
  //
  tag?: string;
  event?: string;
  replace?: boolean;
  //
  onClick?: (e:Event) => boolean | undefined | void;
  //
  children: JSX.Children;
  //
  exact?: boolean;
  //
  className?: ClassProps | ((isActive?: boolean) => ClassProps);
  style?: StyleProps | ((isActive?: boolean) => StyleProps),
  //
  // inherited...
  //
  class?: ClassProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any>;
  //
}

////////////////////////////////////////////////////////////////////////////////

const Link = ({
  to,
  //
  tag,
  event,
  replace,
  //
  onClick,
  //
  children,
  //
  exact,
  //
  className,
  style,
  //
  ...inherited
  //
}: LinkProps) => {

  const stackContext = useExistsRouterStack();
  const rp = resolvePath(to, stackContext.base);

  const history = useHistory();
  const match = useMatch(rp.pathname);

  const exactMatch = (exact)? (match?.isExact ?? false) : (match? true : false);

  const memo = useMemo<{
    exactMatch: boolean | undefined,
    className: ClassProps | undefined,
    style: StyleProps | undefined,
  }>(() => {return {
    exactMatch: undefined,
    className: undefined,
    style: undefined,
  };}, []);

  if(memo.exactMatch !== exactMatch){
    memo.exactMatch = exactMatch;
    memo.className = (typeof className === 'function')?className?.(exactMatch) : className;
    memo.style = (typeof style === 'function')?style?.(exactMatch) : style;
  }

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {
      [`on${event ?? 'click'}`]: (e:Event) => {
        //
        if(onClick){
          if(onClick(e) === false){
            return;
          }
        }
        //
        e.stopPropagation();
        e.preventDefault();
        //
        if(replace){
          history.replace(rp);
        }else{
          history.push(rp);
        }
      },
      href: (tag === 'a')? rp.fullpath :undefined,
      class: memo.className,
      style: memo.style,
    });
  }, [event, history, inherited, memo.className, memo.style, onClick, replace, rp, tag]);

  return jsxs(tag ?? 'a', {...inheritedProps, children});

};

export { Link };