import { useMemo, jsx, Fragment, ReVuNode } from '../';

const InnerSvg = ({html, fill}:{html: string, fill?: string}) => {

  const vDom = useMemo(() => {
    //
    const d = document.createElement('div');
    d.innerHTML = html;
    //
    const parseDom = (parent: Node): ReVuNode | undefined => {
      //
      const childrenDom: ReVuNode[] = [];
      const children = parent.childNodes;
      for(let i = 0; i < children.length; i++){
        const c = parseDom(children[i]);
        if(c){
          childrenDom.push(c);
        }
      }
      //
      if(parent.nodeType === Node.ELEMENT_NODE){
        const element = parent as Element;
        const attrs = element.getAttributeNames();
        const params: {[key:string]: unknown} = {children: childrenDom};
        for(const attr of attrs){
          params[attr] = element.getAttribute(attr);
        }
        if(element.tagName !== 'svg' && fill){
          params['fill'] = params['fill'] || fill;
        }
        return jsx(element.tagName, params);
      }else if(parent.nodeType === Node.TEXT_NODE){
        return {type: '=', text: parent.textContent, params: {}};
      }
      //
    };

    const childrenDom: ReVuNode[] = [];
    const children = d.childNodes;
    for(let i = 0; i < children.length; i++){
      const c = parseDom(children[i]);
      if(c){
        childrenDom.push(c);
      }
    }

    return Fragment({
      children: [childrenDom],
    });
    //
  }, [fill, html]);

  return <>{vDom}</>;

};

let seq = 0;

const Svg = ({src, color, width, height}: {src: string, color?: string, width?: number, height?: number}) => {

  const svgClass = useMemo(() => `revu-svg-${(seq++).toString(36)}`, []);
  const divStyle = useMemo(() => {
    return {
      display: 'inline-block',
      width: `${width?`${width}px`:'auto'}`,
      height: `${height?`${height}px`:'auto'}`,
    };
  }, [height, width]);

  return (
    <div
      className={svgClass}
      style={divStyle}
    >
      <InnerSvg html={src} fill={color}/>
    </div>
  );

  return <></>;
};

export { Svg };
