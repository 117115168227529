import { v4 } from 'uuid';
import {
  assert,
  useCss,
  useGlobalCss,
  useEffect,
  useCallback,
  useState,
  useMemo, useMemoOnce,
  useLayoutEffect,
  addListener,
  useRef,
  shortid,
  margeProps,
  ClassProps,
  StyleProps,
  deepCopy,
  useEffectOnce,
} from '../..';
import { useTheme } from './theme/useTheme';
import { useLayer, createLayer } from './useLayer';

////////////////////////////////////////////////////////////////////////////////

const SelectLayer = createLayer(300000);

////////////////////////////////////////////////////////////////////////////////

type SelectOption = {
  value?: string,
  label: string,
  htmlLabel?: JSX.Children,
  key?: string | number} & {[key:string]: unknown,
  removed?: boolean,
}
type SelectOptions = SelectOption[];

////////////////////////////////////////////////////////////////////////////////

const isChild = (check: Node | null, parent: Node): boolean => {
  while(check){
    if (check === parent){
      return true;
    }
    check = check.parentNode;
  }
  return false;
};

////////////////////////////////////////////////////////////////////////////////

interface SelectParams {
  options: SelectOptions,
  maxHeight?: number,
  onChangeSelect?: (e: Event) => void,
  value?: string
  //
  readonly?: boolean,
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
}

////////////////////////////////////////////////////////////////////////////////

const Select = ({options, value, maxHeight, onChangeSelect, readonly, ...inherited}: SelectParams) => {

  const {theme} = useTheme();
  useCss({
    '.revu-select':{
      position: 'relative',
      '&::before':{
        content: '""',
        zIndex: '1',
        position: 'absolute',
        cursor: 'pointer',
        width: '2rem',
        height: '2rem',
        right: '0',
        top: '0',
        bottom: '0',
      },
      '&::after':{
        content: '""',
        zIndex: '1',
        position: 'absolute',
        cursor: 'pointer',
        width: '0.6rem',
        height: '0.6rem',
        right: '0.7rem',
        top: '0.75rem',
        bottom: '0',
        //
        transform: 'rotate(-45deg)',
        borderBottom: '3px solid',
        borderLeft: '3px solid',
        borderColor: theme.input.placeholderColor,
        //
      },
      '> input':{
        paddingRight: '34px !important',
      }
    },
    '.revu-select.disabled':{
      '&::before, &::after':{
        display: 'none',
      }
    }
  }, [theme]);
  useGlobalCss({
    '.revu-select-option-items':{
      zIndex: '10000',
      //
      position: 'absolute',
      marginTop: '0.5rem',
      padding: '0.4rem 0',
      //
      border: `1px solid ${theme.option.optionBorderColor}`,
      backgroundColor: theme.option.optionBkColor,
      borderRadius: '0.4rem',
    },
    '.revu-select-option-item':{
      height: '2rem',
      display: 'flex',
      alignItems: 'center',
      //
      cursor: 'pointer',
      //
      color: theme.option.optionColor,
      backgroundColor: theme.option.optionBkColor,
      paddingLeft: '0.4rem',
      //
      '&:hover':{
        backgroundColor: theme.option.optionHoverBkColor
      },
      //
      '&.selected':{
        backgroundColor: theme.option.optionHoverBkColor
      }
      //
    },

  }, [theme]);

  //////////////////////////////////////////////////////////////////////////////

  const layerRender = useLayer(SelectLayer);
  const uniqueId = useMemo(() => shortid(6), []);
  const [show, setShowCore] = useState<boolean>();

  const setShow = useCallback((show: boolean) => {
    if(readonly){
      setShowCore(false);
      return;
    }
    setShowCore(show);
  }, [readonly]);

  const state = useMemo<{show?: boolean}>(() => {return {};}, []);

  //////////////////////////////////////////////////////////////////////////////
  // options

  const selectOptions = useMemoOnce(() => {
    const result: SelectOptions = [];
    for(const r of deepCopy(options)){
      if(!r.removed){
        result.push(r);
      }
    }
    result.map((o) => {o.key = o.key ?? shortid(6); return o;});
    return result;
  });

  const selectOptionsAll = useMemoOnce(() => {
    const result: SelectOptions = [];
    for(const r of deepCopy(options)){
      result.push(r);
    }
    result.map((o) => {o.key = o.key ?? shortid(6); return o;});
    return result;
  });

  //////////////////////////////////////////////////////////////////////////////

  const findByValue = useCallback((s?: string) => {
    if(selectOptions.length === 0){
      return;
    }
    for(let i = 0; i < selectOptions.length; i++){
      const o = selectOptions[i];
      if(o.value === s){
        return selectOptions[i];
      }
    }
    return;
  }, [selectOptions]);

  //////////////////////////////////////////////////////////////////////////////

  const findByLabel = useCallback((s?: string) => {
    if(selectOptions.length === 0){
      return;
    }
    for(let i = 0; i < selectOptions.length; i++){
      const o = selectOptions[i];
      if(o.label === s){
        return selectOptions[i];
      }
    }
    return;
  }, [selectOptions]);

  //////////////////////////////////////////////////////////////////////////////

  const lastSelect = useMemo<{lastSelect?: string}>(() => {return {};}, []);

  const onChangeSelectEvent = useCallback((selectedValue?: string) => {
    if(lastSelect.lastSelect === selectedValue){
      return;
    }
    lastSelect.lastSelect = selectedValue;
    if(onChangeSelect !== undefined){
      const result = {
        target : {
          value: selectedValue,
        }
      };
      onChangeSelect(result as unknown as Event);
    }
  }, [lastSelect, onChangeSelect]);

  //////////////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<SelectOption | undefined>();
  const [tempSelected, setTempSelected] = useState<SelectOption | undefined>();
  const [removedValue, setRemovedValue] = useState<boolean>();

  // initial select
  useLayoutEffect(() => {
    //
    let removedValue = '';
    for(const r of selectOptionsAll){
      if(r.value === String(value)){
        removedValue = r.label;
      }
    }
    //
    if(selectOptions.length > 0){
      const v = findByValue(String(value));
      if(v){
        setSelected(v);
        setTempSelected(v);
      }
      if(!v?.label && removedValue){
        setRemovedValue(true);
      }
      refInput.current.value = v?.label ?? removedValue ?? '';
    }else{
      refInput.current.value = '';
    }
    //
  }, [value, selectOptions, findByValue, selectOptionsAll]);

  //////////////////////////////////////////////////////////////////////////////

  const refOptions = useRef<HTMLDivElement>();
  const refInput = useRef<HTMLInputElement>();

  //////////////////////////////////////////////////////////////////////////////

  const refItems = useRef<HTMLDivElement>();
  const itemsRect = useRef<DOMRect | undefined>(undefined);
  //
  const rafState = useMemo<{raf?: number, uid: string}>(() => {return {uid: v4()};}, []);

  useEffect(() => {
    if(!show){
      if(rafState.raf){
        window.cancelAnimationFrame(rafState.raf);
        rafState.raf = undefined;
      }
      return;
    }

    // parent move (scroll)
    const calcRect = () => {
      return window.requestAnimationFrame(() => {
        if(!show || !rafState.raf || !refInput.current){
          return;
        }
        //
        const rect = refInput.current.getBoundingClientRect();
        if(itemsRect.current){
          if(rect.left !== itemsRect.current.left || rect.bottom !== itemsRect.current.bottom){
            if(refItems.current){
              refItems.current.style.top = `${rect.bottom}px`;
              refItems.current.style.left = `${rect.left}px`;
              itemsRect.current = rect;
            }
          }
        }else{
          itemsRect.current = rect;
        }
        calcRect();
        //
      });
    };

    rafState.raf = calcRect();
    return () => {
      if(rafState.raf){
        window.cancelAnimationFrame(rafState.raf);
        rafState.raf = undefined;
      }
    };
    //
  }, [show, rafState]);


  //////////////////////////////////////////////////////////////////////////////

  const itemClick = useCallback((e:Event, o: SelectOption) => {
    refInput.current.value = o.label;
    onChangeSelectEvent(o.value ?? o.label);
    setRemovedValue(false);
    setSelected(o);
    setTempSelected(o);
    setShow(false);
  }, [onChangeSelectEvent, setShow]);

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const wc = addListener(window, 'click', (e: Event) => {
      if(show){
        if(isChild(e.target as Node, refItems.current)){
          return;
        }
        e.stopPropagation();
        setShow(false);
      }
    });
    return () => {
      wc.remove();
    };
  }, [show, setShow]);

  useEffect(() => {
    if(state.show === show){
      return;
    }
    state.show = show;
    //
    if(!show){
      layerRender(uniqueId, undefined);
      return;
    }
    //
    const rect = refInput.current.getBoundingClientRect();
    const output = (
      <div
        style={{
          position: 'absolute',
          top: `${rect.bottom}px`,
          left: `${rect.left}px`,
        }}
        ref={refItems}
      >
        <div
          className="revu-select-option-items"
          style={{
            minWidth: `${rect.width}px`,
            maxHeight: `${maxHeight?`${maxHeight}px`:'auto'}`,
            overflowY: 'auto'
          }}
          ref={refOptions}
        >
          {
            selectOptions.map((o) => {
              assert(o.key);
              return <div
                key={o.key?o.key: (console.log('%cSelect Key Error', 'background-color: red;'), undefined)}
                className={{
                  'revu-select-option-item': true,
                  [`revu-select-option-item-${o.key}`]: true,
                  'selected': (tempSelected?.label === o.label),
                }}
                onClick={(e: Event) => itemClick(e, o)}
              >{o.htmlLabel ?? o.label}</div>;
            })
          }
        </div>
      </div>
    );
    layerRender(uniqueId, output);

    // inner item scroll
    window.requestAnimationFrame(() => {
      if(!refOptions.current){
        return;
      }
      //
      const key = tempSelected?.key ?? selected?.key;
      if(key){
        const option = document.querySelector(`.revu-select-option-item-${key}`);
        if(option){
          const optionRect = option.getBoundingClientRect();
          const optionsRect = refOptions.current.getBoundingClientRect();
          //
          if(optionRect.top - optionsRect.top < 0){
            refOptions.current.scrollBy({
              left: 0,
              top: (optionRect.top - optionsRect.top),
            });
          }else if(optionRect.bottom - optionsRect.bottom > 0){
            refOptions.current.scrollBy({
              left: 0,
              top: (optionRect.bottom - optionsRect.bottom),
            });
          }
        }
      }
      //
    });
    //
  }, [show, tempSelected, selectOptions, uniqueId, refInput, refOptions, selected, layerRender, maxHeight, itemClick, state, setShow]);

  //////////////////////////////////////////////////////////////////////////////

  useEffectOnce(() => {
    return () => {
      layerRender(uniqueId, undefined);
    };
  });

  //////////////////////////////////////////////////////////////////////////////

  const findNext = useCallback((s: string | undefined, reverse: boolean) => {
    //
    if(selectOptions.length === 0){
      return;
    }
    for(let i = 0; i < selectOptions.length; i++){
      const o = selectOptions[i];
      if(o.value === s){
        if(reverse){
          return selectOptions[i - 1];
        }
        return selectOptions[i + 1];
      }
    }
    return selectOptions[0];
    //
  }, [selectOptions]);

  ////////////////////////////////////////

  const onKeydown = useCallback((e: KeyboardEvent) => {
    //
    switch(e.key){
      case 'Tab':{
        if(show){
          e.preventDefault();
          e.stopPropagation();
        }
      }break;
      case 'Escape':{
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }break;
      case 'Enter':{
        e.preventDefault();
        e.stopPropagation();
        if(show){
          if(tempSelected){
            itemClick(e, tempSelected);
          }
          setShow(false);
        }else{
          setShow(true);
        }
      }break;
      case 'ArrowDown':{
        e.preventDefault();
        e.stopPropagation();
        if(show){
          const t = findNext(tempSelected?.value, false);
          if(t){
            setTempSelected(t);
          }
        }else{
          setShow(true);
        }
      }break;
      case 'ArrowUp':{
        e.preventDefault();
        e.stopPropagation();
        if(show){
          const t = findNext(tempSelected?.value, true);
          if(t){
            setTempSelected(t);
          }
        }else{
          setShow(true);
        }
      }break;
    }
    //
  }, [findNext, itemClick, setShow, show, tempSelected]);

  //////////////////////////////////////////////////////////////////////////////

  const onShow = useCallback((_e:PointerEvent) => {
    setShow(!show);
  }, [setShow, show]);

  //////////////////////////////////////////////////////////////////////////////

  const onInput = useCallback((e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    if(target){
      const select = findByLabel(target.value);
      if(select){
        itemClick(e, select);
      }
    }
  }, [findByLabel, itemClick]);

  //////////////////////////////////////////////////////////////////////////////

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {
      style: {width: '100%', textDecoration: removedValue?'none': 'none', color: removedValue?'#888888': undefined},
      ref:refInput,
      readonly: 'true',
      'select-input-readonly' : readonly?'true':'false',
      onKeydown,
      onInput,
    });
  }, [inherited, onInput, onKeydown, readonly, removedValue]);

  return (
    <div className={{'revu-select': true, 'disabled': readonly}} onClick={onShow}>
      <input
        {...inheritedProps}
      />
    </div>
  );

};

export { Select, SelectOption, SelectOptions, SelectLayer };
