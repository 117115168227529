import { assert } from '../lib/assert';
import { getContext } from './context';
import { isSameArray } from '../lib/isSameArray';
import { applyCss, addFcid, toJSON, toCSSString } from './applyCss';
import { CSS, styleToString } from '../lib/styleToString';

////////////////////////////////////////////////////////////////////////////////

interface CssContext {
  initialized?: boolean;
  checked?: unknown[];
  element?: HTMLStyleElement;
  loadCount: number;
}

let cssContexts: {[fcid: string]: CssContext} = {};

const resetCssContexts = () => {
  cssContexts = {};
};

////////////////////////////////////////////////////////////////////////////////

function useCss(cssStyle: string | CSS, check: unknown[] = [], global = false, raw = false): void {
  const context = getContext({});

  const cssId = `${context.fcid}@${context.index}`;
  cssContexts[cssId] = cssContexts[cssId] || {loadCount: 0};
  const cssContext = cssContexts[cssId];
  cssContext.loadCount++;

  context.purgeFuncs.push(() => {
    if(--cssContext.loadCount === 0){
      if(context.renderer.config.purgeCss){
        cssContext.initialized = undefined;
        cssContext.element?.parentNode?.removeChild(cssContext.element);
        cssContext.element = undefined;
        cssContext.checked = undefined;
      }
    }
  });

  if(!cssContext.initialized){
    cssContext.initialized = true;
  }else{
    if(isSameArray(cssContext.checked, check)){
      return;
    }
  }
  cssContext.checked = check;

  // remove old
  cssContext.element?.parentNode?.removeChild(cssContext.element);
  cssContext.element = undefined;

  // raw mode
  if(raw){
    assert(typeof cssStyle === 'string');
    cssContext.element = applyCss(cssStyle);
    return;
  }

  let css = '';
  if(typeof cssStyle !== 'string'){
    css = styleToString(cssStyle);
  }else{
    css = cssStyle;
  }
  const cssJson = toJSON(css);
  /* istanbul ignore next */
  if(!cssJson){
    return;
  }

  // append fcid
  if(!global){
    addFcid(cssJson, `fc${context.fcid}`);
  }

  // apply
  cssContext.element = applyCss(toCSSString(cssJson));
  //
}

////////////////////////////////////////////////////////////////////////////////

function useRawCss(cssStyle: string, check?: unknown[]): void{
  useCss(cssStyle, check, false, true);
}

function useGlobalCss(cssStyle: string | CSS, check?: unknown[]): void{
  useCss(cssStyle, check, true, false);
}

////////////////////////////////////////////////////////////////////////////////

export { useCss, useRawCss, useGlobalCss, CSS, resetCssContexts };