import { getToken, updateToken } from '@/context/token';

////////////////////////////////////////////////////////////////////////////////

const fetchWithTokenCore = async (
  endPoint: string,
  option: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    mode?: RequestMode,
    cache?: RequestCache,
    headers?: {[key:string]: string},
    body?: string,
  },
  needUpdateToken: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (o: any) => any,
  retry?: boolean,
): Promise<JSON | undefined> => {
  //
  if(needUpdateToken){
    await updateToken();
  }
  //
  const token = getToken();
  if(!token){
    return;
  }
  //
  try{
    //
    const result = await fetch(endPoint, {
      ...option,
      method: option.method ?? 'GET',
      mode: option.mode ?? 'cors',
      cache: option.cache ?? 'no-cache',
      headers: {
        ...option.headers,
        'Authorization': `Bearer ${token.access_token}`,
        'Content-Type': 'application/json'
      },
      body: option.body
    });
    //
    const fetchResult = await result.json() as JSON;
    if(validate && !validate(fetchResult)){
      if(!retry){
        return await fetchWithTokenCore(endPoint, option, needUpdateToken, validate, true);
      }
      return;
    }
    return fetchResult;
    //
  }catch(e){
    if(!retry){
      return await fetchWithTokenCore(endPoint, option, needUpdateToken, validate, true);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchWithToken = async <T = any>(
  endPoint: string,
  option: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    mode?: RequestMode,
    cache?: RequestCache,
    headers?: {[key:string]: string},
    body?: string,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (o: any) => any,
  needUpdateToken = true
): Promise<T | undefined> => {
  const result = await fetchWithTokenCore(endPoint, option, needUpdateToken, validate);
  if(!result){
    return;
  }
  return result as unknown as T;
};

export { fetchWithToken };