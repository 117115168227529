import { LocalStorage } from '@/lib/Storage';
import { getEnvironments } from '@/../config/env';
import { setUser } from './user';
import { mutex } from '@/lib/mutex';
import jwt_decode from 'jwt-decode';
import { tokenUpdated } from './AppContext';
import { useEffectOnce } from '@/ReVu2';
import { clearSignInInfo, setUpdatedToken } from './SignInInfo';

////////////////////////////////////////////////////////////////////////////////

const decodeToken = (token?: string) => {
  if(!token){
    return;
  }

  const x = jwt_decode<{
    client_id: string,
    iss: string,
    sub: string,
    created_at: number,
    exp: number,
    iat: number,
  }>(token);

  const created_at = new Date();
  created_at.setTime(x.created_at * 1000);
  const exp = new Date();
  exp.setTime(x.exp * 1000);
  const iat = new Date();
  iat.setTime(x.iat * 1000);

  return {
    client_id: x.client_id,
    iss: x.iss,
    sub: x.sub,
    created_at,
    exp,
    iat
  };

};

////////////////////////////////////////////////////////////////////////////////

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token?: string;
  scope: string;
  state?: string;
  //
  error?: string;
  error_description?: string;
}

const TOKEN = 'token';

const setToken = (token: TokenResponse | undefined) => {
  LocalStorage.set<TokenResponse>(TOKEN, token);
};

const getToken = (): TokenResponse | undefined => {
  return LocalStorage.get<TokenResponse>(TOKEN);
};

////////////////////////////////////////////////////////////////////////////////

const updateToken = async (): Promise<void> => {
  await mutex('updateToken', async () => {
    //
    const access_token = getToken()?.access_token;
    if(!access_token){
      return;
    }
    //
    const tokenDetail = decodeToken(access_token);
    if(!tokenDetail){
      return;
    }

    const diff = tokenDetail.exp.getTime() - (new Date()).getTime();
    const diffMin = diff / 1000 / 60;

    if(diffMin < 5){  // 5 min
      await updateTokenCore();
      setUpdatedToken();
      setTimeout(() => {
        tokenUpdated();
      }, 100);
    }
    return;
    //
  });
};

const updateTokenCore = async (retry = 3): Promise<TokenResponse | undefined> => {
  //
  const debug = getEnvironments().debug;
  //
  try{
    //
    const refresh_token = getToken()?.refresh_token;
    if(!refresh_token){
      return;
    }
    //
    const updateTokenEndPoint = `${getEnvironments().updateTokenEndPoint}`;
    const updateTokenResult = await fetch(updateTokenEndPoint, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${refresh_token}`,
      },
    });
    //
    const result = await updateTokenResult.json() as TokenResponse;
    setToken(result);
    if(debug){
      console.log('%cUPDATE TOKEN SUCCEEDED', 'background-color: yellow; color: black;', result);
    }
    return result;
    //
  }catch(e){
    //
    if(retry > 0){
      return updateTokenCore(retry - 1);
    }
    //
    setToken(undefined);
    setUser(undefined);
    return;
    //
  }
  //
};

////////////////////////////////////////////////////////////////////////////////

const revokeToken = async (retry = 3): Promise<void> => {
  //
  const token = getToken();
  if(!token){
    setToken(undefined);
    setUser(undefined);
    return;
  }
  //
  try{
    const revokeTokenEndPoint = `${getEnvironments().revokeEndPoint}`;
    await fetch(revokeTokenEndPoint, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
      },
    });
    if(token.refresh_token){
      await fetch(revokeTokenEndPoint, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${token.refresh_token}`,
        },
      });
    }
    //
    clearSignInInfo();
    //
  }catch(e){
    //
    if(retry > 0){
      return revokeToken(retry - 1);
    }
    //
    setToken(undefined);
    setUser(undefined);
    return;
    //
  }
  //
};

const useAutoUpdateToken = (): void => {
  //
  useEffectOnce(() => {
    const i = setInterval(() => {
      void updateToken();
    }, 60 * 1000);
    return () => {
      clearInterval(i);
    };
  });
  //
};

export { TokenResponse, setToken, getToken, revokeToken, updateToken, useAutoUpdateToken };