/* istanbul ignore file */
let debugRouter = false;

const DebugRouter = (f?: boolean) => {
  if(f === undefined){
    return debugRouter;
  }
  debugRouter = true;
};

export {
  DebugRouter,
};
