import { assert } from '../lib/assert';
import { getContext } from './context';

////////////////////////////////////////////////////////////////////////////////

interface StackContext<T, P> {
  initialized?: boolean;
  stack?: [T, GetParentStack<T, P>];
}

type GetParentStack<T, P = T> = (parentCtxid?: string) => [T, GetParentStack<T, P>];

////////////////////////////////////////////////////////////////////////////////

let stackData: {[kind:symbol]: {[ctxid:string]: StackContext<unknown, unknown>}} = {};

const resetStackData = () => {
  stackData = {};
};

////////////////////////////////////////////////////////////////////////////////

const useExistsStack = <T extends object, P = T>(kind: symbol, ctxid: string): [T, GetParentStack<T, P>] => {
  //
  if(!stackData[kind]){
    return [undefined, undefined] as unknown as [T, GetParentStack<T, P>];
  }
  //
  const st = stackData[kind][ctxid];
  if(st){
    return st.stack as [T, GetParentStack<T, P>];
  }
  //
  const l = ctxid.split('.');
  l.pop();
  if(l.length === 0){
    return [undefined, undefined] as unknown as [T, GetParentStack<T, P>];
  }
  const target = l.join('.');
  //
  return useExistsStack(kind, target);
  //
};

////////////////////////////////////////////////////////////////////////////////

const useStack = <T extends object, P = T>(kind: symbol, init?: T): [T, GetParentStack<T, P>] => {
  const context = getContext<StackContext<T, P>>({});

  stackData[kind] = stackData[kind] || {};

  const getParentStack = (parentCtxid?: string): [T, GetParentStack<T, P>] => {
    //
    const ctxid = parentCtxid ?? context.ctxid;
    //
    const l = ctxid.split('.');
    l.pop();
    if(l.length === 0){
      return [undefined as unknown as T, getParentStack];
    }
    const target = l.join('.');
    //
    const parentStack = stackData[kind][target];
    if(!parentStack){
      return getParentStack(target);
    }
    //
    return parentStack.stack as [T, GetParentStack<T, P>];
    //
  };

  const exists = stackData[kind][context.ctxid];
  if(exists){
    return exists.stack as [T, GetParentStack<T, P>];
  }

  if(!context.data.initialized){
    context.data.initialized = true;
    //
    /* istanbul ignore next */
    if(typeof init !== 'object'){
      console.warn('stack data type is not object');
    }
    //
    context.data.stack = [init as T, getParentStack];
    stackData[kind][context.ctxid] = context.data;
    //
    context.purgeFuncs.push(() => {
      delete stackData[kind][context.ctxid];
    });
  }

  assert(context.data.stack);
  context.data.stack[1] = getParentStack;
  return context.data.stack;
};

////////////////////////////////////////////////////////////////////////////////

export { useStack, useExistsStack, resetStackData };