import { assert } from './assert';
import { camel2Kebabu } from './changeCase';

interface CSS {
  [selector:string]: CSS | string | number;
}

function styleToStringCore(styles: CSS, parentKey= '', master: {[key:string]:string[]} = {}): string {

  for(const style in styles){
    const v = styles[style];

    if(typeof v === 'string' || typeof v === 'number'){
      master[parentKey] = master[parentKey] ?? [];
      master[parentKey].push(`  ${camel2Kebabu(style)}:${v};`);
      continue;
    }

    const parents = style.split(',').map(v => v.trim());
    for(const parent of parents){
      const newParentKey = (
        parent.substring(0,1)==='&')?
        `${parentKey}${parent.substring(1)}`
        :
        `${parentKey?`${parentKey} `:''}${parent}`;
      styleToStringCore(v, newParentKey, master);
    }
  }

  const ss: string[] = [];
  for(const selector in master){
    ss.push(`${selector} {`);
    //
    for(const css of master[selector]){
      ss.push(css);
    }
    ss.push('}');
  }

  return ss.join('\n');
}

function styleToString(styles: CSS): string {
  //
  const results: string[] = [];
  //
  for(const style in styles){
    const v = styles[style];

    const parents = style.split(',').map(v => v.trim());
    for(const parent of parents){
      assert(typeof v !== 'string' && typeof v !== 'number');
      if(parent.substring(0,1) === '@'){
        // media query
        results.push(`${parent} {`);
        results.push(styleToStringCore(v));
        results.push('}');
        continue;
      }else{
        results.push(styleToStringCore(v, parent));
      }

    }
    //
  }
  //
  return results.join('\n');
  //
}

export { CSS, styleToString };