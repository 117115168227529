import { assert } from '../lib/assert';
import { StyleProps, ClassProps } from '../types';
import { splitStyle } from '../lib/splitStyle';

////////////////////////////////////////////////////////////////////////////////

interface Props {
  class?: ClassProps;
  className?: ClassProps;
  style?: StyleProps;
  [key:string]: unknown;
}

////////////////////////////////////////////////////////////////////////////////

const margeProps = (propsParam: unknown, appendParam: unknown): Props => {

  assert(typeof propsParam === 'object' && typeof appendParam === 'object');

  const props = propsParam as Props ?? {};
  const append = appendParam as Props ?? {};

  //////////////////////////////////////////////////////////////////////////////
  // marge class

  let classProps: {[key:string]: boolean} = {};
  //
  const workClassProps = props.class || props.className;
  if(typeof workClassProps === 'string'){
    classProps[workClassProps] = true;
  }else if(Array.isArray(workClassProps)){
    workClassProps.filter(c => c).map((c) => {classProps[c] = true;});
  }else if(workClassProps){
    classProps = {...classProps, ...workClassProps};
  }

  let appendClassProps: {[key:string]: boolean} = {};
  //
  const workAppendClassProps = append.class || append.className;
  if(typeof workAppendClassProps === 'string'){
    appendClassProps[workAppendClassProps] = true;
  }else if(Array.isArray(workAppendClassProps)){
    workAppendClassProps.filter(c => c).map((c) => {appendClassProps[c] = true;});
  }else if(workAppendClassProps){
    appendClassProps = {...appendClassProps, ...workAppendClassProps};
  }

  props.class = {...appendClassProps, ...classProps};
  if(Object.keys(props.class).length === 0){
    props.class = undefined;
  }

  //////////////////////////////////////////////////////////////////////////////
  // marge style

  const workStyleProps = props.style;
  //
  let styleProps: StyleProps = {};
  if(typeof workStyleProps === 'string'){
    styleProps = splitStyle(workStyleProps);
  }else if(workStyleProps){
    styleProps = workStyleProps;
  }

  const workAppendStyleProps = append.style;
  //
  let appendStyleProps: StyleProps = {};
  if(typeof workAppendStyleProps === 'string'){
    appendStyleProps = splitStyle(workAppendStyleProps);
  }else if(workAppendStyleProps){
    appendStyleProps = workAppendStyleProps;
  }

  props.style = {...appendStyleProps, ...styleProps};
  if(Object.keys(props.style).length === 0){
    props.style = undefined;
  }

  //////////////////////////////////////////////////////////////////////////////
  // marge other

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {className, style, ...extAppend} = append;
  delete extAppend['class'];

  return {...props, ...extAppend};

};

////////////////////////////////////////////////////////////////////////////////

export { margeProps };