const SRC = '0123456789abcdefghijklmnopqrstuvwxyz';
const SRC_LEN = SRC.length;

const num36 = {
  encode: (n: number): string => {
    if(n === 0){
      return SRC.substring(0, 1);
    }
    //
    const results: string[] = [];
    let v = n;
    while(v > 0){
      const a = v % SRC_LEN;
      v = (v - a) / SRC_LEN;
      results.unshift(SRC.substring(a, a + 1));
    }
    //
    return results.join('');
  },
  decode: (n62: string): number => {
    if(!n62){
      return -1;
    }
    //
    let n = 0;
    let r = Math.pow(SRC_LEN, n62.length - 1);
    for(const c of n62){
      n += SRC.indexOf(c) * r;
      r /= SRC_LEN;
    }
    //
    return n;
  },
};

export { num36 };
