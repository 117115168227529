import { Params } from './types';
import { useForceUpdate } from './hooks/useForceUpdate';

type LazyFC = (props: Params) => JSX.Element;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fcCache = new Map<() => Promise<any>, LazyFC>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazy = (o: () => Promise<any>): LazyFC => {
  //
  return (props: Params): JSX.Element  => {
    //
    const forceUpdate = useForceUpdate('lazy');
    //
    let fc = fcCache.get(o);
    if(fc === undefined){
      //
      fc = () => <></>;
      fcCache.set(o, fc);
      //
      void (async() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        fc = ((await o())['default'] as LazyFC);
        fcCache.set(o, fc);
        forceUpdate();
        //
      })();
      //
    }
    //
    return fc(props);
    //
  };
};

export { lazy };
