import { useCss, StyleProps, ClassProps, margeProps, useMemo, Ref } from '../..';

////////////////////////////////////////////////////////////////////////////////

interface ScrollType {
  id?: string;
  class?: ClassProps;
  className?: ClassProps;
  style?: StyleProps;
  children: JSX.Children;
  onScroll?: (e:Event) => void;
  ref?: Ref<any>;
}

interface ScrollCoreType extends ScrollType{
  scrollX: boolean;
  scrollY: boolean;
}

////////////////////////////////////////////////////////////////////////////////

const ScrollCore = ({scrollX, scrollY, children, onScroll, ref, ...inherited}: ScrollCoreType) => {

  useCss({
    '.overflow-wrap':{
      width: '100%',
      height: '100%',
      position: 'absolute',
      overflowX: 'auto',
    },
    '.overflow-x-contents':{
      whiteSpace:'nowrap',
    },
    '.overflow-y-contents':{
    }
  });

  //////////////////////////////////////////////////////////////////////////////

  const containerClass = useMemo(() => {
    const containerClass: string[] = [];
    if(scrollX){
      containerClass.push('overflow-x-contents');
    }
    if(scrollY){
      containerClass.push('overflow-y-contents');
    }
    return containerClass;
  }, [scrollX, scrollY]);

  //////////////////////////////////////////////////////////////////////////////

  const props = useMemo(() => {
    return margeProps(inherited, {class: containerClass});
  }, [containerClass, inherited]);

  return (
    <div className="overflow-wrap" onScroll={onScroll} ref={ref}>
      <div {...props}>
        {children}
      </div>
    </div>
  );

};

const ScrollY = (prop: ScrollType) => {
  return ScrollCore({...prop, scrollX: false, scrollY: true});
};

const ScrollX = (prop: ScrollType) => {
  return ScrollCore({...prop, scrollX: true, scrollY: false});
};

const ScrollXY = (prop: ScrollType) => {
  return ScrollCore({...prop, scrollX: true, scrollY: true});
};

export { ScrollX, ScrollY, ScrollXY };