import { useExistsRouterStack } from './useRouterStack';

////////////////////////////////////////////////////////////////////////////////

function useParams<T = unknown>(): T {
  const routeContext = useExistsRouterStack();
  return (routeContext.match?.params ?? {}) as T;
}

////////////////////////////////////////////////////////////////////////////////

export { useParams };