const mutexCount: {[key:string]: boolean} = {};

const mutex = async <T>(key: string, func: () => Promise<T>): Promise<T>  => {

  if(!mutexCount[key]){
    mutexCount[key] = true;
    const result = await func();
    mutexCount[key] = false;
    return result;
  }

  return new Promise((resolve) => {
    const i = setInterval(() => {
      //
      if(!mutexCount[key]){
        //
        clearInterval(i);
        //
        mutexCount[key] = true;
        void (async () => {
          //
          const result = await func();
          mutexCount[key] = false;
          //
          resolve(result);
        })();
        //
      }
      //
    }, 10);
  });
};

export { mutex };
