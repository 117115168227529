import {
  styleToString,
  useContext, createContext,
  FC,
} from '../../../';
import defaultTheme, { Theme, Colors } from './defaultTheme';
import { revuUiTheme } from './revuUiTheme';
import { resetCss } from './resetCss';

////////////////////////////////////////////////////////////////////////////////

const DEFAULT_THEME = 'default';

////////////////////////////////////////////////////////////////////////////////

const themeCssString = <T extends Theme>(t: T): string => {
  return styleToString(revuUiTheme(t));
};

////////////////////////////////////////////////////////////////////////////////

interface ThemeContextData<T extends Theme> {
  themes: {[key:string]: T};
  themeName: string;
  globalCss: string;
}

const ContextData = createContext<ThemeContextData<Theme>>({
  themeName: DEFAULT_THEME,
  themes: {[DEFAULT_THEME]: defaultTheme},
  globalCss: `${resetCss}\n${themeCssString(defaultTheme)}`
});

////////////////////////////////////////////////////////////////////////////////

const useTheme = <T extends Theme>(customThemes?: {[key:string]: T}): {
  Provider: FC<{children: JSX.Children}>,
  theme: T,
  globalCss: string,
  setTheme:(p: string) => void
} => {
  const [context, setContext] = useContext(ContextData);

  if(customThemes){
    context.themes = {...context.themes, ...customThemes};
  }

  const getTheme = (t: string): T => {
    return (context.themes[t] ?? context.themes[DEFAULT_THEME]) as T;
  };

  const setTheme = (t: string): void => {
    if(context.themeName !== t){
      setContext(v => {
        return {
          ...v,
          themeName: t,
          globalCss: `${resetCss}\n${themeCssString(getTheme(t))}`
        };
      });
    }
  };

  return {
    Provider: ContextData.Provider,
    theme: getTheme(context.themeName),
    globalCss: context.globalCss,
    setTheme
  };

};

export { Theme, Colors, useTheme, defaultTheme };
