import { Navigate, useLocation} from 'revu2/router';
import { getUser } from '@/context/user';
import { setRedirect } from '@/context/signInRedirect';
import { AppUserKind } from '@/graphql/types';

////////////////////////////////////////////////////////////////////////////////

const SystemAdminArea = ({failed, children}: {failed: string, children: JSX.Children}) => {
  //
  const userInfo = getUser();
  const location = useLocation();
  //
  const redirect = `${location.pathname}${location.search}${location.hash}`;
  if(!userInfo){
    setRedirect(redirect);
  }
  //
  return <>
    {
      userInfo?.kind === AppUserKind.BIMSTOK_ADMIN?
        children
        :
        <Navigate to={failed} replace/>
    }
  </>;
};

export default SystemAdminArea;
