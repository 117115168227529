import { getEnvironments } from '@/../config/env';

////////////////////////////////////////////////////////////////////////////////

type nullableString = string | null;

const PATH2FULLPATH = (k: string, w:string, p:string, id:nullableString, ow: nullableString) => {
  if(!id){
    return `/ws/${w}/${p}/${k}`;
  }
  if(!ow){
    return `/ws/${w}/${p}/${k}/${id}`;
  }
  return `/ws/${w}/${p}/${k}/${id}?owner_id=${ow}`;
};

const ROUTE_PATH = {

  //////////////////////////////////////////////////////////////////////////////

  SIGNUP_PATH: 'signup',

  SIGNIN_PATH: 'signin',
  SIGNIN: () => `${getEnvironments().BASE_URL}${ROUTE_PATH.SIGNIN_PATH}`,

  SIGNIN_CALLBACK_PATH: 'signin_callback',
  SIGNIN_CALLBACK: () => `${getEnvironments().BASE_URL}${ROUTE_PATH.SIGNIN_CALLBACK_PATH}`,

  //////////////////////////////////////////////////////////////////////////////

  SYSTEMADMIN_PATH: 'admin',
  SYSTEMADMIN: () => `${getEnvironments().BASE_URL}${ROUTE_PATH.SYSTEMADMIN_PATH}/workspaces`,

  //////////////////////////////////////////////////////////////////////////////

  WORKSPACE_INFO_PATH: 'ws_info',
  WORKSPACE_INFO: (workspace_id: string) => {
    return `${getEnvironments().BASE_URL}ws/${workspace_id}/${ROUTE_PATH.WORKSPACE_INFO_PATH}`;
  },

  //////////////////////////////////////////////////////////////////////////////

  TERMSAGREEMENT_PATH: 'terms_agreement',
  TERMSAGREEMENT: () => `${getEnvironments().BASE_URL}${ROUTE_PATH.TERMSAGREEMENT_PATH}`,

  //////////////////////////////////////////////////////////////////////////////

  WORKSPACE_PATH: 'ws/:workspace_id',
  WORKSPACE: (workspace_id?: string) => {
    return `${getEnvironments().BASE_URL}${workspace_id ? `ws/${workspace_id}` : 'ws/_'}/${ROUTE_PATH.PROJECTS_PATH}`;
  },

  //////////////////////////////////////////////////////////////////////////////

  PROJECTS_PATH: 'projects',

  PROJECTS_USER_PROFILE_PATH: 'ws_user_profile',
  PROJECTS_USER_PROFILE: (workspace_id: string) => {
    return `${getEnvironments().BASE_URL}ws/${workspace_id}/${ROUTE_PATH.PROJECTS_USER_PROFILE_PATH}`;
  },

  //////////////////////////////////////////////////////////////////////////////

  PROJECT_PATH: ':project_id',
  PROJECT: (workspace_id: string, project_id: string) => {
    return `${getEnvironments().BASE_URL}ws/${workspace_id}/${project_id}/home`;
  },

  //////////////////////////////////////////////////////////////////////////////

  PREVIEW_PATH: 'preview',
  PREVIEW: () => `${getEnvironments().BASE_URL}${ROUTE_PATH.PREVIEW_PATH}`,

  //////////////////////////////////////////////////////////////////////////////

  HOME_PATH: 'home',
  PROJECT_HOME: (w: string, p: string) => `/ws/${w}/${p}/${ROUTE_PATH.HOME_PATH}`,

  INSPECTIONS_PATH: 'inspections',
  INSPECTIONS: (w: string, p: string, id: nullableString, ow: nullableString) => {
    return PATH2FULLPATH(ROUTE_PATH.INSPECTIONS_PATH, w, p, id, ow);
  },

  ISSUE_PATH: 'issues',
  ISSUE: (w: string, p: string, id: nullableString, ow: nullableString) => {
    return PATH2FULLPATH(ROUTE_PATH.ISSUE_PATH, w, p, id, ow);
  },

  REPORTS_PATH: 'reports',
  REPORTS: (w: string, p: string, id: nullableString, ow: nullableString) => {
    return PATH2FULLPATH(ROUTE_PATH.REPORTS_PATH, w, p, id, ow);
  },

  DETAIL_PATH: 'detail',
  DETAIL: (w: string, p: string, facilities_id: nullableString) => {
    return `${PATH2FULLPATH(ROUTE_PATH.DETAIL_PATH, w, p, null, null)}${facilities_id?`?facilities_id=${facilities_id}`:''}`;
  },

  DETAIL2_PATH: 'detail2',
  DETAIL2: (w: string, p: string, facilities_id: nullableString) => {
    return `${PATH2FULLPATH(ROUTE_PATH.DETAIL2_PATH, w, p, null, null)}${facilities_id?`?facilities_id=${facilities_id}`:''}`;
  },

  PLAN_PATH: 'plan',
  PLAN: (w: string, p: string, id: nullableString, ow: nullableString) => {
    return PATH2FULLPATH(ROUTE_PATH.PLAN_PATH, w, p, id, ow);
  },

  SETTING_PATH: 'settings',
  SETTINGS: (w: string, p: string, path: string) => {
    return PATH2FULLPATH(ROUTE_PATH.SETTING_PATH, w, p, path, null);
  },

  ABOUT_PATH: 'about',
  ABOUT: (w: string, p: string) => {
    return PATH2FULLPATH(ROUTE_PATH.ABOUT_PATH, w, p, null, null);
  },

};

////////////////////////////////////////////////////////////////////////////////

export {
  ROUTE_PATH,
};
