import {
  useCss,
  useRef,
  useState,
  shortid,
  ClassProps,
  StyleProps,
  margeProps,
  useCallback,
} from '../..';
import { useTheme } from './theme/useTheme';

////////////////////////////////////////////////////////////////////////////////

// FileSystemEntry for chrome only
function traverseFileTree(item: FileSystemEntry, path: string,
 files: {file: File, path: string, data: string}[], onComplete: () => void) {
  if (item.isFile) {
    (item as any).file((file: File) => {
      const pathResult = path.substring(path.length - 1) === '/' ? path.substring(0, path.length - 1) : path;
      files.push({ file, path: pathResult, data: '' });
      onComplete();
    });
  } else if (item.isDirectory) {
    const dirReader = (item as any).createReader() as FileSystemDirectoryReader;
    dirReader.readEntries((entries) => {
      let pending = entries.length;
      if (!pending) {
        onComplete();
        return;
      }
      entries.forEach((entry) => {
        traverseFileTree(entry, `${path}${item.name}/`, files, () => {
          pending--;
          if (!pending) {
            onComplete();
          }
        });
      });
    });
  }
}

////////////////////////////////////////////////////////////////////////////////

interface FileData {
  file: File,
  path: string,
  data: string,
}

////////////////////////////////////////////////////////////////////////////////

class FileReaderEx extends FileReader {
  constructor(){
    super();
  }

  readAs(blob, ctx){
    return new Promise((res, rej)=>{
      super.addEventListener('load', ({target}) => res(target?.result));
      super.addEventListener('error', ({target}) => rej(target?.error));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      super[ctx](blob);
    });
  }

  readAsArrayBuffer(blob){
    return this.readAs(blob, 'readAsArrayBuffer');
  }

  readAsDataURL(blob){
    return this.readAs(blob, 'readAsDataURL');
  }

  readAsText(blob){
    return this.readAs(blob, 'readAsText');
  }
}

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  });
};

////////////////////////////////////////////////////////////////////////////////

interface FileSelectParams {
  buttonOnly?: boolean,
  dropOnly?: boolean,
  resize?: {x?: number, y?: number},  // create resized image
  //
  selected: (dropId: string, files:FileData[]) => Promise<void> | void,
  onDrop?: (e: DragEvent) => Promise<boolean> | boolean,
  //
  accept?: string,
  multiple?: boolean,
  directory?: boolean,
  //
  dropAreaStyle?: StyleProps,
  //
  children: JSX.Children
  //
  disabled?: boolean,
  //
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
  outline?: boolean,
}

const FileSelect = ({
  buttonOnly,
  dropOnly,
  resize,
  //
  selected,
  onDrop,
  //
  accept,
  multiple,
  directory,
  //
  dropAreaStyle,
  //
  children,
  disabled,
  outline,
  //
  ...inherited
}: FileSelectParams) => {

  const {theme} = useTheme();
  useCss({
    'input[type="file"]': {
      display: 'none',
    },
    '.input-file':{
      display: 'inline-block',
      padding: '0.4rem 1rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      fontWeight: 'bold',
      //
      border: `1px solid ${theme.inputButton.primaryBkColor}`,
      //      color: `${theme.inputButton.primaryBkColor} !important`,
      backgroundColor: theme.inputButton.primaryColor,
      //
      '&:hover':{
        //        color: `${theme.color.white} !important`,
        backgroundColor: theme.inputButton.primaryHover,
      },
      //
      '&[disabled]':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

    '.input-file.outline':{
      color: `${theme.inputButton.primaryBkColor} !important`,
      '&:hover':{
        color: `${theme.color.white} !important`,
      },
    },

    '.file-drop':{
      backgroundColor: theme.inputButton.primaryColor,
      '&.file-drag-over':{
        backgroundColor: `${theme.color.primary}44`,
      },
    }
  }, [theme]);

  //////////////////////////////////////////////////////////////////////////////

  const [draggingClass, setDraggingClass] = useState<string | undefined>();
  const inputButtonRef = useRef<HTMLInputElement | undefined>();

  //////////////////////////////////////////////////////////////////////////////

  const onDragover = useCallback((e:DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingClass('file-drag-over');
  }, []);

  const onDragleave = useCallback((e:DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingClass(undefined);
  }, []);

  const selectedInner = useCallback(async (dropId: string, files: FileData[]) => {
    //

    for(const file of files){
      const data = await new FileReaderEx().readAsDataURL(file.file) as string;
      if(resize && data.substring(0, 11) === 'data:image/'){
        //
        const image = await loadImage(data);
        //
        const rx = (image.width / (resize.x || 300));
        const ry = (image.height / (resize.y || 150));
        const rr = Math.min(rx, ry);
        //
        const canvas = document.createElement('canvas');
        canvas.width = Math.floor(image.width / rr);
        canvas.height = Math.floor(image.height / rr);
        const ctx = canvas.getContext('2d');
        //
        if(ctx){
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const test = canvas.toDataURL('image/png');
          file.data = test;
        }
        //
      }else{
        file.data = data;
      }
    }
    return selected(dropId, files);
    //
  }, [resize, selected]);

  const onDropArea = useCallback(async (e: DragEvent): Promise<void> => {

    setDraggingClass(undefined);
    if(onDrop){
      if(await onDrop(e) !== false){
        return;
      }
    }

    e.preventDefault();
    e.stopPropagation();
    //
    if(!e.dataTransfer){
      return;
    }

    const dropId = shortid(6);
    const accumulatedFiles: { file: File; path: string; data: string }[] = [];

    const finalizeDrop = async () => {
      await selectedInner(dropId, accumulatedFiles);
    };

    // file and folder drop for chrome...
    const items = e.dataTransfer.items;
    if(items){
      let pending = items.length;
      if (!pending) {
        await finalizeDrop();
        return;
      }
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          traverseFileTree(entry, '', accumulatedFiles, () => {
            pending--;
            if (!pending) {
              finalizeDrop();
            }
          });
        } else {
          pending--;
          if (!pending) {
            finalizeDrop();
          }
        }
      }
      return;
    }

    // for classic browser
    const fileList = e.dataTransfer.files;
    if (!fileList || fileList.length === 0) {
      return;
    }

    for(const file of fileList){
      accumulatedFiles.push({file, path: '', data:''});
    }

    await finalizeDrop();
  }, [onDrop, selectedInner]);

  //////////////////////////////////////////////////////////////////////////////

  const onFileSelect = useCallback(async (event: InputEvent): Promise<void> => {
    //
    const fileList = (event.target as HTMLInputElement).files;
    if (!fileList || fileList.length === 0) {
      return;
    }
    const files: FileData[] = [];
    for(const file of fileList){
      files.push({file, path: '', data: ''});
    }
    //
    const dropId = shortid(6);
    console.log(files);
    await selectedInner(dropId, files);
    //
    if(inputButtonRef.current){
      inputButtonRef.current.value = '';
    }
    //
  }, [selectedInner]);

  //////////////////////////////////////////////////////////////////////////////

  if(buttonOnly){
    const inheritedProps = margeProps(inherited, {class: outline?'input-file outline':'input-file', style: {
      textAlign: 'center',
    }});
    return (
      <label {...inheritedProps} disabled={disabled?'':undefined}>
        <input
          ref={inputButtonRef}
          type="file"
          name="file"
          accept={accept}
          multiple={multiple?true:null}
          // eslint-disable-next-line react/no-unknown-property
          directory={directory?true:null}
          webkitdirectory={directory?true:null}
          onChange={onFileSelect}
          readOnly={disabled?'':undefined}
          disabled={disabled?'':undefined}
        />{children}
      </label>
    );
  }

  //////////////////////////////////////////////////////////////////////////////

  if(dropOnly){
    const inheritedDropProps = margeProps(inherited, {
      class: ['file-drop', draggingClass],
      style: dropAreaStyle,
    });
    return (
      <div
        {...inheritedDropProps}
        onDragOver={onDragover}
        onDragLeave={onDragleave}
        onDrop={onDropArea}
      >
        {children}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////

  const inheritedProps = margeProps(inherited, {class: outline?'input-file outline':'input-file', style: {
    textAlign: 'center',
  }});
  const inheritedDropProps = margeProps(inherited, {
    class: ['file-drop', draggingClass],
    style: dropAreaStyle,
  });

  return (
    <div
      {...inheritedDropProps}
      onDragOver={onDragover}
      onDragLeave={onDragleave}
      onDrop={onDropArea}
    >
      <label {...inheritedProps} disabled={disabled?'':undefined}>
        <input
          type="file"
          name="file"
          accept={accept}
          multiple={multiple?true:null}
          // eslint-disable-next-line react/no-unknown-property
          directory={directory?true:null}
          webkitdirectory={directory?true:null}
          onChange={onFileSelect}
          readOnly={disabled?'':undefined}
          disabled={disabled?'':undefined}
        />{children}
      </label>
      <span className="input-file-name">選択してください</span>
    </div>
  );
};

export { FileSelect, FileData };
