import {
  assert,
  margeProps,
  ClassProps,
  useCallback,
  useCss,
  useState,
  useMemo,
  deepEqual,
  useForceUpdate,
  useLayoutEffect,
  addListener,
  ListenInfo,
  useRef,
  StyleProps,
  useEffect
} from '../..';
import { useTheme } from './theme/useTheme';

////////////////////////////////////////////////////////////////////////////////

interface TableColumn {
  key: string,
  title: string | JSX.Element;
  style?: StyleProps;
  headerStyle?: StyleProps;
}
type TableColumns = TableColumn[]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableItem = any;
type TableItems = TableItem[]

////////////////////////////////////////////////////////////////////////////////

const TABLE_LINE = 'revu-table-line';
const TABLE_LINE_ID = 'lineid';

const findElementFromPos = (x: number, y: number) => {

  const elmParent: Element | ParentNode | null = document.elementFromPoint(x, y);

  let line: Element | undefined = undefined;
  let elm = elmParent;
  //
  while (elm) {
    if(elm instanceof Element){
      if(typeof elm.className === 'string'){
        const testClasses = elm.className.split(' ');
        if (testClasses.indexOf(TABLE_LINE) >= 0) {
          line = elm;
          break;
        }
      }
    }
    elm = elm.parentNode;
  }

  let key: string | undefined = undefined;
  if(line){
    const id = line.getAttribute(TABLE_LINE_ID);
    if(id){
      key = id;
    }
  }

  return {line, key};
};

////////////////////////////////////////////////////////////////////////////////

interface TableParams {
  items: TableItems,
  keyName: string,
  columns?: TableColumns,
  //
  headerRowStyle?: StyleProps,
  rowStyle?: ((row: TableItem) => StyleProps | undefined) | StyleProps,
  columnStyle?: (row: TableItem, column: string) => StyleProps | undefined,
  //
  noHeader?: boolean,
  //
  clickAble?: boolean,
  onClick?: (e:PointerEvent, item: TableItem, index: number) => void | Promise<void>,
  onDblClick?: (e:PointerEvent, item: TableItem, index: number) => void | Promise<void>,
  //
  draggable?: boolean,
  onDragEnd?: (sortedItems: TableItems) => void | Promise<void>
  //
  id?: string;
  class?: ClassProps,
  className?: ClassProps;
  style?: StyleProps,
  //
}

////////////////////////////////////////////////////////////////////////////////

const Table = ({
  items,
  keyName,
  columns,
  headerRowStyle,
  rowStyle,
  columnStyle,
  noHeader,
  clickAble,
  onClick,
  onDblClick,
  draggable,
  onDragEnd,
  ...inherited
}: TableParams) => {

  const {theme} = useTheme();
  useCss({
    '.revu-table':{
      width: '100%',
      height: 'auto',
      borderSpacing: '0',

      borderTop: `1px solid ${theme.table.borderColor}`,

      'tr': {
        width: '100%',
        backgroundColor: theme.table.backgroundColor,
      },

      'th': {
        height: '48px',
        fontWeight: 'normal',
      },
      'td': {
        height: '65px',
      },

      [`.${TABLE_LINE}.dragging`]: {
        backgroundColor: theme.table.dragBackgroundColor,
      },

      'th, td': {
        textAlign: 'left',
        borderBottom: `1px solid ${theme.table.borderColor}`,
      },

      'th[scope="col"]': {
        color: theme.table.headerText,
        backgroundColor: theme.table.headerBackgroundColor,
        borderTop: `1px solid ${theme.table.borderColor}`,
      },

      'th[scope="col"]:first-child, th[scope="row"]':{
        paddingLeft: '1.5rem',
      },
      'th[scope="col"]:last-child, td:last-child':{
        paddingRight: '1.5rem',
      }

    }
  }, [theme]);

  //////////////////////////////////////////////////////////////////////////////

  const forceUpdate = useForceUpdate('ui.Table');

  //////////////////////////////////////////////////////////////////////////////

  const [tableItems, setTableItems] = useState<TableItems>([]);

  useEffect(() => {
    if(deepEqual(items, tableItems)){
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
    setTableItems([...items]);
  }, [items, tableItems]);

  const [tableColumns, setTableColumns] = useState<TableColumns>();

  useEffect(() => {
    if(!columns){
      return;
    }
    if(deepEqual(columns, tableColumns)){
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
    setTableColumns([...columns]);
  }, [columns, tableColumns]);

  //////////////////////////////////////////////////////////////////////////////

  const tableHeaders = useMemo(() => {
    if(!tableColumns){
      const header: TableColumns = [];
      if(tableItems.length > 0){
        for(const key in tableItems[0]){
          header.push({key, title: key});
        }
      }
      return header;
    }
    return [...(tableColumns ?? [])];
  }, [tableColumns, tableItems]);

  //////////////////////////////////////////////////////////////////////////////

  const removeLine = useCallback((parts: TableItems, remove: TableItem) => {
    for(let i = 0; i < parts.length; i++){
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const line = parts[i];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if(line?.[keyName] === remove?.[keyName]){
        parts.splice(i, 1);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return line;
      }
    }
    return;
  }, [keyName]);

  const insertLineBefore = useCallback((parts: TableItems, targetKey: string, newLine: TableItem) => {
    for(let i = 0; i < parts.length; i++){
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const line = parts[i];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if(line?.[keyName] === targetKey){
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if(line?.[keyName] !== newLine?.[keyName]){
          removeLine(parts, newLine);
          parts.splice(i, 0, newLine);
          return true;
        }
        return;
      }
    }
    return;
  }, [keyName, removeLine]);

  const insertLineLast = useCallback((parts: TableItems, newLine: TableItem) => {
    removeLine(parts, newLine);
    parts.push(newLine);
    return true;
  }, [removeLine]);

  //////////////////////////////////////////////////////////////////////////////

  const dragLine = useRef<TableItem | undefined>(undefined);
  const itemsBackup = useRef<TableItems | undefined>(undefined);

  const lineDragStart = useCallback((e: DragEvent, line: TableItem) => {
    if(!e.dataTransfer){
      return;
    }
    e.stopPropagation();
    //
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dragLine.current = line;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    itemsBackup.current = [...tableItems];
    forceUpdate();
    //
  }, [forceUpdate, tableItems]);

  const onDragOver = useCallback((e:DragEvent): void => {
    //
    const found = findElementFromPos(e.clientX, e.clientY);
    //
    if(dragLine.current){
      if(!found){
        e.preventDefault();
        if(insertLineLast(tableItems, dragLine.current)){
          forceUpdate();
        }
        return;
      }
      if(found.key){
        e.preventDefault();
        if(insertLineBefore(tableItems, found.key, dragLine.current)){
          forceUpdate();
        }
      }
      return;
    }
    //
  }, [forceUpdate, insertLineBefore, insertLineLast, tableItems]);

  const onDragEnter = useCallback((e:DragEvent): void => {
    onDragOver(e);
  }, [onDragOver]);

  const onDragLeave = useCallback((_e:DragEvent): void => {
    // NOOP
  }, []);

  const onDrop = useCallback(async (e: DragEvent, succeeded: boolean): Promise<void> => {
    e.preventDefault();
    dragLine.current = undefined;
    //
    if(succeeded){
      itemsBackup.current = undefined;
      if(onDragEnd){
        await onDragEnd?.(tableItems);
      }
    }else{
      if(itemsBackup.current){
        assert(itemsBackup.current);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        tableItems.splice(0, tableItems.length, ...itemsBackup.current);
      }
    }
    //
    forceUpdate();
    //
  }, [forceUpdate, onDragEnd, tableItems]);

  //////////////////////////////////////////////////////////////////////////////

  useLayoutEffect(() => {
    const listeners: ListenInfo[] = [];
    //
    listeners.push(addListener(document, 'dragenter', (e: Event) => {
      onDragEnter(e as DragEvent);
    }));
    listeners.push(addListener(document, 'dragover', (e: Event) => {
      onDragOver(e as DragEvent);
    }));
    listeners.push(addListener(document, 'dragleave', (e: Event) => {
      onDragLeave(e as DragEvent);
    }));
    listeners.push(addListener(document, 'drop', async (e: Event) => {
      await onDrop(e as DragEvent, true);
    }));
    listeners.push(addListener(document, 'dragend', async (e: Event) => {
      await onDrop(e as DragEvent, false);
    }));
    //
    return () => {
      for(const l of listeners){
        l.remove();
      }
    };
    //
  }, [onDragEnter, onDragOver, onDragLeave, onDrop]);

  //////////////////////////////////////////////////////////////////////////////

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {class: 'revu-table'});
  }, [inherited]);

  return (
    <table {...inheritedProps}>
      {
        noHeader?
          <></>
          :
          <tr
            style={ headerRowStyle }
          >
            {
              tableHeaders.map(c =>
                <th
                  scope="col"
                  key={c.key}
                  style={c.headerStyle ?? c.style}
                >
                  {c.title}
                </th>
              )
            }
          </tr>
      }
      {
        tableItems.map((item, index) =>
          <tr
            className={{[TABLE_LINE]: true, 'dragging': item === dragLine.current, 'no-dragging': !dragLine.current}}
            // eslint-disable-next-line react/no-unknown-property, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            lineid={item[keyName]}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            key={item[keyName]?item[keyName]:(console.log('%cTable Key Error', 'background-color: red;', keyName, item),index)}
            style={ (typeof rowStyle === 'function')?rowStyle(item): rowStyle/*    rowStyleFunc?rowStyleFunc(item):rowStyle*/ }
            draggable={draggable?'true':undefined}
            onDragStart={(e:DragEvent) => lineDragStart(e, item)}
          >
            {
              tableHeaders.map((c, i) =>
                (i===0)?
                  <th
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                    key={`${item[keyName]}-${i}`}
                    scope="row"
                    className={clickAble?'clickable':null}
                    style={columnStyle?columnStyle(item, c.key):tableHeaders?.[i].style}
                    onClick={async (e: PointerEvent) => {if(clickAble){await onClick?.(e, item, index);}}}
                    // eslint-disable-next-line react/no-unknown-property
                    onDblClick={async (e: PointerEvent) => {if(clickAble){await onDblClick?.(e, item, index);}}}
                  >
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      item[c.key]
                    }
                  </th>
                  :
                  <td
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                    key={`${item[keyName]}-${i}`}
                    className={clickAble?'clickable':null}
                    style={columnStyle?columnStyle(item, c.key):tableHeaders?.[i].style}
                    //style={tableHeaders?.[i].style}
                    onClick={async (e: PointerEvent) => {if(clickAble){await onClick?.(e, item, index);}}}
                    // eslint-disable-next-line react/no-unknown-property
                    onDblClick={async (e: PointerEvent) => {if(clickAble){await onDblClick?.(e, item, index);}}}
                  >
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      item[c.key]
                    }
                  </td>
              )
            }
          </tr>
        )
      }
    </table>
  );

};

export { TableItem, TableItems, TableColumn, TableColumns };
export { Table };