import { useCss } from 'revu2';
import { DialogParams, createDialog } from 'revu2/ui';
import { useCustomTheme } from '@/theme';
import ModalDialog from '@/pages/components/ModalDialog';

const AlertKind = {
  DANGER: 'DANGER',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

////////////////////////////////////////////////////////////////////////////////

interface AlertDialogContext {
  title?: string
  //
  kind: string
  icon?: JSX.Child
  //
  description?: JSX.Children
  primary?: string
  secondary?: string
  style?: {[key:string]: unknown}
  //
  primaryClass?: string;
  secondaryClass?: string
  //
  hideSecondary?: boolean,
  //
  accept?: boolean
}

////////////////////////////////////////////////////////////////////////////////

const createAlertDialog = <T extends AlertDialogContext>() => {

  const AlertDialog = ({close, context}: DialogParams<T>) => {
    //
    const {theme} = useCustomTheme();
    useCss({
      '.contents' : {
        width: '400px',
        //
        '.form-item':{
          '.must':{
            paddingLeft: '16px',
          },
          'label':{
            //
          },
          'textarea':{
            resize: 'vertical',
            height: '5rem',
          },
          'label, input, textarea':{
            width: '100%',
          }
        },

      },
    }, [theme]);

    let primaryClass = 'primary';
    switch(context.data.kind){
      case AlertKind.DANGER:
        primaryClass = 'alert';
        break;
      case AlertKind.WARNING:
        primaryClass = 'alert-outline';
        break;
      case AlertKind.INFO:
        primaryClass = 'primary';
        break;
    }

    //////////////////////////////////////////////////////////////////////////////

    if(context.data.hideSecondary === true){
      return (
        <ModalDialog
          title={context.data?.title ? context.data.title : '警告'}
          clickClose={() => close({...context.data, accept: false})}
          //
          primary={context.data?.primary ? context.data.primary : 'OK'}
          primaryClass={context.data?.primaryClass || primaryClass}
          clickPrimary={() => close({...context.data, accept: true})}
        >
          <div className="contents" style={context.data.style}>
            <div className="form-item">
              <label>
                {context.data?.description ? context.data.description : '警告'}
              </label>
            </div>
          </div>
        </ModalDialog>
      );

    }

    return (
      <ModalDialog
        title={context.data?.title ? context.data.title : '警告'}
        clickClose={() => close({...context.data, accept: false})}
        //
        primary={context.data?.primary ? context.data.primary : 'OK'}
        primaryClass={context.data?.primaryClass || primaryClass}
        clickPrimary={() => close({...context.data, accept: true})}
        //
        secondary={context.data?.secondary ? context.data.secondary : 'キャンセル'}
        secondaryClass={context.data?.secondaryClass}
        clickSecondary={() => close({...context.data, accept: false})}
      >
        <div className="contents" style={context.data.style}>
          <div className="form-item">
            <label>
              {context.data?.description ? context.data.description : '警告'}
            </label>
          </div>
        </div>
      </ModalDialog>
    );
  };

  return createDialog<T>(AlertDialog);
};

const AlertDialog = createAlertDialog();

export { createAlertDialog, AlertDialogContext, AlertDialog, AlertKind };
