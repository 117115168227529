type SetStateParam<T> = (o:T) => T;
type StateParam<T> = T | SetStateParam<T>;

////////////////////////////////////////////////////////////////////////////////

const resolveStateParam = <T>(value: StateParam<T>, oldValue: T): T => {
  if(typeof value === 'function'){
    return (value as SetStateParam<T>)(oldValue);
  }
  return value;
};

////////////////////////////////////////////////////////////////////////////////

export { StateParam, SetStateParam, resolveStateParam };