import { CSS } from '../../lib/styleToString';
import { Theme/*, Colors*/ } from './defaultTheme';
import { inputElementCss } from './element/inputElement';
import { labelElementCss } from './element/labelElement';
import { buttonElementCss } from './element/buttonElement';

const formCss = (theme: Theme): CSS => {
  return {
    ...labelElementCss(theme),
    ...inputElementCss(theme),
    ...buttonElementCss(theme),
  };
};

const utilCss = (theme: Theme): CSS => {

  return {
    '.form-item, .form-item-no-margin': {
      position: 'relative',
      margin: '1.0rem 0',
      ...formCss(theme)
    },
    '.form-item-no-margin': {
      margin: '0 0',
    },

    ////////////////////////////////////////////////////////////////////////////
    // color

    '.primary': {
      color: theme.color.primary,
    },
    'button.primary, input[type="button"].primary': {
      color: theme.color.primaryText,
      border: 'none',
      backgroundColor: theme.color.primary,
      '&:hover':{
        color: theme.color.primaryText,
        border: 'none',
        backgroundColor: theme.color.primaryHover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },
    'button.primary-outline, input[type="button"].primary-outline': {
      color: theme.color.primary,
      border: `1px solid ${theme.color.primary}`,
      backgroundColor: theme.color.primaryText,
      '&:hover':{
        border: `1px solid ${theme.color.primary}`,
        color: theme.color.primaryText,
        backgroundColor: theme.color.primaryHover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },
    'button.primary-light-outline, input[type="button"].primary-light-outline': {
      color: theme.color.primary,
      border: `1px solid ${theme.color.primary}`,
      backgroundColor: theme.color.primaryText,
      '&:hover':{
        border: `1px solid ${theme.color.primary}`,
        color: theme.color.primary,
        backgroundColor: `${theme.color.primaryHover}44`,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },
    'button.primary-light, input[type="button"].primary-light': {
      color: theme.color.primary,
      border: `1px solid ${theme.color.primary}`,
      backgroundColor: `${theme.color.primaryHover}44`,
      '&:hover':{
        border: `1px solid ${theme.color.primary}`,
        color: theme.color.primary,
        //backgroundColor: theme.color.primaryText,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

    '.alert': {
      color: theme.color.alert,
    },
    'button.alert, input[type="button"].alert': {
      color: theme.color.alertText,
      border: 'none',
      backgroundColor: theme.color.alert,
      '&:hover':{
        color: theme.color.alertText,
        border: 'none',
        backgroundColor: theme.color.alertHover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },
    'button.alert-outline, input[type="button"].alert-outline': {
      color: theme.color.alert,
      border: `1px solid ${theme.color.alert}`,
      backgroundColor: theme.color.alertText,
      '&:hover':{
        color: theme.color.alertText,
        border: `1px solid ${theme.color.alert}`,
        backgroundColor: theme.color.alertHover,
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

    'button.yellow-button, input[type="button"].yellow-button': {
      color: 'black',
      border: 'none',
      backgroundColor: '#FFCC00',
      '&:hover':{
        color: 'black',
        border: 'none',
        backgroundColor: '#FF9933',
      },
      '&:disabled':{
        border: `1px solid ${theme.inputButton.disabledBorderColor}`,
        color: `${theme.inputButton.disabledColor}`,
        backgroundColor: `${theme.inputButton.disabledBkColor}`,
        '&:hover':{
          cursor: 'not-allowed',
        }
      }
    },

    ////////////////////////////////////////////////////////////////////////////
    // util

    '.text-left': {
      textAlign: 'left',
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.text-right': {
      textAlign: 'right',
    },

    '.font-bold,.bold':{
      fontWeight: 'bold',
    },
    '.font-normal':{
      fontWeight: 'normal',
    },

    '.clickable,.pointer': {
      cursor: 'pointer'
    },

    '.shadow': {
      filter: 'drop-shadow(5px 5px 5px rgba(0,0,0,0.2))',
    },

    '.inline': {
      display: 'inline-block',
    },

    '.padding8': {
      padding: '8px',
    },

    ////////////////////////////////////////////////////////////////////////////

  };
};

const revuUiTheme = (theme: Theme): CSS => {
  return {
    '.revu-ui' : utilCss(theme),
  };
};

export { revuUiTheme };