import {
  assert,
  useCss,
  useEffect,
  useMemo,
  addListener,
  ListenInfo,
  useRef,
  Ref,
  shortid,
  useCallback,
  useEffectOnce,
} from '../..';
import { useLayer, Layer, createLayer } from './useLayer';

////////////////////////////////////////////////////////////////////////////////

const PopUpLayer = createLayer(300000);

////////////////////////////////////////////////////////////////////////////////

const getParentNode = (marker: Element): Node | null => {
  if(!marker){
    return null;
  }
  return marker.previousSibling;
};

////////////////////////////////////////////////////////////////////////////////

const isChild = (check: Node | null, parent: Node): boolean => {
  while(check){
    if (check === parent){
      return true;
    }
    check = check.parentNode;
  }
  return false;
};

////////////////////////////////////////////////////////////////////////////////
// PopUpChild
////////////////////////////////////////////////////////////////////////////////

interface PopUpChildParams {
  children: JSX.Children,
  position?: 'left' | 'right' | 'top' | 'bottom',
  markerElement: Ref<HTMLElement>,
  offsetX?: number,
  offsetY?: number,
  popupRef: Ref<HTMLElement>,
  fromUpload?: boolean,
  fromModal?: boolean,
  fromAbout?: boolean,
}

////////////////////////////////////////////////////////////////////////////////

const PopUpChild = ({
  children,
  position,
  markerElement,
  offsetX,
  offsetY,
  popupRef,
  fromUpload,
  fromModal,
  fromAbout,
}: PopUpChildParams) => {

  //////////////////////////////////////////////////////////////////////////////

  const popupRefInner = useRef<HTMLElement>();

  const style = useMemo<{position: string, left: string, top: string, width?: string}>(() => {
    if(fromUpload || fromModal || fromAbout){
      return {position: 'absolute', left: '-10000px', top: '-10000px'};
    }else{
      return {position: 'absolute', left: '-10000px', top: '-10000px', width: '-10000px'};
    }
  }, []);

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    //
    if(!popupRefInner.current || !markerElement?.current){
      return;
    }
    const parent = getParentNode(markerElement.current);
    if(!parent){
      return;
    }

    // window size
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // client size
    const clientRect = popupRefInner.current.getBoundingClientRect();

    // parent size
    assert(parent);
    const range = document.createRange();
    range.selectNode(parent);
    const parentClientRect = range.getBoundingClientRect();

    let x = 0;
    let y = 0;
    switch(position ?? 'bottom'){
      case 'right':{
        x = window.scrollX + parentClientRect.right;
        y = window.scrollY + parentClientRect.top;
      }break;
      case 'left':{
        x = window.scrollX + parentClientRect.left - clientRect.width;
        y = window.scrollY + parentClientRect.top;
      }break;
      case 'bottom':{
        x = window.scrollX + parentClientRect.left;
        y = window.scrollY + parentClientRect.bottom;
      }break;
      case 'top':{
        x = window.scrollX + parentClientRect.left;
        y = window.scrollY + parentClientRect.top - clientRect.height;
      }break;
    }

    const ofsX = (offsetX || 0);
    const ofsY = (offsetY || 0);
    x += ofsX;
    y += ofsY;

    let shiftX = 0;
    let shiftY = 0;
    if(x + clientRect.width >= width){
      shiftX = width - (x + clientRect.width);
    }
    if(x <= 0){
      shiftX = -x;
    }
    if(y + clientRect.height >= height){
      shiftY = height - (y + clientRect.height);
    }
    if(y <= 0){
      shiftY = -y;
    }
    //
    const left = `${x + shiftX}px`;
    const top = `${y + shiftY}px`;
    // Popup width
    if(fromUpload){
      const popupWidth  = `${width - (x + shiftX) - 40}px`;
      popupRefInner.current.setAttribute('style', `position:absolute;left:${left};top:${top};width:${popupWidth}`);
      style.width = popupWidth;
    }else if(fromModal){
      const popupWidth  = `600px`;
      popupRefInner.current.setAttribute('style', `position:absolute;left:${left};top:${top};width:${popupWidth}`);
      style.width = popupWidth;
    }else if(fromAbout){
      const popupWidth  = `300px`;
      const right = `40px`;
      popupRefInner.current.setAttribute('style', `position:absolute;right:${right};top:${top};width:${popupWidth}`);
      style.width = popupWidth;
    }else{
      popupRefInner.current.setAttribute('style', `position:absolute;left:${left};top:${top};`);
    }
    //
    style.left = left;
    style.top = top;
    //
  }, [markerElement, offsetX, offsetY, position, style]);

  return <div
    style={style}
    ref={[popupRef, popupRefInner]}
  >
    {children}
  </div>;
};

////////////////////////////////////////////////////////////////////////////////
// PopUp
////////////////////////////////////////////////////////////////////////////////

interface PopUpParams {
  children: JSX.Children;
  layer?: Layer;
  //
  position?: 'left' | 'right' | 'top' | 'bottom',
  offsetX?: number;
  offsetY?: number;
  //
  clickClose?: boolean;
  clickOuterClose?: boolean;
  autoShow?: boolean;
  autoHide?: boolean;
  //
  event?: string;
  //
  fromUpload?: boolean;
  fromModal?: boolean;
  fromAbout?: boolean;
}

////////////////////////////////////////////////////////////////////////////////

const PopUp = ({
  children,
  layer,
  //
  position,
  offsetX,
  offsetY,
  //
  clickClose,
  clickOuterClose,
  autoShow,
  autoHide,
  //
  event,
  //
  fromUpload = false,
  fromModal = false,
  fromAbout = false,
}: PopUpParams) => {

  //////////////////////////////////////////////////////////////////////////////

  useCss({
    '.revu2-popup-insert-marker' : {
      display: 'none'
    }
  });

  //////////////////////////////////////////////////////////////////////////////

  const uniqueId = useMemo(() => `popup-${shortid(6)}`, []);
  const markerElement = useRef<HTMLElement>();
  const popupRef = useRef<HTMLElement>();

  //////////////////////////////////////////////////////////////////////////////

  const state = useMemo<{show: boolean}>(() => {return {show: false};}, []);

  //////////////////////////////////////////////////////////////////////////////

  const layerRender = useLayer(layer ?? PopUpLayer);

  //////////////////////////////////////////////////////////////////////////////
  // show func

  const showPopUp = useCallback((show: boolean, forceUpdate = true) => {
    state.show = show;
    if(show){
      const child = <PopUpChild
        key={uniqueId}
        position={position}
        popupRef={popupRef}
        offsetX={offsetX}
        offsetY={offsetY}
        markerElement={markerElement}
        fromUpload={fromUpload}
        fromModal={fromModal}
        fromAbout={fromAbout}
      >
        {children}
      </PopUpChild>;
      layerRender(uniqueId, child, forceUpdate);
    }else{
      layerRender(uniqueId, undefined, forceUpdate);
    }
    //
    // 毎回生成！ !important
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, undefined);

  showPopUp(state.show, false);

  //////////////////////////////////////////////////////////////////////////////
  // purge

  useEffectOnce(() => {
    return () => {
      layerRender(uniqueId, undefined);
    };
  });

  //////////////////////////////////////////////////////////////////////////////
  // events

  useEffect(() => {
    //
    const parent = getParentNode(markerElement.current);
    if(!parent){
      return;
    }

    const closer: ListenInfo[] = [];

    if(event){
      // open event
      const ec = addListener(parent, event, (e: Event) => {
        e.stopPropagation();
        showPopUp(!state.show);
      });
      closer.push(ec);
    }

    if(clickOuterClose !== false){
      // window click
      const wc = addListener(window, 'click', (e: Event) => {
        if(!state.show){
          return;
        }
        e.stopPropagation();
        if(isChild(e.target as Node, parent)){
          return;
        }
        if(isChild(e.target as Element, popupRef.current)){
          return;
        }
        showPopUp(false);
      });
      closer.push(wc);
    }

    // auto show
    if(autoShow){
      const po = addListener(parent, 'pointerenter', () => {
        if(state.show){
          return;
        }
        showPopUp(true);
      });
      closer.push(po);
    }

    // auto hide
    if(autoHide !== false){
      const pl = addListener(parent, 'pointerleave', (e: Event) => {
        if(!state.show){
          return;
        }
        const pe = (e as PointerEvent);
        const elmParent: Element | ParentNode | null = document.elementFromPoint(pe.clientX, pe.clientY);
        //
        if(isChild(elmParent, popupRef.current)){
          const plChild = addListener(popupRef.current, 'pointerleave', (_e: Event) => {
            showPopUp(false);
          });
          closer.push(plChild);
          return;
        }
        showPopUp(false);
      });
      //
      closer.push(pl);

    }

    if(clickClose !== false){
      window.requestAnimationFrame(() => {
        if(!state.show){
          return;
        }
        if(popupRef.current){
          const clEv = addListener(popupRef.current, 'click', (e) => {
            //
            e.stopPropagation();
            showPopUp(false);
          });
          closer.push(clEv);
        }
      });
    }

    return () => {
      closer.map(c => c.remove());
    };

  }, [autoHide, autoShow, clickClose, clickOuterClose, event, showPopUp, state.show]);

  //////////////////////////////////////////////////////////////////////////////

  return <div className="revu2-popup-insert-marker" ref={markerElement}></div>;

};

export { PopUp, PopUpLayer };