import {
  assert,
  margeProps,
  ClassProps,
  useCss,
  useState,
  useLayoutEffect,
  useRef,
  StyleProps,
  useCallback,
  useMemo
} from '../..';

const DEFAULT_RESIZE_BAR_SIZE = 8;
const DEFAULT_RESIZE_BAR_COLOR = '#00000000';
const DEFAULT_RESIZE_BAR_COLOR_HOVER = '#FFFFFF44';

////////////////////////////////////////////////////////////////////////////////

const HSplitContainer = ({children}: {children: JSX.Children}) => {
  useCss({
    '.h-split-container':{
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'relative',
      flexWrap: 'nowrap',
    }
  });
  return <div className="h-split-container">{children}</div>;
};

////////////////////////////////////////////////////////////////////////////////

type HResizeBarInfo = {
  position: 'left' | 'right',
  width?: number,
  color?: string,
  hoverColor?: string
};

type HSplitParams = {
  width?: number;
  minWidth?: number;
  resizeBar?: HResizeBarInfo;
  children: JSX.Children;
  //
  id?: string;
  class?: ClassProps;
  className?: ClassProps;
  style?: StyleProps;
  //
};

////////////////////////////////////////////////////////////////////////////////

const HSplit = ({ width, minWidth, resizeBar, children, ...inherited }: HSplitParams) => {

  useCss({
    '.h-split' : {
      zIndex: '0',
      position: 'relative',
      height: '100%',
    },
    '.bar' : {
      zIndex: '1',
      position: 'absolute',
      height: '100%',
      '&.left' : {
        top: '0px',
        left: '0px',
      },
      '&.right' : {
        top: '0px',
        right: '0px',
      },
      '&:hover': {
        cursor: 'ew-resize',
      }
    },
  });

  //////////////////////////////////////////////////////////////////////////////

  const [hWidth, setHWidth] = useState<number | undefined>(width);

  useLayoutEffect(() => {
    if(width){
      setHWidth(width);
    }
  }, [width]);

  //////////////////////////////////////////////////////////////////////////////

  const resizeInfo = useRef({active: false, offset: 0, width: 0});
  const div = useRef<HTMLDivElement>();

  const onPointerDown = useCallback((e: PointerEvent) => {
    if(resizeBar && hWidth !== undefined){
      resizeInfo.current.active = true;
      resizeInfo.current.width = hWidth;
      resizeInfo.current.offset = e.screenX;
      (e.target as HTMLElement).setPointerCapture(e.pointerId);
    }
  }, [hWidth, resizeBar]);

  const onPointerMove = useCallback((e: PointerEvent) => {
    if(!resizeInfo.current.active){
      return;
    }
    //
    const offset = e.screenX - resizeInfo.current.offset;
    const newWidth = resizeInfo.current.width + ((resizeBar?.position === 'right')? offset : -offset );
    //
    assert(width);
    if(newWidth > (minWidth ?? width)){
      setHWidth(newWidth);
    }
  }, [minWidth, resizeBar?.position, width]);

  const onPointerUp = useCallback((e: PointerEvent) => {
    if(!resizeInfo.current.active){
      return;
    }
    //
    const rect = div.current.getBoundingClientRect();
    setHWidth(rect.width);
    //
    resizeInfo.current.active = false;
    (e.target as HTMLElement).releasePointerCapture(e.pointerId);
    //
  }, []);

  //////////////////////////////////////////////////////////////////////////////

  const barColor = resizeBar?.color ?? DEFAULT_RESIZE_BAR_COLOR;
  const [backgroundColor, setBackgroundColor] = useState<string>(barColor);

  const onMouseEnter = useCallback((_e: PointerEvent) => {
    const barHoverColor = resizeBar?.hoverColor ?? DEFAULT_RESIZE_BAR_COLOR_HOVER;
    setBackgroundColor(barHoverColor);
  }, [resizeBar?.hoverColor]);

  const onMouseLeave = useCallback((_e: PointerEvent) => {
    setBackgroundColor(barColor);
  }, [barColor]);

  //////////////////////////////////////////////////////////////////////////////

  const splitStyle = useMemo(() => {
    return (hWidth !== undefined)?
      {flexBasis: `${Math.floor(hWidth)}px`}
      :
      {flexGrow:'1'};
  }, [hWidth]);

  const splitChildStyle = useMemo(() => {
    return (hWidth !== undefined)?
      {width: `${Math.floor(hWidth)}px`, height: '100%'}
      :
      {width: 'auto', height: '100%'};
  }, [hWidth]);

  const inheritedProps = useMemo(() => {
    return margeProps(inherited, {style: splitChildStyle});
  }, [inherited, splitChildStyle]);

  return (
    <div
      className="h-split"
      style={splitStyle}
      ref={div}
    >
      <div {...inheritedProps}>
        {children}
        {
          (resizeBar && width !== undefined)?
            <div
              className={`bar ${resizeBar.position}`}
              style={{
                width: `${resizeBar?.width ?? DEFAULT_RESIZE_BAR_SIZE}px`,
                backgroundColor,
              }}
              onPointerDown={onPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={onPointerUp}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            ></div>
            :
            <></>
        }
      </div>
    </div>
  );

};

export { HSplitContainer, HSplit };