export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: Date; output: Date };
  DateTime: { input: Date; output: Date };
  JSON: {
    input: { [key: string]: unknown };
    output: { [key: string]: unknown };
  };
};

export type AppInfo = {
  __typename?: "AppInfo";
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AppInfoCreate = {
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type AppInfoUpdate = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type AppUser = {
  __typename?: "AppUser";
  active: Scalars["Boolean"]["output"];
  company?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  kind: AppUserKind;
  name?: Maybe<Scalars["String"]["output"]>;
  signedInAt?: Maybe<Scalars["DateTime"]["output"]>;
  sub?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  userAgreedTermVersion?: Maybe<Scalars["String"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type AppUserCreate = {
  active: Scalars["Boolean"]["input"];
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  kind: AppUserKind;
  name?: InputMaybe<Scalars["String"]["input"]>;
  signedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sub?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userAgreedTermVersion?: InputMaybe<Scalars["String"]["input"]>;
};

export enum AppUserKind {
  BIMSTOK_ADMIN = "BIMSTOK_ADMIN",
  USER = "USER",
}

export type AppUserUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<AppUserKind>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  signedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sub?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userAgreedTermVersion?: InputMaybe<Scalars["String"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ApprovedStatus {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  FIXED = "FIXED",
  WITHDRAW = "WITHDRAW",
}

export type Badge = {
  __typename?: "Badge";
  inspection?: Maybe<BadgeKind>;
  issue?: Maybe<BadgeKind>;
  report?: Maybe<BadgeKind>;
};

export type BadgeKind = {
  __typename?: "BadgeKind";
  approved?: Maybe<Scalars["Int"]["output"]>;
  draft?: Maybe<Scalars["Int"]["output"]>;
  fixed?: Maybe<Scalars["Int"]["output"]>;
  withdraw?: Maybe<Scalars["Int"]["output"]>;
};

export type CustomForm = {
  __typename?: "CustomForm";
  active: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  customform_id: Scalars["String"]["output"];
  form: Scalars["JSON"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CustomFormCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  form: Scalars["JSON"]["input"];
  kind?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CustomFormUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customform_id?: InputMaybe<Scalars["String"]["input"]>;
  form?: InputMaybe<Scalars["JSON"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FORM_VALUE = {
  __typename?: "FORM_VALUE";
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FORM_VALUECreate = {
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type FORM_VALUEUpdate = {
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Facilities = {
  __typename?: "Facilities";
  active: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities_id: Scalars["String"]["output"];
  ifcObject?: Maybe<Scalars["JSON"]["output"]>;
  interval?: Maybe<Scalars["String"]["output"]>;
  level1: Scalars["String"]["output"];
  level2: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  priority?: Maybe<Scalars["String"]["output"]>;
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  standard?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FacilitiesCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ifcObject?: InputMaybe<Scalars["JSON"]["input"]>;
  interval?: InputMaybe<Scalars["String"]["input"]>;
  level1: Scalars["String"]["input"];
  level2: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FacilitiesDocuments = {
  __typename?: "FacilitiesDocuments";
  document_id: Scalars["String"]["output"];
  facilities_documents_id: Scalars["Int"]["output"];
  facilities_id: Scalars["String"]["output"];
  inspection?: Maybe<Inspection>;
  issue?: Maybe<Issue>;
  issueResponse?: Maybe<IssueResponse>;
  plan?: Maybe<Plan>;
  project_id: Scalars["String"]["output"];
  report?: Maybe<Report>;
  updated: Scalars["DateTime"]["output"];
};

export type FacilitiesDocumentsCreate = {
  document_id: Scalars["String"]["input"];
  facilities_id: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  updated: Scalars["DateTime"]["input"];
};

export type FacilitiesDocumentsUpdate = {
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  facilities_documents_id?: InputMaybe<Scalars["Int"]["input"]>;
  facilities_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  updated?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FacilitiesUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities_id?: InputMaybe<Scalars["String"]["input"]>;
  ifcObject?: InputMaybe<Scalars["JSON"]["input"]>;
  interval?: InputMaybe<Scalars["String"]["input"]>;
  level1?: InputMaybe<Scalars["String"]["input"]>;
  level2?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum FileKind {
  DOCUMENT = "DOCUMENT",
  DRAWING = "DRAWING",
  IFC = "IFC",
  INSPECTION = "INSPECTION",
  ISSUE = "ISSUE",
  ISSUE_RESPONSE = "ISSUE_RESPONSE",
  REPORT = "REPORT",
}

export type Files = {
  __typename?: "Files";
  active: Scalars["Boolean"]["output"];
  contents_id: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  file_id: Scalars["String"]["output"];
  ifcConverted?: Maybe<Scalars["Boolean"]["output"]>;
  is_directory: Scalars["Boolean"]["output"];
  kind: FileKind;
  name: Scalars["String"]["output"];
  owner_id?: Maybe<Scalars["String"]["output"]>;
  parent: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  viruses?: Maybe<Scalars["Boolean"]["output"]>;
  virusesInfo?: Maybe<Scalars["JSON"]["output"]>;
};

export type FilesCreate = {
  active: Scalars["Boolean"]["input"];
  contents_id: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ifcConverted?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_directory: Scalars["Boolean"]["input"];
  kind: FileKind;
  name: Scalars["String"]["input"];
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  parent: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  viruses?: InputMaybe<Scalars["Boolean"]["input"]>;
  virusesInfo?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type FilesUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  contents_id?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  file_id?: InputMaybe<Scalars["String"]["input"]>;
  ifcConverted?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_directory?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<FileKind>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  parent?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  viruses?: InputMaybe<Scalars["Boolean"]["input"]>;
  virusesInfo?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type Inspection = {
  __typename?: "Inspection";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  files?: Maybe<Array<Maybe<Files>>>;
  formData?: Maybe<Scalars["JSON"]["output"]>;
  hasIssues?: Maybe<Scalars["Int"]["output"]>;
  inspection_id: Scalars["String"]["output"];
  issues?: Maybe<Array<Maybe<Issue>>>;
  kind: Scalars["String"]["output"];
  memo?: Maybe<Array<Maybe<Memo>>>;
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: ApprovedStatus;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type InspectionCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  issues?: InputMaybe<Array<InputMaybe<IssueUpdate>>>;
  kind: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status: ApprovedStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export type InspectionKind = {
  __typename?: "InspectionKind";
  active: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  inspection_kind_id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type InspectionKindCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InspectionKindUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  inspection_kind_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InspectionUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  inspection_id?: InputMaybe<Scalars["String"]["input"]>;
  issues?: InputMaybe<Array<InputMaybe<IssueUpdate>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ApprovedStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type IsUsedIfcFile = {
  __typename?: "IsUsedIfcFile";
  detailsFacilities?: Maybe<Array<Maybe<IsUsedIfcFileResult>>>;
  detailsIssue?: Maybe<Array<Maybe<IsUsedIfcFileResult>>>;
  isUsedFacilities?: Maybe<Scalars["Boolean"]["output"]>;
  isUsedIssue?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IsUsedIfcFileResult = {
  __typename?: "IsUsedIfcFileResult";
  id: Scalars["String"]["output"];
  kind: Scalars["String"]["output"];
};

export type Issue = {
  __typename?: "Issue";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  facility?: Maybe<Scalars["String"]["output"]>;
  files?: Maybe<Array<Maybe<Files>>>;
  formData?: Maybe<Scalars["JSON"]["output"]>;
  issueStatus?: Maybe<Scalars["String"]["output"]>;
  issue_id: Scalars["String"]["output"];
  issue_points?: Maybe<Array<Maybe<IssuePoint>>>;
  kind?: Maybe<Scalars["String"]["output"]>;
  memo?: Maybe<Array<Maybe<Memo>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  owner?: Maybe<Owner>;
  owner_id?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  project_id: Scalars["String"]["output"];
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<ApprovedStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type IssueCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  facility?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  issueStatus?: InputMaybe<Scalars["String"]["input"]>;
  issue_points?: InputMaybe<Array<InputMaybe<IssuePointUpdate>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ApprovedStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export type IssueKind = {
  __typename?: "IssueKind";
  active: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  issuekind_id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IssueKindCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssueKindUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  issuekind_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssuePoint = {
  __typename?: "IssuePoint";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  ifcFileInfo?: Maybe<Files>;
  issuepoint_id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  owner_id?: Maybe<Scalars["String"]["output"]>;
  pointsData?: Maybe<Scalars["JSON"]["output"]>;
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IssuePointCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  pointsData?: InputMaybe<Scalars["JSON"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssuePointUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  issuepoint_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  pointsData?: InputMaybe<Scalars["JSON"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssuePriority = {
  __typename?: "IssuePriority";
  active: Scalars["Boolean"]["output"];
  alert: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  issuepriority_id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IssuePriorityCreate = {
  active: Scalars["Boolean"]["input"];
  alert: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssuePriorityUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  alert?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  issuepriority_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssueResponse = {
  __typename?: "IssueResponse";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  files?: Maybe<Array<Maybe<Files>>>;
  issueresponse_id: Scalars["String"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  project_id: Scalars["String"]["output"];
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type IssueResponseCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export type IssueResponseUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  issueresponse_id?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type IssueStatus = {
  __typename?: "IssueStatus";
  active: Scalars["Boolean"]["output"];
  alert: Scalars["Boolean"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  issuestatus_id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IssueStatusCreate = {
  active: Scalars["Boolean"]["input"];
  alert: Scalars["Boolean"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssueStatusUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  alert?: InputMaybe<Scalars["Boolean"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  issuestatus_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IssueUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  facility?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  issueStatus?: InputMaybe<Scalars["String"]["input"]>;
  issue_id?: InputMaybe<Scalars["String"]["input"]>;
  issue_points?: InputMaybe<Array<InputMaybe<IssuePointUpdate>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ApprovedStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Log = {
  __typename?: "Log";
  browser?: Maybe<Scalars["JSON"]["output"]>;
  cpu?: Maybe<Scalars["JSON"]["output"]>;
  device?: Maybe<Scalars["JSON"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  engine?: Maybe<Scalars["JSON"]["output"]>;
  error?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["Int"]["output"];
  ip?: Maybe<Scalars["String"]["output"]>;
  kind: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
  method?: Maybe<Scalars["String"]["output"]>;
  os?: Maybe<Scalars["JSON"]["output"]>;
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  sub?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["JSON"]["output"]>;
};

export type LogCreate = {
  browser?: InputMaybe<Scalars["JSON"]["input"]>;
  cpu?: InputMaybe<Scalars["JSON"]["input"]>;
  device?: InputMaybe<Scalars["JSON"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  engine?: InputMaybe<Scalars["JSON"]["input"]>;
  error?: InputMaybe<Scalars["JSON"]["input"]>;
  ip?: InputMaybe<Scalars["String"]["input"]>;
  kind: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
  method?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Scalars["JSON"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sub?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type LogUpdate = {
  browser?: InputMaybe<Scalars["JSON"]["input"]>;
  cpu?: InputMaybe<Scalars["JSON"]["input"]>;
  device?: InputMaybe<Scalars["JSON"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  engine?: InputMaybe<Scalars["JSON"]["input"]>;
  error?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  ip?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  method?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Scalars["JSON"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sub?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type Memo = {
  __typename?: "Memo";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  kind: Scalars["String"]["output"];
  memo_id: Scalars["Int"]["output"];
  owner_id: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type MemoCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  kind: Scalars["String"]["input"];
  owner_id: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export enum MemoKind {
  APPROVED = "APPROVED",
  APPROVED_OVERWRITE = "APPROVED_OVERWRITE",
  CANCEL_FIXED = "CANCEL_FIXED",
  FIXED = "FIXED",
  RETRY_FIXED = "RETRY_FIXED",
  WITHDRAW = "WITHDRAW",
}

export type MemoUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  memo_id?: InputMaybe<Scalars["Int"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  AppUser_removePrivileges?: Maybe<AppUser>;
  AppUser_update?: Maybe<AppUser>;
  CustomForm_create?: Maybe<CustomForm>;
  CustomForm_update?: Maybe<CustomForm>;
  Facilities_create?: Maybe<Facilities>;
  Facilities_update?: Maybe<Facilities>;
  Files_create?: Maybe<Files>;
  Files_remove?: Maybe<Array<Maybe<Files>>>;
  Files_update?: Maybe<Files>;
  InspectionKind_create?: Maybe<InspectionKind>;
  InspectionKind_update?: Maybe<InspectionKind>;
  Inspection_create?: Maybe<Inspection>;
  Inspection_update?: Maybe<Inspection>;
  IssueKind_create?: Maybe<IssueKind>;
  IssueKind_update?: Maybe<IssueKind>;
  IssuePoint_create?: Maybe<IssuePoint>;
  IssuePoint_update?: Maybe<IssuePoint>;
  IssuePriority_create?: Maybe<IssuePriority>;
  IssuePriority_update?: Maybe<IssuePriority>;
  IssueResponse_create?: Maybe<IssueResponse>;
  IssueResponse_update?: Maybe<IssueResponse>;
  IssueStatus_create?: Maybe<IssueStatus>;
  IssueStatus_update?: Maybe<IssueStatus>;
  Issue_create?: Maybe<Issue>;
  Issue_update?: Maybe<Issue>;
  Memo_create?: Maybe<Memo>;
  Plan_create?: Maybe<Plan>;
  Plan_update?: Maybe<Plan>;
  ProjectUser_create?: Maybe<ProjectUser>;
  ProjectUser_update?: Maybe<ProjectUser>;
  Project_create?: Maybe<Project>;
  Project_remove?: Maybe<Scalars["Boolean"]["output"]>;
  Project_update?: Maybe<Project>;
  ReportKind_create?: Maybe<ReportKind>;
  ReportKind_update?: Maybe<ReportKind>;
  Report_create?: Maybe<Report>;
  Report_update?: Maybe<Report>;
  TermsCustom_create?: Maybe<TermsCustom>;
  TermsCustom_update?: Maybe<TermsCustom>;
  WorkspaceUser_update?: Maybe<WorkspaceUser>;
  Workspace_create?: Maybe<Workspace>;
  Workspace_remove?: Maybe<Scalars["Boolean"]["output"]>;
  Workspace_update?: Maybe<Workspace>;
};

export type MutationAppUser_removePrivilegesArgs = {
  appUser?: InputMaybe<AppUserUpdate>;
};

export type MutationAppUser_updateArgs = {
  appUser?: InputMaybe<AppUserUpdate>;
};

export type MutationCustomForm_createArgs = {
  customForm: CustomFormCreate;
};

export type MutationCustomForm_updateArgs = {
  customForm: CustomFormUpdate;
};

export type MutationFacilities_createArgs = {
  facilities: FacilitiesCreate;
};

export type MutationFacilities_updateArgs = {
  facilities: FacilitiesUpdate;
};

export type MutationFiles_createArgs = {
  file: FilesCreate;
};

export type MutationFiles_removeArgs = {
  file_id: Scalars["String"]["input"];
};

export type MutationFiles_updateArgs = {
  file: FilesUpdate;
};

export type MutationInspectionKind_createArgs = {
  inspectionKind: InspectionKindCreate;
};

export type MutationInspectionKind_updateArgs = {
  inspectionKind: InspectionKindUpdate;
};

export type MutationInspection_createArgs = {
  inspection: InspectionCreate;
};

export type MutationInspection_updateArgs = {
  inspection: InspectionUpdate;
};

export type MutationIssueKind_createArgs = {
  issueKind: IssueKindCreate;
};

export type MutationIssueKind_updateArgs = {
  issueKind: IssueKindUpdate;
};

export type MutationIssuePoint_createArgs = {
  issuepoint: IssuePointCreate;
};

export type MutationIssuePoint_updateArgs = {
  issuepoint: IssuePointUpdate;
};

export type MutationIssuePriority_createArgs = {
  issuePriority: IssuePriorityCreate;
};

export type MutationIssuePriority_updateArgs = {
  issuePriority: IssuePriorityUpdate;
};

export type MutationIssueResponse_createArgs = {
  issueResponse: IssueResponseCreate;
};

export type MutationIssueResponse_updateArgs = {
  issueResponse: IssueResponseUpdate;
};

export type MutationIssueStatus_createArgs = {
  issueStatus: IssueStatusCreate;
};

export type MutationIssueStatus_updateArgs = {
  issueStatus: IssueStatusUpdate;
};

export type MutationIssue_createArgs = {
  issue: IssueCreate;
};

export type MutationIssue_updateArgs = {
  issue: IssueUpdate;
};

export type MutationMemo_createArgs = {
  memo: MemoCreate;
};

export type MutationPlan_createArgs = {
  plan: PlanCreate;
};

export type MutationPlan_updateArgs = {
  plan: PlanUpdate;
};

export type MutationProjectUser_createArgs = {
  email: Scalars["String"]["input"];
  kind: ProjectUserKind;
  mode: ProjectUserMode;
  project_id: Scalars["String"]["input"];
};

export type MutationProjectUser_updateArgs = {
  project_user: ProjectUserUpdate;
};

export type MutationProject_createArgs = {
  project: ProjectCreate;
};

export type MutationProject_removeArgs = {
  project_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationProject_updateArgs = {
  project: ProjectUpdate;
};

export type MutationReportKind_createArgs = {
  reportKind: ReportKindCreate;
};

export type MutationReportKind_updateArgs = {
  reportKind: ReportKindUpdate;
};

export type MutationReport_createArgs = {
  report: ReportCreate;
};

export type MutationReport_updateArgs = {
  report: ReportUpdate;
};

export type MutationTermsCustom_createArgs = {
  termsCustom: TermsCustomCreate;
};

export type MutationTermsCustom_updateArgs = {
  termsCustom: TermsCustomUpdate;
};

export type MutationWorkspaceUser_updateArgs = {
  workspace_user: WorkspaceUserUpdate;
};

export type MutationWorkspace_createArgs = {
  email: Scalars["String"]["input"];
  workspace: WorkspaceCreate;
};

export type MutationWorkspace_removeArgs = {
  workspace_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationWorkspace_updateArgs = {
  workspace: WorkspaceUpdate;
};

export enum OrderKind {
  APPROVED_STATUS = "APPROVED_STATUS",
  DATETIME = "DATETIME",
  INSPECTION_KIND = "INSPECTION_KIND",
  ISSUE_KIND = "ISSUE_KIND",
  ISSUE_PRIORITY = "ISSUE_PRIORITY",
  REPORT_KIND = "REPORT_KIND",
  UNDONE_KIND = "UNDONE_KIND",
}

export type Owner = {
  __typename?: "Owner";
  name?: Maybe<Scalars["String"]["output"]>;
  owner?: Maybe<Owner>;
  owner_id?: Maybe<Scalars["String"]["output"]>;
  project_id?: Maybe<Scalars["String"]["output"]>;
  target_id: Scalars["String"]["output"];
  target_kind: Scalars["String"]["output"];
};

export type Plan = {
  __typename?: "Plan";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  done?: Maybe<Scalars["Boolean"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  kind?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  plan_id: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type PlanCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  done?: InputMaybe<Scalars["Boolean"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export type PlanUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  done?: InputMaybe<Scalars["Boolean"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  plan_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Project = {
  __typename?: "Project";
  active: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  masterUpdatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  workspace_id: Scalars["String"]["output"];
};

export type ProjectCreate = {
  active: Scalars["Boolean"]["input"];
  address?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  masterUpdatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workspace_id: Scalars["String"]["input"];
};

export type ProjectUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  masterUpdatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workspace_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProjectUser = {
  __typename?: "ProjectUser";
  active: Scalars["Boolean"]["output"];
  company?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  kind?: Maybe<ProjectUserKind>;
  mode?: Maybe<ProjectUserMode>;
  name?: Maybe<Scalars["String"]["output"]>;
  project?: Maybe<Project>;
  project_id: Scalars["String"]["output"];
  projectuser_id: Scalars["Int"]["output"];
  removed?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user?: Maybe<AppUser>;
  userThumbnail?: Maybe<Scalars["String"]["output"]>;
  user_id: Scalars["String"]["output"];
  workspace_user?: Maybe<WorkspaceUser>;
};

export type ProjectUserCreate = {
  active: Scalars["Boolean"]["input"];
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<ProjectUserKind>;
  mode?: InputMaybe<ProjectUserMode>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  removed?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userThumbnail?: InputMaybe<Scalars["String"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export enum ProjectUserKind {
  PROJECT_ADMIN = "PROJECT_ADMIN",
  USER = "USER",
}

export enum ProjectUserMode {
  ADMIN = "ADMIN",
  USER = "USER",
}

export type ProjectUserUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<ProjectUserKind>;
  mode?: InputMaybe<ProjectUserMode>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  projectuser_id?: InputMaybe<Scalars["Int"]["input"]>;
  removed?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userThumbnail?: InputMaybe<Scalars["String"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  AppInfo_get?: Maybe<Scalars["JSON"]["output"]>;
  AppUser_create?: Maybe<AppUser>;
  AppUser_gets?: Maybe<Array<Maybe<AppUser>>>;
  Badge_get?: Maybe<Badge>;
  CustomForm_get?: Maybe<CustomForm>;
  CustomForm_gets?: Maybe<Array<Maybe<CustomForm>>>;
  FacilitiesDocuments_get?: Maybe<Array<Maybe<FacilitiesDocuments>>>;
  FacilitiesDocuments_gets?: Maybe<Array<Maybe<FacilitiesDocuments>>>;
  Facilities_get?: Maybe<Facilities>;
  Facilities_gets?: Maybe<Array<Maybe<Facilities>>>;
  Files_get?: Maybe<Array<Maybe<Files>>>;
  Files_gets?: Maybe<Array<Maybe<Files>>>;
  InspectionKind_get?: Maybe<InspectionKind>;
  InspectionKind_gets?: Maybe<Array<Maybe<InspectionKind>>>;
  Inspection_get?: Maybe<Inspection>;
  Inspection_gets?: Maybe<Array<Maybe<Inspection>>>;
  IsUsedIfcFile_isUsedIfcFile?: Maybe<IsUsedIfcFile>;
  IssueKind_get?: Maybe<IssueKind>;
  IssueKind_gets?: Maybe<Array<Maybe<IssueKind>>>;
  IssuePoint_get?: Maybe<IssuePoint>;
  IssuePoint_gets?: Maybe<Array<Maybe<IssuePoint>>>;
  IssuePriority_get?: Maybe<IssuePriority>;
  IssuePriority_gets?: Maybe<Array<Maybe<IssuePriority>>>;
  IssueResponse_get?: Maybe<IssueResponse>;
  IssueResponse_gets?: Maybe<Array<Maybe<IssueResponse>>>;
  IssueStatus_get?: Maybe<IssueStatus>;
  IssueStatus_gets?: Maybe<Array<Maybe<IssueStatus>>>;
  Issue_get?: Maybe<Issue>;
  Issue_gets?: Maybe<Array<Maybe<Issue>>>;
  Plan_get?: Maybe<Plan>;
  Plan_gets?: Maybe<Array<Maybe<Plan>>>;
  ProjectUser_get?: Maybe<ProjectUser>;
  ProjectUser_getProjectUsers?: Maybe<Array<Maybe<ProjectUser>>>;
  ProjectUser_gets?: Maybe<Array<Maybe<ProjectUser>>>;
  Project_get?: Maybe<Project>;
  ReportKind_get?: Maybe<ReportKind>;
  ReportKind_gets?: Maybe<Array<Maybe<ReportKind>>>;
  Report_get?: Maybe<Report>;
  Report_gets?: Maybe<Array<Maybe<Report>>>;
  Storage_get?: Maybe<Storage>;
  Storage_gets?: Maybe<Storage>;
  TermsCustom_get?: Maybe<TermsCustom>;
  TermsCustom_gets?: Maybe<Array<Maybe<TermsCustom>>>;
  TermsDefault_gets?: Maybe<Array<Maybe<TermsDefault>>>;
  WorkspaceUser_get?: Maybe<WorkspaceUser>;
  WorkspaceUser_getWorkspaceUsers?: Maybe<Array<Maybe<WorkspaceUser>>>;
  WorkspaceUser_gets?: Maybe<Array<Maybe<WorkspaceUser>>>;
  Workspace_get?: Maybe<Workspace>;
  Workspace_gets?: Maybe<Array<Maybe<Workspace>>>;
};

export type QueryAppUser_createArgs = {
  appUser?: InputMaybe<AppUserCreate>;
};

export type QueryBadge_getArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryCustomForm_getArgs = {
  customform_id: Scalars["String"]["input"];
};

export type QueryCustomForm_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryFacilitiesDocuments_getArgs = {
  facilities_id: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
};

export type QueryFacilitiesDocuments_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryFacilities_getArgs = {
  facilities_id: Scalars["String"]["input"];
};

export type QueryFacilities_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryFiles_getArgs = {
  file_id: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
};

export type QueryFiles_getsArgs = {
  kind: FileKind;
  parent: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
};

export type QueryInspectionKind_getArgs = {
  inspection_kind_id: Scalars["String"]["input"];
};

export type QueryInspectionKind_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryInspection_getArgs = {
  inspection_id: Scalars["String"]["input"];
};

export type QueryInspection_getsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_kind?: InputMaybe<OrderKind>;
  project_id: Scalars["String"]["input"];
  status_filter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryIsUsedIfcFile_isUsedIfcFileArgs = {
  ifc_file_id: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
};

export type QueryIssueKind_getArgs = {
  issuekind_id: Scalars["String"]["input"];
};

export type QueryIssueKind_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryIssuePoint_getArgs = {
  issuepoint_id: Scalars["String"]["input"];
};

export type QueryIssuePoint_getsArgs = {
  owner_id: Scalars["String"]["input"];
};

export type QueryIssuePriority_getArgs = {
  issuepriority_id: Scalars["String"]["input"];
};

export type QueryIssuePriority_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryIssueResponse_getArgs = {
  issueresponse_id: Scalars["String"]["input"];
};

export type QueryIssueResponse_getsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  project_id: Scalars["String"]["input"];
};

export type QueryIssueStatus_getArgs = {
  issuestatus_id: Scalars["String"]["input"];
};

export type QueryIssueStatus_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryIssue_getArgs = {
  issue_id: Scalars["String"]["input"];
};

export type QueryIssue_getsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_kind?: InputMaybe<OrderKind>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  status_filter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPlan_getArgs = {
  plan_id: Scalars["String"]["input"];
};

export type QueryPlan_getsArgs = {
  doneFilter?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  project_id: Scalars["String"]["input"];
};

export type QueryProjectUser_getArgs = {
  project_id: Scalars["String"]["input"];
  projectuser_id: Scalars["Int"]["input"];
};

export type QueryProjectUser_getProjectUsersArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryProjectUser_getsArgs = {
  workspace_id: Scalars["String"]["input"];
};

export type QueryProject_getArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryReportKind_getArgs = {
  reportkind_id: Scalars["String"]["input"];
};

export type QueryReportKind_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryReport_getArgs = {
  report_id: Scalars["String"]["input"];
};

export type QueryReport_getsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_kind?: InputMaybe<OrderKind>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  status_filter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryStorage_getArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryStorage_getsArgs = {
  workspace_id: Scalars["String"]["input"];
};

export type QueryTermsCustom_getArgs = {
  custom_term_id: Scalars["String"]["input"];
};

export type QueryTermsCustom_getsArgs = {
  project_id: Scalars["String"]["input"];
};

export type QueryWorkspaceUser_getArgs = {
  workspace_id: Scalars["String"]["input"];
  workspaceuser_id: Scalars["Int"]["input"];
};

export type QueryWorkspaceUser_getWorkspaceUsersArgs = {
  workspace_id: Scalars["String"]["input"];
};

export type QueryWorkspace_getArgs = {
  workspace_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Report = {
  __typename?: "Report";
  active: Scalars["Boolean"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  facilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  files?: Maybe<Array<Maybe<Files>>>;
  formData?: Maybe<Scalars["JSON"]["output"]>;
  kind: Scalars["String"]["output"];
  memo?: Maybe<Array<Maybe<Memo>>>;
  name: Scalars["String"]["output"];
  owner?: Maybe<Owner>;
  owner_id?: Maybe<Scalars["String"]["output"]>;
  project_id: Scalars["String"]["output"];
  report_id: Scalars["String"]["output"];
  reportedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<ApprovedStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user_id: Scalars["String"]["output"];
};

export type ReportCreate = {
  active: Scalars["Boolean"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  kind: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id: Scalars["String"]["input"];
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ApprovedStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id: Scalars["String"]["input"];
};

export type ReportKind = {
  __typename?: "ReportKind";
  active: Scalars["Boolean"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  reportkind_id: Scalars["String"]["output"];
  showOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReportKindCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportKindUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  reportkind_id?: InputMaybe<Scalars["String"]["input"]>;
  showOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum ReportStatus {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  FIXED = "FIXED",
  WITHDRAW = "WITHDRAW",
}

export type ReportUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<FilesUpdate>>>;
  formData?: InputMaybe<Scalars["JSON"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  report_id?: InputMaybe<Scalars["String"]["input"]>;
  reportedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ApprovedStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Storage = {
  __typename?: "Storage";
  count: Scalars["Int"]["output"];
  total: Scalars["Float"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  UpdateEvent_update?: Maybe<Scalars["String"]["output"]>;
};

export type SubscriptionUpdateEvent_updateArgs = {
  project_id: Scalars["String"]["input"];
};

export type TermsCustom = {
  __typename?: "TermsCustom";
  active: Scalars["Boolean"]["output"];
  alias_name: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  custom_name: Scalars["String"]["output"];
  custom_term_id: Scalars["String"]["output"];
  project_id: Scalars["String"]["output"];
  term_id: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TermsCustomCreate = {
  active: Scalars["Boolean"]["input"];
  alias_name: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  custom_name: Scalars["String"]["input"];
  project_id: Scalars["String"]["input"];
  term_id: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TermsCustomUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  alias_name?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  custom_name?: InputMaybe<Scalars["String"]["input"]>;
  custom_term_id?: InputMaybe<Scalars["String"]["input"]>;
  project_id?: InputMaybe<Scalars["String"]["input"]>;
  term_id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TermsDefault = {
  __typename?: "TermsDefault";
  alias_name: Scalars["String"]["output"];
  default_name: Scalars["String"]["output"];
  show_order: Scalars["Int"]["output"];
  term_id: Scalars["String"]["output"];
};

export type TermsDefaultCreate = {
  default_name: Scalars["String"]["input"];
  show_order: Scalars["Int"]["input"];
  term_id: Scalars["String"]["input"];
};

export type TermsDefaultUpdate = {
  alias_name?: InputMaybe<Scalars["String"]["input"]>;
  default_name?: InputMaybe<Scalars["String"]["input"]>;
  show_order?: InputMaybe<Scalars["Int"]["input"]>;
  term_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Workspace = {
  __typename?: "Workspace";
  active: Scalars["Boolean"]["output"];
  admin?: Maybe<Array<Maybe<AppUser>>>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  storage?: Maybe<Storage>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  workspace_id: Scalars["String"]["output"];
};

export type WorkspaceCreate = {
  active: Scalars["Boolean"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkspaceUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workspace_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkspaceUser = {
  __typename?: "WorkspaceUser";
  active: Scalars["Boolean"]["output"];
  company?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  kind?: Maybe<WorkspaceUserKind>;
  name?: Maybe<Scalars["String"]["output"]>;
  projectUser?: Maybe<Array<Maybe<ProjectUser>>>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  user?: Maybe<AppUser>;
  userThumbnail?: Maybe<Scalars["String"]["output"]>;
  user_id: Scalars["String"]["output"];
  workspace?: Maybe<Workspace>;
  workspace_id: Scalars["String"]["output"];
  workspaceuser_id: Scalars["Int"]["output"];
};

export type WorkspaceUserCreate = {
  active: Scalars["Boolean"]["input"];
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<WorkspaceUserKind>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userThumbnail?: InputMaybe<Scalars["String"]["input"]>;
  user_id: Scalars["String"]["input"];
  workspace_id: Scalars["String"]["input"];
};

export enum WorkspaceUserKind {
  USER = "USER",
  WORKSPACE_ADMIN = "WORKSPACE_ADMIN",
}

export type WorkspaceUserUpdate = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<WorkspaceUserKind>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userThumbnail?: InputMaybe<Scalars["String"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
  workspace_id?: InputMaybe<Scalars["String"]["input"]>;
  workspaceuser_id?: InputMaybe<Scalars["Int"]["input"]>;
};
